define("ember-svg-jar/inlined/chat-multiple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.749 4c-5.523 0-10 4.477-10 10 0 1.51.335 2.945.937 4.231L2.72 22.02a1.667 1.667 0 002.005 2.032c1-.24 2.538-.61 3.908-.936a9.968 9.968 0 004.116.884c5.523 0 10-4.477 10-10s-4.477-10-10-10zm-8 10a8 8 0 114.44 7.166l-.325-.161-.352.084c-1.23.293-2.652.634-3.717.89l.92-3.611.093-.368-.174-.338A7.961 7.961 0 014.75 14zm14.667 14a9.965 9.965 0 01-6.8-2.667h.133c.957 0 1.887-.118 2.775-.342a7.963 7.963 0 003.892 1.01 7.96 7.96 0 003.56-.835l.324-.161.353.084c1.229.293 2.62.594 3.652.813-.233-1.001-.552-2.34-.855-3.534L26.356 22l.174-.337A7.961 7.961 0 0027.416 18a7.993 7.993 0 00-3.663-6.723c-.238-.964-.6-1.88-1.066-2.73 3.916 1.355 6.729 5.076 6.729 9.453 0 1.51-.336 2.946-.938 4.232.338 1.344.68 2.8.896 3.738a1.66 1.66 0 01-1.957 2c-.97-.204-2.493-.529-3.884-.854a9.967 9.967 0 01-4.117.884z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});