define("ember-svg-jar/inlined/medical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.434 8.767a.778.778 0 00-.778.778v1.556H9.1a.778.778 0 00-.778.778v1.556c0 .43.349.777.778.777h1.556v1.556c0 .43.348.778.778.778h1.556c.43 0 .778-.348.778-.778v-1.556h1.555c.43 0 .778-.348.778-.777v-1.556a.778.778 0 00-.778-.778h-1.555V9.545a.778.778 0 00-.778-.778h-1.556zM9.1 11.88h2.334V9.545h1.556v2.334h2.333v1.556H12.99v2.333h-1.556v-2.333H9.1v-1.556z\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M23.88 12.657c0 6.444-5.224 11.668-11.668 11.668-6.444 0-11.669-5.224-11.669-11.668C.543 6.213 5.768.988 12.212.988c6.444 0 11.668 5.225 11.668 11.669zm-.778 0c0 6.014-4.876 10.89-10.89 10.89-6.015 0-10.89-4.876-10.89-10.89 0-6.015 4.875-10.89 10.89-10.89 6.014 0 10.89 4.875 10.89 10.89z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 24 25",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});