define("ember-svg-jar/inlined/pending", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#F6A623\" fill-rule=\"evenodd\"><path d=\"M.981 19.58L3.8 18.267S1.2 12.494 6.632 8.028c0 0 3.395-2.547 7.118-.809l1.157-3.113s-5.653-2.6-10.4 1.448c0 0-6.616 4.796-3.526 14.025z\"/><path d=\"M15.328.302l-3.7 9.96 6.39-2.95-2.69-7.01zM22.05 10.569l-2.819 1.307s2.597 5.775-2.837 10.238c0 0-3.397 2.547-7.118.81l-1.158 3.108s5.65 2.605 10.4-1.44c0 0 6.619-4.794 3.532-14.023z\"/><path d=\"M7.696 29.838L11.4 19.88l-6.391 2.949 2.688 7.01z\"/></g>",
    "attrs": {
      "class": "#{className}",
      "width": "23",
      "height": "30",
      "viewBox": "0 0 23 30",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});