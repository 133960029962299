define("ember-svg-jar/inlined/bedarf-screen-1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#01051C\" d=\"M62 60.708c0 .724-.568 1.292-1.292 1.292H1.292A1.279 1.279 0 010 60.708V1.292C0 .568.568 0 1.292 0a1.28 1.28 0 011.292 1.292v58.125h.491l12.555-25.11a1.26 1.26 0 011.731-.568 1.26 1.26 0 01.568 1.731L5.968 59.417h54.741c.723 0 1.291.568 1.291 1.291zM15.5 25.833c0-2.842 2.325-5.167 5.167-5.167 2.842 0 5.167 2.325 5.167 5.167 0 2.842-2.325 5.167-5.167 5.167-2.842 0-5.167-2.325-5.167-5.167zm2.583 0a2.59 2.59 0 002.583 2.583 2.59 2.59 0 002.583-2.583 2.59 2.59 0 00-2.583-2.583 2.591 2.591 0 00-2.583 2.583zM46.5 20.667c0-2.842 2.325-5.167 5.167-5.167 2.842 0 5.167 2.325 5.167 5.167 0 2.842-2.325 5.167-5.167 5.167-2.842-.001-5.167-2.326-5.167-5.167zm2.583 0a2.59 2.59 0 002.583 2.583 2.59 2.59 0 002.583-2.583 2.59 2.59 0 00-2.583-2.583 2.59 2.59 0 00-2.583 2.583zm-15.5 18.083c0-2.842 2.325-5.167 5.167-5.167s5.167 2.325 5.167 5.167-2.325 5.167-5.167 5.167-5.167-2.325-5.167-5.167zm2.584 0c0 1.421 1.163 2.583 2.583 2.583s2.583-1.163 2.583-2.583-1.163-2.583-2.583-2.583-2.583 1.162-2.583 2.583zm-6.459-5.167A1.28 1.28 0 0031 32.291c0-.723-.568-1.291-1.292-1.291a1.28 1.28 0 00-1.292 1.292 1.28 1.28 0 001.292 1.291zM45.208 31a1.28 1.28 0 001.292-1.292 1.28 1.28 0 00-1.292-1.292 1.28 1.28 0 00-1.292 1.292A1.28 1.28 0 0045.208 31z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "97",
      "height": "97",
      "viewBox": "-15.5 -19.5 97 97"
    }
  };
});