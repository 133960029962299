define("ember-svg-jar/inlined/apple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.727 3.383c.468-.567.8-1.338.8-2.12 0-.107-.01-.214-.029-.302-.762.03-1.68.508-2.227 1.152-.43.489-.83 1.27-.83 2.051 0 .117.02.234.03.274.049.01.127.019.205.019.683 0 1.543-.459 2.05-1.074zm.537 1.24c-1.143 0-2.07.693-2.666.693-.635 0-1.465-.654-2.461-.654-1.885 0-3.8 1.563-3.8 4.502 0 1.836.704 3.77 1.583 5.02.752 1.054 1.406 1.923 2.353 1.923.938 0 1.348-.625 2.51-.625 1.182 0 1.446.606 2.48.606 1.026 0 1.71-.938 2.354-1.865.723-1.065 1.026-2.1 1.035-2.149-.058-.02-2.021-.82-2.021-3.066 0-1.944 1.543-2.813 1.63-2.881-1.015-1.465-2.568-1.504-2.997-1.504z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "13",
      "height": "17",
      "viewBox": "0 0 13 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});