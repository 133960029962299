define("ember-svg-jar/inlined/pill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.03 15.53a.75.75 0 00-1.06-1.06l-2.25 2.25a.75.75 0 001.06 1.06l2.25-2.25zm8.395-10.954a5.25 5.25 0 00-7.425 0L4.576 12A5.25 5.25 0 1012 19.425L19.425 12a5.25 5.25 0 000-7.425zM8.818 9.878l5.304 5.303-3.182 3.182a3.75 3.75 0 11-5.304-5.303l3.182-3.182zm9.546 1.06l-3.182 3.182L9.88 8.818l3.182-3.182a3.75 3.75 0 115.303 5.304z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});