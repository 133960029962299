define("ember-svg-jar/inlined/ellipse-navbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 1.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm6 0a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM13.5 3a1.5 1.5 0 100-3 1.5 1.5 0 000 3z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "15",
      "height": "3",
      "viewBox": "0 0 15 3",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});