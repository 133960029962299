define("ember-svg-jar/inlined/ico-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\"><path d=\"M50.963 44.804c.587 0 1.063-.501 1.063-1.12V7.844c0-.618-.476-1.12-1.063-1.12h-7.441V35.84c0 4.948-3.808 8.96-8.504 8.96h-6.379v.004h2.473a3.105 3.105 0 011.931.686l10.473 8.268.006-7.387c0-.866.666-1.568 1.488-1.568l5.953.001z\" fill=\"#BFD2F5\" class=\"svg-brand-primary-fill\" opacity=\".3\"/><path d=\"M50.963 4.484H4.19C2.43 4.486 1.002 5.99 1 7.844v35.84c.002 1.855 1.429 3.359 3.19 3.36h26.922c.235.002.463.083.65.233l10.473 8.268a2.037 2.037 0 002.231.224c.724-.378 1.182-1.156 1.182-2.007v-6.718h5.315c1.76-.001 3.188-1.505 3.19-3.36V7.844c-.002-1.854-1.43-3.358-3.19-3.36zm1.063 39.2c0 .619-.476 1.12-1.063 1.12H45.01c-.821.002-1.487.703-1.488 1.568l-.006 7.386-10.473-8.268a3.105 3.105 0 00-1.93-.686H4.188c-.587 0-1.062-.502-1.063-1.12V7.844c0-.618.476-1.12 1.063-1.12h46.774c.587 0 1.063.502 1.063 1.12v35.84z\" fill=\"#2B6CDE\" class=\"svg-brand-primary-fill\"/><path d=\"M31.828 17.924c-1.679.003-3.257.846-4.252 2.27-1.36-1.943-3.752-2.746-5.929-1.989-2.177.758-3.644 2.904-3.638 5.319 0 3.946 8.512 12.32 9.552 12.32h.031c1.039 0 9.552-8.374 9.552-12.32 0-3.093-2.38-5.6-5.316-5.6zm-4.252 15.37c-2.344-1.857-7.441-7.76-7.441-9.77.002-1.855 1.429-3.358 3.19-3.36 2.074 0 2.965 1.961 4.251 3.782 1.287-1.82 2.178-3.782 4.252-3.782 1.761.002 3.188 1.505 3.19 3.36 0 2.01-5.097 7.913-7.442 9.77z\" fill=\"#2B6CDE\" class=\"svg-brand-primary-fill\"/></g>",
    "attrs": {
      "viewBox": "0 0 56 56",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});