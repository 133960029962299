define("ember-svg-jar/inlined/locker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect width=\"32\" height=\"24\" rx=\"12\" fill=\"#87CDF9\" fill-opacity=\".1\"/><path d=\"M16 13a.5.5 0 01.5.5V15a.5.5 0 01-1 0v-1.5a.5.5 0 01.5-.5z\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13 9.5V8a3 3 0 116 0v1.5h1a1 1 0 011 1V18a1 1 0 01-1 1h-8a1 1 0 01-1-1v-7.5a1 1 0 011-1h1zM18 8v1.5h-4V8a2 2 0 114 0zm-6 2.5V18h8v-7.5h-8z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "32",
      "height": "24",
      "viewBox": "0 0 32 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});