define("ember-svg-jar/inlined/padlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>lock</title><defs><mask id=\"padlock_svg__c\" maskContentUnits=\"userSpaceOnUse\" maskUnits=\"objectBoundingBox\" x=\"0\" y=\"0\" width=\"12\" height=\"10.389\" fill=\"#fff\"><use xlink:href=\"#padlock_svg__a\"/></mask><mask id=\"padlock_svg__d\" maskContentUnits=\"userSpaceOnUse\" maskUnits=\"objectBoundingBox\" x=\"0\" y=\"0\" width=\"12\" height=\"17\" fill=\"#fff\"><use xlink:href=\"#padlock_svg__b\"/></mask><rect id=\"padlock_svg__a\" x=\"0\" y=\"6.611\" width=\"12\" height=\"10.389\" rx=\"1.905\"/><path d=\"M10.154 6.612V4.155A4.15 4.15 0 006 0a4.157 4.157 0 00-4.154 4.155v2.457A1.899 1.899 0 000 8.517v6.578C0 16.15.851 17 1.901 17H10.1A1.899 1.899 0 0012 15.095V8.517a1.902 1.902 0 00-1.846-1.905z\" id=\"padlock_svg__b\"/></defs><g stroke=\"#78787A\" stroke-width=\"1.714\" fill=\"none\" fill-rule=\"evenodd\"><use mask=\"url(#padlock_svg__c)\" fill=\"#78787A\" class=\"padlock-fill\" xlink:href=\"#padlock_svg__a\"/><use mask=\"url(#padlock_svg__d)\" xlink:href=\"#padlock_svg__b\"/></g>",
    "attrs": {
      "width": "12",
      "height": "19",
      "viewBox": "0 0 12 17",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
});