define("ember-svg-jar/inlined/companyPendingShield", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#A8AAB2\" fill-rule=\"evenodd\"><path d=\"M13.5 1.987v.987c-3.462 0-6.257.58-8.445 1.537-.75.328-1.368.676-1.863 1.02-.082.057-.155.11-.218.157v6.016c0 6.2 2.816 11.167 10.526 15.092 7.71-3.925 10.526-8.891 10.526-15.092V5.688a7.746 7.746 0 00-.218-.157 10.995 10.995 0 00-1.863-1.02c-2.188-.957-4.983-1.537-8.445-1.537v-.987zm0 0V1c3.733 0 6.798.635 9.236 1.702 1.502.658 2.475 1.334 2.97 1.82l.294.29v6.892c0 7.046-3.328 12.78-12.064 17.081L13.5 29l-.436-.215C4.328 24.485 1 18.75 1 11.705V4.812l.295-.29c.494-.487 1.467-1.163 2.969-1.82C6.702 1.634 9.767 1 13.5 1v.987z\" fill-rule=\"nonzero\"/><path d=\"M13.513 16a.996.996 0 01-1.013-.994c0-.554.449-1.006 1.013-1.006.551 0 .987.452.987 1.006a.983.983 0 01-.987.994zM10.513 16a.996.996 0 01-1.013-.994c0-.554.449-1.006 1.013-1.006.551 0 .987.452.987 1.006a.983.983 0 01-.987.994zM16.513 16a.996.996 0 01-1.013-.994c0-.554.449-1.006 1.013-1.006.551 0 .987.452.987 1.006a.983.983 0 01-.987.994z\"/></g>",
    "attrs": {
      "width": "27",
      "height": "30",
      "viewBox": "0 0 27 30",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});