define("ember-svg-jar/inlined/horizon-dismiss-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M8 2a6 6 0 110 12A6 6 0 018 2zM6.534 5.839a.5.5 0 00-.638.057l-.057.07a.5.5 0 00.057.638L7.293 8 5.896 9.396l-.057.07a.5.5 0 00.057.638l.07.057a.5.5 0 00.638-.057L8 8.707l1.396 1.397.07.057a.5.5 0 00.638-.057l.057-.07a.5.5 0 00-.057-.638L8.707 8l1.397-1.396.057-.07a.5.5 0 00-.057-.638l-.07-.057a.5.5 0 00-.638.057L8 7.293 6.604 5.896l-.07-.057z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});