define("ember-svg-jar/inlined/missing-contract-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.58.484H1.352c-.468 0-.81.342-.81.792s.342.81.81.81H7.58a.81.81 0 00.828-.81c0-.45-.36-.792-.828-.792z\" fill=\"#888DA0\"/>",
    "attrs": {
      "width": "9",
      "height": "3",
      "viewBox": "0 0 9 3",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});