define("ember-svg-jar/inlined/motorcycle-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M26.5 25a3.5 3.5 0 100-7 3.5 3.5 0 000 7zm0 1a4.5 4.5 0 100-9 4.5 4.5 0 000 9zm-21-1a3.5 3.5 0 100-7 3.5 3.5 0 000 7zm0 1a4.5 4.5 0 100-9 4.5 4.5 0 000 9z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M16.5 5.5A.5.5 0 0117 5h3.5c.98 0 2.912.586 3.485 2.879l.003.013 3 13.5a.5.5 0 01-.976.216L23.013 8.115C22.585 6.413 21.185 6 20.5 6H17a.5.5 0 01-.5-.5z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M17.146 12.146c2.514-2.513 5.106-2.83 6.425-2.641a.5.5 0 01-.142.99c-1.012-.145-3.279.071-5.56 2.343-.456.541-1.25 1.23-2.186 1.515a3.011 3.011 0 01-.683.127V22h3.982c-.015-1.363.212-3.192.95-4.808.85-1.857 2.399-3.471 5.027-3.69A.521.521 0 0125 13.5h4a.5.5 0 010 1h-3.979c-2.16.187-3.438 1.487-4.179 3.108-.752 1.646-.923 3.584-.843 4.86A.5.5 0 0119.5 23H12a.5.5 0 01-.5-.5v-4.436c-.36-1.341-1.504-2.234-2.923-2.8-1.431-.57-3.04-.764-4.077-.764-.36 0-.691-.18-.952-.369a5.129 5.129 0 01-.784-.736 11.71 11.71 0 01-1.193-1.638A.5.5 0 012 11h9.5a.5.5 0 01.354.146l1.5 1.5a.513.513 0 01.036.042c.347.432.693.646 1.01.738.32.093.653.074.992-.03.695-.211 1.343-.76 1.724-1.216a.538.538 0 01.03-.034zM14 22v-7.652c-.493-.17-.957-.501-1.373-1.013L11.293 12H2.93c.177.246.376.5.58.73.22.247.433.452.625.592.205.15.324.178.364.178 1.13 0 2.871.207 4.448.836 1.569.625 3.08 1.716 3.537 3.543.01.04.015.08.015.121v4H14z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M5.096 20.706l4-5.5.808.588-4 5.5a.5.5 0 01-.808-.588zm20.68-14.153A.5.5 0 0126 6.5h3a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-3c-.663 0-1.053-.434-1.25-.846-.193-.4-.25-.855-.25-1.154 0-.546.229-.988.488-1.304.257-.313.564-.53.788-.643zm.356.947c-.108.07-.249.181-.37.33a1.054 1.054 0 00-.262.67c0 .201.043.496.15.721.103.213.213.279.35.279h2.5v-2h-2.368z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});