define("ember-svg-jar/inlined/blueArrowRight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2.178 12.383L6.822 7 2.178 1.617\" stroke=\"#2B6CDE\" stroke-width=\"3\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "9",
      "height": "14",
      "viewBox": "0 0 9 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});