define("ember-svg-jar/inlined/ellipse-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"3\" cy=\"3\" r=\"3\" fill=\"#0439D7\"/><circle cx=\"11\" cy=\"3\" r=\"3\" fill=\"#0439D7\"/><circle cx=\"19\" cy=\"3\" r=\"3\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "22",
      "height": "6",
      "viewBox": "0 0 22 6",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});