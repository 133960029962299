define("ember-svg-jar/inlined/notification-icon-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 21a9 9 0 110-18 9 9 0 010 18z\" fill=\"currentColor\"/><path d=\"M12.05 7.94c.439 0 .818.363.818.827 0 .455-.38.819-.817.819a.823.823 0 110-1.646zM11.995 16.378c-.438 0-.771-.345-.771-.795v-4.39c0-.45.333-.784.783-.784a.76.76 0 01.77.783v4.391c0 .45-.333.795-.782.795z\" fill=\"#fff\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});