define("ember-svg-jar/inlined/blue-down-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#fff\" d=\"M17.368 9.231a1 1 0 011.279 1.538l-6.004 4.997a1 1 0 01-1.28 0L5.36 10.769A1 1 0 016.64 9.23l5.364 4.465 5.364-4.465z\"/><path class=\"svg-cta-fill\" d=\"M17.368 9.231a1 1 0 011.279 1.538l-6.004 4.997a1 1 0 01-1.28 0L5.36 10.769A1 1 0 016.64 9.23l5.364 4.465 5.364-4.465z\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});