define("ember-svg-jar/inlined/info-outlined", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 12a9 9 0 1018 0 9 9 0 00-18 0zm16.5-.002a7.5 7.5 0 01-7.5 7.499A7.5 7.5 0 1112 4.5a7.5 7.5 0 017.5 7.498zM12.05 7.94c.439 0 .818.364.818.828 0 .455-.38.819-.817.819a.823.823 0 110-1.646zm-.827 7.644c0 .45.334.795.772.795a.772.772 0 00.783-.795v-4.39a.76.76 0 00-.772-.784.763.763 0 00-.783.783v4.391z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});