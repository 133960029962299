define("ember-svg-jar/inlined/user-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20.21 3.84v24.32H1.264v2.56h21.474V3.84H20.21z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.263 2.56h21.474c.698 0 1.263.573 1.263 1.28v26.88c0 .707-.566 1.28-1.263 1.28H1.263A1.272 1.272 0 010 30.72V3.84c0-.707.566-1.28 1.263-1.28zm0 28.16h21.474V3.84H1.263v26.88z\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.158 10.88c0 1.767-1.414 3.2-3.158 3.2s-3.158-1.433-3.158-3.2c0-1.767 1.414-3.2 3.158-3.2s3.158 1.433 3.158 3.2zm-1.263 0c0-1.06-.848-1.92-1.895-1.92-1.046.001-1.893.86-1.895 1.92 0 1.06.849 1.92 1.895 1.92s1.895-.86 1.895-1.92zM17.684 21.12c-.009.214-.03.428-.063.64H6.38a5.758 5.758 0 01-.063-.64c0-3.181 2.545-5.76 5.684-5.76 3.14 0 5.684 2.579 5.684 5.76zm-1.308-.64c-.317-2.203-2.18-3.837-4.376-3.84-2.197.003-4.06 1.637-4.376 3.84h8.752z\" fill=\"#0439D7\"/><path d=\"M6.947 25.6h10.106a.636.636 0 00.631-.64.636.636 0 00-.631-.64H6.947a.636.636 0 00-.631.64c0 .353.283.64.631.64zM8.21 1.28h7.58a.636.636 0 00.631-.64.636.636 0 00-.632-.64H8.21a.636.636 0 00-.631.64c0 .353.283.64.631.64z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "24",
      "height": "32",
      "viewBox": "0 0 24 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});