define("ember-svg-jar/inlined/phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#phone_svg__clip0_305_759)\"><path d=\"M24.593 19.1l-6.3-4.7c-.4-.3-.8-.2-1.1.1l-.5.5-1.6 1.6c-.5.5-1.3.5-1.9 0l-5.9-5.3c-.3-.2-.4-.6-.4-1s.1-.7.4-1l1.4-1.4.3-.3c.3-.3.3-.8.1-1.1L4.793.3c-.1-.1-.3-.3-.6-.3-.2 0-.5 0-.7.2l-2.5 2c-1 .8-1.2 2.1-.8 3.3 3.3 8.8 10.4 15.4 19.4 18.4 1 .3 2.2 0 2.9-.8.5-.6 1.7-2.1 1.9-2.3.1-.1.2-.3.3-.4.2-.2.2-.4.2-.7.1-.3-.1-.5-.3-.6z\" fill=\"#2661D4\"/></g><defs><clipPath id=\"phone_svg__clip0_305_759\"><path fill=\"#fff\" d=\"M0 0h26v24H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "26",
      "height": "24",
      "viewBox": "0 0 26 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});