define("ember-svg-jar/inlined/button-arrow-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"35\" cy=\"35\" r=\"35\" fill=\"currentColor\"/><path d=\"M23.5 35h22m0 0l-9-9m9 9l-9 9\" stroke=\"#fff\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "70",
      "height": "70",
      "viewBox": "0 0 70 70",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});