define("ember-svg-jar/inlined/equity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g transform=\"translate(8 1)\" stroke=\"#A8AAB2\" fill=\"none\" fill-rule=\"evenodd\"><circle stroke-width=\"2\" cx=\"15\" cy=\"15\" r=\"15\"/><path d=\"M20.984 20.328c.432 0 .864.486.864.945 0 .297-.135.567-.459.918-1.35 1.35-3.267 2.106-5.4 2.106-3.888 0-6.885-2.43-7.938-6.156H5.972c-.513 0-.945-.405-.945-.918 0-.54.432-.918.945-.918H7.7c-.027-.405-.054-.81-.054-1.215 0-.378.027-.756.054-1.107H6.026c-.513 0-.945-.378-.945-.918 0-.513.432-.918.945-.918h1.998c1.026-3.78 4.023-6.264 7.965-6.264 2.133 0 4.05.756 5.4 2.106.324.351.459.621.459.891 0 .486-.432.972-.864.972-.567 0-.756-.324-1.188-.675-1.134-.918-2.295-1.35-3.726-1.35-2.808 0-4.914 1.647-5.805 4.32h6.831c.54 0 .945.405.945.918 0 .54-.405.918-.945.918H9.887c-.027.351-.054.729-.054 1.107 0 .432.027.837.081 1.215h6.021c.54 0 .945.378.945.918 0 .513-.405.918-.945.918h-5.616c.891 2.619 2.997 4.212 5.751 4.212 1.431 0 2.592-.432 3.726-1.35.432-.351.621-.675 1.188-.675z\" stroke-width=\".5\" fill=\"#A8AAB2\"/></g>",
    "attrs": {
      "width": "46",
      "height": "32",
      "viewBox": "0 0 46 32",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});