define("ember-svg-jar/inlined/icon-nav-logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3 1.5A1.5 1.5 0 001.5 3v18A1.5 1.5 0 003 22.5h9.75a1.5 1.5 0 001.5-1.5v-3.75a.75.75 0 00-1.5 0V21H3V3h9.75v3.75a.75.75 0 001.5 0V3a1.5 1.5 0 00-1.5-1.5H3z\" fill=\"#0439D7\"/><path d=\"M19.28 8.47a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h11.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3a.75.75 0 000-1.06l-3-3z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});