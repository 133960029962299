define("ember-svg-jar/inlined/hausrat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>hausrat</title><g fill-rule=\"nonzero\" fill=\"none\"><path d=\"M31.947 12.837V32.8c0 .442-.358.8-.8.8H28.8v1.6h4c.442 0 .8-.358.8-.8v-20h.053l-1.706-1.563zM11.2 24h3.2v3.2h-3.2z\" fill=\"#BFD2F5\"/><path d=\"M27.301 33.6H6.4v.8c0 .441.673.8 1.5.8h22.5v-1.6h-3.099z\" fill=\"#BFD2F5\"/><path d=\"M39.74 17.81L20.54.21a.802.802 0 00-1.08 0L.26 17.81a.8.8 0 001.08 1.18l3.46-3.171V34.4a2.4 2.4 0 002.4 2.4h25.6a2.4 2.4 0 002.4-2.4V15.819l3.46 3.17a.8.8 0 101.08-1.179zM20.8 35.2V24h6.4v11.2h-6.4zm12.8-20.8v20c0 .442-.358.8-.8.8h-4V24a1.6 1.6 0 00-1.6-1.6h-6.4a1.6 1.6 0 00-1.6 1.6v11.2h-12a.801.801 0 01-.8-.8v-20h-.053L20 1.885 33.653 14.4H33.6z\" fill=\"#2B6CDE\"/><path d=\"M14.4 22.4h-3.2A1.6 1.6 0 009.6 24v3.2a1.6 1.6 0 001.6 1.6h3.2a1.6 1.6 0 001.6-1.6V24a1.6 1.6 0 00-1.6-1.6zm0 4.8h-3.2V24h3.2v3.2z\" fill=\"#2B6CDE\"/></g>",
    "attrs": {
      "width": "40",
      "height": "37",
      "viewBox": "0 0 40 37",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});