define("ember-svg-jar/inlined/demand-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#demand-check_svg__clip0_663_3995)\"><path d=\"M78 8.46v48.12H35.41V.38h33.68L78 8.46z\" fill=\"#fff\" stroke=\"#1D1D1B\" stroke-width=\".76\" stroke-miterlimit=\"10\"/><path d=\"M69.09.38L78 8.46h-8.91V.38z\" fill=\"#1D1D1B\"/><path d=\"M53.02 16.19h17.47M40.98 30.95h30.34M40.98 34.87h30.34M40.98 27.45h30.34\" stroke=\"#1D1D1B\" stroke-width=\".76\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M55.29 33.31a17.58 17.58 0 10-19.66 15.23 17.61 17.61 0 0019.66-15.23zm-3.13-2.22a14.31 14.31 0 11-28.62 0 14.31 14.31 0 0128.62 0z\" fill=\"#fff\" stroke=\"#1D1D1B\" stroke-width=\".63\" stroke-miterlimit=\"10\"/><path d=\"M36.08 45.28A14.23 14.23 0 0123.51 30.5a14.37 14.37 0 0012.57 13.6A14.211 14.211 0 0052 31.68c0-.44.15-.73.15-1.18.025.793-.025 1.587-.15 2.37a14.29 14.29 0 01-15.92 12.41z\" fill=\"#1A1A1F\"/><path d=\"M23.66 41.59L2.87 54.27\" stroke=\"#1A1A1F\" stroke-width=\"2.59\" stroke-miterlimit=\"10\"/><path d=\"M14.2 50L3.41 56.67a2.34 2.34 0 01-3.11-.74 2.34 2.34 0 01.7-3.11l10.79-6.65a2.34 2.34 0 013.11.74 2.34 2.34 0 01-.7 3.09z\" fill=\"#F1EEF8\"/></g><defs><clipPath id=\"demand-check_svg__clip0_663_3995\"><path fill=\"#fff\" d=\"M0 0h78v57H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "78",
      "height": "57",
      "viewBox": "0 0 78 57",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});