define("ember-svg-jar/inlined/file-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5 4.25a.75.75 0 01.75-.75h5.75V8a2 2 0 002 2H18v9.75a.75.75 0 01-.75.75H12.5V22h4.75a2.25 2.25 0 002.25-2.25V9.664c0-.464-.184-.909-.513-1.237l-5.914-5.914A1.75 1.75 0 0011.836 2H5.75A2.25 2.25 0 003.5 4.25V12H5V4.25zM13 8V4.56l3.94 3.94H13.5A.5.5 0 0113 8zm-9 5.75a.75.75 0 01-.75.75H2v7h1.25a.75.75 0 010 1.5H2a1.5 1.5 0 01-1.5-1.5v-7A1.5 1.5 0 012 13h1.25a.75.75 0 01.75.75zm4.75.75a.75.75 0 010-1.5H10a1.5 1.5 0 011.5 1.5v7A1.5 1.5 0 0110 23H8.75a.75.75 0 010-1.5H10v-7H8.75zM6 16.75a.75.75 0 01.75.75V21a.75.75 0 01-1.5 0v-3.5a.75.75 0 01.75-.75zM6 16a.75.75 0 100-1.5.75.75 0 000 1.5z\" fill=\"#222326\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});