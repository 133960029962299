define("ember-svg-jar/inlined/clock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g transform=\"translate(2 1)\" stroke=\"#666\" stroke-width=\"2\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><ellipse cx=\"12.49\" cy=\"12.397\" rx=\"12.49\" ry=\"12.397\"/><path d=\"M11.89 8.71v5.78h5.531\"/></g>",
    "attrs": {
      "width": "29",
      "height": "29",
      "viewBox": "0 0 29 29",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});