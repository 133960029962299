define("ember-svg-jar/inlined/tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>tooltip</title><g fill=\"none\" fill-rule=\"evenodd\"><ellipse fill=\"#D6D6D6\" cx=\"10.812\" cy=\"10.801\" rx=\"10.812\" ry=\"10.801\"/><path d=\"M9.37 13.163v-.594c0-.515.11-.962.33-1.342.219-.38.62-.785 1.204-1.214.563-.402.934-.728 1.113-.98.18-.252.27-.533.27-.844 0-.348-.129-.613-.386-.796-.257-.182-.616-.273-1.077-.273-.803 0-1.72.263-2.748.788L7.2 6.148A7.656 7.656 0 0111 5.143c1.104 0 1.982.265 2.633.796.65.53.976 1.237.976 2.121 0 .59-.134 1.099-.402 1.527-.268.429-.777.911-1.527 1.447-.514.38-.84.67-.976.868-.137.198-.205.458-.205.78v.481H9.37zm-.257 2.749c0-.45.12-.79.361-1.02.241-.231.592-.346 1.053-.346.444 0 .789.118 1.032.353.244.236.366.574.366 1.013 0 .423-.123.757-.37 1-.246.244-.589.366-1.028.366-.45 0-.798-.12-1.045-.358-.246-.238-.37-.574-.37-1.008z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});