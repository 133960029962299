define("ember-svg-jar/inlined/lock-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.5 5V4a2.5 2.5 0 00-5 0v1H4V4a4 4 0 118 0v1h-1.5zM2 7.029a2 2 0 012-2h8a2 2 0 012 2V14a2 2 0 01-2 2H4a2 2 0 01-2-2V7.029zM7 10a1 1 0 112 0v1a1 1 0 11-2 0v-1z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});