define("ember-engines/initializers/engines", ["exports", "ember-engines/-private/router-ext", "ember-engines/-private/engine-instance-ext"], function (_exports, _routerExt, _engineInstanceExt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  // Load extensions to Ember

  // TODO: Move to ensure they run prior to instantiating Ember.Application
  function initialize() {}
  var _default = _exports.default = {
    name: 'engines',
    initialize
  };
});