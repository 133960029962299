define("ember-svg-jar/inlined/event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.496 2.25H14.25V.752a.75.75 0 10-1.5 0V2.25h-7.5V.752a.75.75 0 10-1.5 0V2.25H1.504C.673 2.25 0 2.923 0 3.753v12.744C0 17.328.673 18 1.504 18h14.992c.831 0 1.504-.673 1.504-1.503V3.753c0-.831-.673-1.503-1.504-1.503zm.004 4.5V3.753l-2.25-.001v.746a.75.75 0 11-1.5 0V3.75h-7.5v.747a.75.75 0 11-1.5 0V3.75H1.504c-.001 0-.002 1.266-.002 3H16.5zm0 1.5H1.5L1.5 16.497c0 .002 14.996.003 14.996.003.002 0 .003-4.58.003-8.25z\" fill=\"#203151\"/><mask id=\"event_svg__a\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"18\" height=\"18\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.496 2.25H14.25V.752a.75.75 0 10-1.5 0V2.25h-7.5V.752a.75.75 0 10-1.5 0V2.25H1.504C.673 2.25 0 2.923 0 3.753v12.744C0 17.328.673 18 1.504 18h14.992c.831 0 1.504-.673 1.504-1.503V3.753c0-.831-.673-1.503-1.504-1.503zm.004 4.5V3.753l-2.25-.001v.746a.75.75 0 11-1.5 0V3.75h-7.5v.747a.75.75 0 11-1.5 0V3.75H1.504c-.001 0-.002 1.266-.002 3H16.5zm0 1.5H1.5L1.5 16.497c0 .002 14.996.003 14.996.003.002 0 .003-4.58.003-8.25z\" fill=\"#fff\"/></mask><g mask=\"url(#event_svg__a)\"><path fill=\"#203151\" d=\"M0 0h18v18H0z\"/></g>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});