define("ember-svg-jar/inlined/three-pages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#fff\" d=\"M5.257 15.132l6.08-2.966a.25.25 0 00.115-.334L8.629 6.044l-3.18-1.126-4.308 1.735a.25.25 0 00-.132.341l3.913 8.023a.25.25 0 00.335.115z\"/><path stroke=\"currentColor\" d=\"M8.629 6.044L6.55 7.058l-1.1-2.14m3.179 1.126l2.823 5.788a.25.25 0 01-.115.334l-6.08 2.966a.25.25 0 01-.335-.115L1.01 6.994a.25.25 0 01.132-.341L5.45 4.918m3.179 1.126l-3.18-1.126\"/><path fill=\"#fff\" stroke=\"currentColor\" d=\"M7.207 14.743l8.126-2.178a.25.25 0 00.163-.344l-3.919-8.713L9.57 2.502l-5.352 1.01a.25.25 0 00-.196.31l2.88 10.744a.25.25 0 00.305.177z\"/><path fill=\"#fff\" d=\"M8.458 15.5h10.3a.35.35 0 00.35-.35V5l-3.216-4H8.458a.35.35 0 00-.35.35v13.8c0 .193.157.35.35.35z\"/><path stroke=\"currentColor\" stroke-width=\"1.25\" d=\"M19.108 5l-3.4.457a.25.25 0 01-.281-.275L15.892 1m3.216 4v10.15a.35.35 0 01-.35.35h-10.3a.35.35 0 01-.35-.35V1.35a.35.35 0 01.35-.35h7.434m3.216 4l-3.216-4\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "16",
      "fill": "none"
    }
  };
});