define("ember-svg-jar/inlined/revoke-change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\".5\" y=\".5\" width=\"39\" height=\"39\" rx=\"19.5\" fill=\"#F5F6FA\"/><path d=\"M14.25 11A3.25 3.25 0 0011 14.25v11.5A3.25 3.25 0 0014.25 29h6.922A3.738 3.738 0 0121 27.875v-.103c0-.092.005-.182.013-.272H14.25a1.75 1.75 0 01-1.75-1.75v-11.5c0-.966.784-1.75 1.75-1.75h11.5c.966 0 1.75.784 1.75 1.75v4.895a3.5 3.5 0 011.5.905v-5.8A3.25 3.25 0 0025.75 11h-11.5zm11.03 6.28a.75.75 0 10-1.06-1.06l-6.223 6.216-2.216-2.216a.75.75 0 00-1.06 1.06l2.745 2.746a.75.75 0 001.06 0l6.754-6.745zM29 22.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm2 5.375C31 29.431 29.714 31 26.5 31S22 29.437 22 27.875v-.103c0-.98.794-1.772 1.773-1.772h5.454c.98 0 1.773.793 1.773 1.772v.103z\" fill=\"#203151\"/><rect x=\".5\" y=\".5\" width=\"39\" height=\"39\" rx=\"19.5\" stroke=\"#CFD3DA\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});