define("ember-svg-jar/inlined/star-emphasis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.612 4.137c-.66-1.338-2.568-1.338-3.228 0l-3.144 6.37-7.03 1.022c-1.477.214-2.066 2.029-.998 3.07L8.3 19.558l-1.2 7.001c-.253 1.47 1.29 2.592 2.611 1.898l6.288-3.306 6.288 3.306c1.32.694 2.864-.427 2.611-1.898l-1.2-7.002 5.087-4.958c1.068-1.041.478-2.856-.998-3.07l-7.03-1.022-3.144-6.37zm-4.625 7.35l3.01-6.102 3.012 6.101a1.8 1.8 0 001.355.985l6.733.979-4.872 4.749a1.8 1.8 0 00-.517 1.593l1.15 6.706-6.023-3.166a1.8 1.8 0 00-1.675 0l-6.022 3.166 1.15-6.706a1.8 1.8 0 00-.517-1.593l-4.873-4.75 6.734-.978a1.8 1.8 0 001.355-.985zM1.552 5.042a1 1 0 00.157 1.406l3.333 2.666a1 1 0 101.25-1.562L2.957 4.886a1 1 0 00-1.406.156z\"/><path d=\"M30.448 24.291a1 1 0 00-.157-1.405l-3.333-2.667a1 1 0 10-1.25 1.562l3.334 2.667a1 1 0 001.406-.157zM1.709 22.886a1 1 0 101.249 1.562l3.333-2.667a1 1 0 10-1.249-1.562l-3.333 2.667zM30.448 5.042a1 1 0 01-.157 1.406l-3.333 2.666a1 1 0 01-1.25-1.562l3.334-2.666a1 1 0 011.406.156z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});