define("ember-svg-jar/inlined/information-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 14.294a6 6 0 100-12 6 6 0 000 12zm0 1a7 7 0 100-14 7 7 0 000 14z\" fill=\"#212121\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 7.794a.5.5 0 01.5.5v3a.5.5 0 01-1 0v-3a.5.5 0 01.5-.5zM8 4.794a.5.5 0 01.5.5v.5a.5.5 0 11-1 0v-.5a.5.5 0 01.5-.5z\" fill=\"#212121\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});