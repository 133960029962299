define("ember-svg-jar/inlined/outage-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<mask id=\"outage-icon_svg__a\" style=\"mask-type:alpha\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"68\" height=\"68\"><path fill=\"#C4C4C4\" d=\"M0 0h68v68H0z\"/></mask><g mask=\"url(#outage-icon_svg__a)\" fill=\"#0439D7\"><path d=\"M34 19.975c-.704 0-1.275.57-1.275 1.275v15.3c0 .704.57 1.275 1.275 1.275h15.3a1.275 1.275 0 100-2.55H35.275V21.25c0-.704-.57-1.275-1.275-1.275z\"/><path d=\"M34 2.125A31.65 31.65 0 004.675 21.546V5.95a1.275 1.275 0 00-2.55 0V23.8A1.275 1.275 0 003.4 25.075h17.85a1.275 1.275 0 100-2.55H7.043a29.297 29.297 0 114.956 30.864 1.276 1.276 0 00-1.913 1.688c9.705 11.01 25.671 13.97 38.677 7.172 13.006-6.798 19.69-21.598 16.19-35.85C61.453 12.147 48.675 2.126 34 2.125z\"/></g>",
    "attrs": {
      "width": "68",
      "height": "68",
      "viewBox": "0 0 68 68",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});