define("ember-svg-jar/inlined/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.5 1.5a.75.75 0 01.75.75V3h7.5v-.75a.75.75 0 011.5 0V3h2.25A1.5 1.5 0 0121 4.5v15a1.5 1.5 0 01-1.5 1.5h-15A1.5 1.5 0 013 19.5v-15A1.5 1.5 0 014.5 3h2.25v-.75a.75.75 0 01.75-.75zm-.75 3H4.5v3h15v-3h-2.25v.75a.75.75 0 01-1.5 0V4.5h-7.5v.75a.75.75 0 01-1.5 0V4.5zM19.5 9h-15v10.5h15V9z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});