define("ember-svg-jar/inlined/document-processing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M49 18.328V54.52c0 1.856-1.633 3.48-3.5 3.48h-42C1.633 58 0 56.376 0 54.52V3.48C0 1.624 1.633 0 3.5 0h27.067c.933 0 1.866.464 2.566.928l14.934 14.847c.466.928.933 1.625.933 2.553zM32.667 3.944V15.08c0 .696.466 1.16 1.166 1.16h11.2L32.667 3.944zm14 14.616H33.833c-1.866 0-3.5-1.624-3.5-3.48V2.32H3.5c-.7 0-1.167.464-1.167 1.16v51.04c0 .696.467 1.16 1.167 1.16h42c.7 0 1.167-.464 1.167-1.16V18.56z\" fill=\"#BDC0CF\"/><rect x=\"7.538\" y=\"6.997\" width=\"9.692\" height=\"9.688\" rx=\"4.844\" fill=\"#BDC0CF\"/><path d=\"M15.943 34.08c0-.71.575-1.285 1.286-1.285h3.805a1.286 1.286 0 110 2.572h-3.805c-.71 0-1.286-.576-1.286-1.286z\" fill=\"#BDC0CF\"/><path d=\"M8.29 34.08c0-.71.577-1.285 1.287-1.285h3.805a1.286 1.286 0 110 2.572H9.577c-.71 0-1.286-.576-1.286-1.286zM23.596 34.08c0-.71.575-1.285 1.285-1.285h2.53a1.286 1.286 0 110 2.572h-2.53c-.71 0-1.285-.576-1.285-1.286z\" fill=\"#0439D7\"/><path d=\"M27.422 40.51c0-.71.576-1.285 1.287-1.285h6.356a1.286 1.286 0 110 2.572h-6.356c-.71 0-1.287-.576-1.287-1.286zM8.29 40.51c0-.71.577-1.285 1.287-1.285h6.356a1.286 1.286 0 110 2.572H9.577c-.71 0-1.286-.576-1.286-1.286z\" fill=\"#BDC0CF\"/><path d=\"M18.494 40.51c0-.71.576-1.285 1.286-1.285h5.081a1.286 1.286 0 110 2.572h-5.08c-.71 0-1.287-.576-1.287-1.286zM8.29 46.941c0-.71.577-1.286 1.287-1.286h1.254a1.286 1.286 0 110 2.572H9.577c-.71 0-1.286-.576-1.286-1.286z\" fill=\"#0439D7\"/><path d=\"M13.392 46.941c0-.71.576-1.286 1.286-1.286h11.458a1.286 1.286 0 010 2.572H14.678c-.71 0-1.286-.576-1.286-1.286z\" fill=\"#BDC0CF\"/><path d=\"M28.698 46.941c0-.71.575-1.286 1.286-1.286h7.631a1.286 1.286 0 010 2.572h-7.631c-.71 0-1.286-.576-1.286-1.286z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "49",
      "height": "58",
      "viewBox": "0 0 49 58",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});