define("ember-svg-jar/inlined/icon-person-call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.5 8c.042 0 .083.002.124.005l-.136.321-.048.133-.008.025c-.052.168-.079.342-.082.516H4.5a.5.5 0 00-.5.5v.5c0 1.438 1.432 3 4 3 .309 0 .601-.023.877-.065a1.89 1.89 0 00-.163 1.029A6.91 6.91 0 018 14c-3.14 0-5-2.029-5-4v-.5A1.5 1.5 0 014.5 8h7zm-.301 4.244l.74.246.121.04c.344-.26.63-.587.86-.981.197-.338.325-.681.386-1.029l.025-.174-.717-.68a.875.875 0 01-.226-.888l.03-.085.326-.767a.784.784 0 01.877-.482l.077.02.407.13c.404.13.714.476.814.91.237 1.034-.048 2.292-.855 3.774-.806 1.48-1.69 2.371-2.651 2.67a1.139 1.139 0 01-1.076-.22l-.08-.074-.309-.31a.88.88 0 01-.144-1.03l.046-.074.467-.686a.786.786 0 01.704-.347.762.762 0 01.096.014l.082.023zM8 1.5A2.75 2.75 0 118 7a2.75 2.75 0 010-5.5zm0 1A1.75 1.75 0 108 6a1.75 1.75 0 000-3.5z\" fill=\"#222326\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});