define("ember-svg-jar/inlined/x-current-color", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g class=\"x-icon\" stroke-linecap=\"square\" stroke=\"currentColor\" stroke-width=\"1.56\" fill-rule=\"evenodd\"><path d=\"M1.569 1.569l17.213 17.213M1.569 18.631L18.782 1.418\"/></g>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});