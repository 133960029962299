define("ember-svg-jar/inlined/clark-contracts-assets--person-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.814 15.5a6.472 6.472 0 01.708-1.5h-6.77a2.249 2.249 0 00-2.248 2.248v.578c0 .892.318 1.756.898 2.435C4.968 21.095 7.354 22 10.5 22c.6 0 1.173-.034 1.717-.1a6.516 6.516 0 01-.994-1.419c-.235.012-.476.018-.723.018-2.738 0-4.704-.745-5.957-2.213a2.25 2.25 0 01-.54-1.461v-.578c0-.414.336-.749.75-.749h6.06zM10.5 2.003a5 5 0 110 10 5 5 0 010-10zm0 1.5a3.5 3.5 0 100 7 3.5 3.5 0 000-7zm12 13.996a5.5 5.5 0 11-11 0 5.5 5.5 0 0111 0zm-4.945-3.08a.577.577 0 00-1.11 0l-.557 1.788h-1.803c-.566 0-.8.754-.343 1.1l1.458 1.105-.557 1.787c-.175.561.441 1.028.899.681L17 19.777l1.458 1.104c.458.347 1.074-.12.899-.68l-.557-1.788 1.458-1.104c.458-.347.223-1.101-.343-1.101h-1.803l-.557-1.787z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});