define("ember-svg-jar/inlined/document-attach", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.75 3.25a.75.75 0 01.75-.75h1.129a2.25 2.25 0 011.59.659l6.622 6.622c.422.421.659.993.659 1.59V20.5a3 3 0 01-3 3H8.75a3 3 0 01-3-3v-4a.75.75 0 011.5 0v4a1.5 1.5 0 001.5 1.5h9.75a1.5 1.5 0 001.5-1.5v-9.129a.75.75 0 00-.22-.53L13.16 4.22a.75.75 0 00-.53-.22H11.5a.75.75 0 01-.75-.75z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.25 2.875a.75.75 0 01.75.75V9.25a.75.75 0 00.75.75h5.625a.75.75 0 010 1.5H14.75a2.25 2.25 0 01-2.25-2.25V3.625a.75.75 0 01.75-.75zM6.16 2.99c-.25.315-.41.794-.41 1.385v6.75c0 .134.049.217.11.272.07.065.168.103.265.103h.01a.362.362 0 00.365-.365V4A.75.75 0 018 4v7.12A1.861 1.861 0 016.12 13c-.915-.003-1.87-.706-1.87-1.875v-6.75c0-.843.228-1.677.735-2.316C5.508 1.399 6.288 1 7.25 1c.955 0 1.736.387 2.262 1.04.51.634.738 1.462.738 2.306v6.504c0 2.341-1.557 4.4-4.125 4.4S2 13.192 2 10.85V7a.75.75 0 011.5 0v3.85c0 1.69 1.051 2.9 2.625 2.9s2.625-1.21 2.625-2.9V4.346c0-.59-.16-1.06-.407-1.365-.23-.287-.575-.481-1.093-.481-.512 0-.856.196-1.09.49z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});