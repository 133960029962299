define("ember-svg-jar/inlined/notification-bell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 1.996a7.49 7.49 0 017.496 7.25l.004.25v4.097l1.38 3.156a1.25 1.25 0 01-1.145 1.751L15 18.502a3 3 0 01-5.995.176L9 18.499l-4.725.001a1.25 1.25 0 01-1.147-1.748L4.5 13.594V9.496c0-4.155 3.352-7.5 7.5-7.5zM13.5 18.5l-3 .003a1.5 1.5 0 002.993.144l.006-.147zM12 3.496c-3.32 0-6 2.674-6 6v4.41L4.656 17h14.697L18 13.907V9.509l-.004-.225A5.988 5.988 0 0012 3.496z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});