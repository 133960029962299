define("ember-svg-jar/inlined/notification-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 .375c1.633 0 3.104.71 4.117 1.84l-1.025.733a4.275 4.275 0 00-7.365 2.818l-.062 1.966c-.007.217-.057.43-.147.626L1.13 10.064c.022-.266.11-.533.276-.777l.945-1.395a.375.375 0 00.065-.199l.062-1.966A5.525 5.525 0 018 .375zm0 10.5c-.809 0-1.76-.045-2.693-.108L3.722 11.9c1.36.116 2.982.226 4.278.226 1.688 0 3.932-.186 5.425-.33 1.229-.12 1.85-1.503 1.17-2.508l-.946-1.396a.374.374 0 01-.065-.198l-.061-1.966a5.587 5.587 0 00-.079-.773l-1.17.837.061 1.94c.01.308.106.607.279.861l.946 1.396c.16.237.009.536-.256.562-1.49.144-3.681.325-5.304.325zm-.964 3.173c.24.21.585.327.964.327s.724-.117.964-.327c.227-.197.411-.52.411-1.048h1.25c0 .856-.316 1.534-.84 1.99-.509.445-1.164.635-1.785.635s-1.276-.19-1.786-.635c-.523-.456-.839-1.134-.839-1.99h1.25c0 .529.184.85.41 1.048zm8.327-11.54a.625.625 0 10-.726-1.017l-14 10a.625.625 0 00.726 1.018l14-10z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});