define("ember-modal-dialog/components/in-place-dialog", ["exports", "@ember-decorators/component", "@ember/component", "ember-modal-dialog/templates/components/in-place-dialog"], function (_exports, _component, _component2, _inPlaceDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  let InPlaceDialog = _exports.default = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_inPlaceDialog.default), _dec(_class = _dec2(_class = class InPlaceDialog extends _component2.default {
    get containerClassNamesString() {
      var _this$containerClassN, _this$containerClassN2;
      const addonClassNamesString = ['ember-modal-dialog', 'ember-modal-dialog-in-place', 'emd-in-place'].join(' ');
      const containerClassNamesString = ((_this$containerClassN = this.containerClassNames) === null || _this$containerClassN === void 0 ? void 0 : _this$containerClassN.join) && ((_this$containerClassN2 = this.containerClassNames) === null || _this$containerClassN2 === void 0 ? void 0 : _this$containerClassN2.join(' ')) || this.containerClassNames || '';
      return "".concat(addonClassNamesString, " ").concat(containerClassNamesString);
    }
  }) || _class) || _class);
});