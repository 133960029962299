define("ember-svg-jar/inlined/downArrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M-2.406 4.406L3.43 9.268l5.837-4.862\" stroke=\"#2B6CDE\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "7",
      "height": "14",
      "viewBox": "0 0 7 14"
    }
  };
});