define("ember-svg-jar/inlined/clark-home-assets--check-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M16.5 9a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0zM18 9A9 9 0 110 9a9 9 0 0118 0zm-5.016-3.778a.932.932 0 00-1.34.139l-3.71 4.684-1.302-1.37a.931.931 0 00-1.347-.012 1.007 1.007 0 00-.012 1.39l2.045 2.153a.933.933 0 001.417-.067l4.383-5.532c.333-.42.273-1.04-.134-1.385z\"/>",
    "attrs": {
      "viewBox": "0 0 18 18",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});