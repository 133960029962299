define("ember-svg-jar/inlined/icon-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.874 11.582a6 6 0 11.707-.707l3.273 3.271a.5.5 0 01-.708.708l-3.272-3.272zM12 7A5 5 0 112 7a5 5 0 0110 0z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});