define("ember-svg-jar/inlined/checked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M3 11l5.654 6L19.797 3\" stroke=\"#F6F8FD\" stroke-width=\"7\"/><path d=\"M3 11l5.654 6L19.797 3\" stroke=\"#2B6CDE\" class=\"svg-cta-stroke\" stroke-width=\"3\"/></g>",
    "attrs": {
      "viewBox": "0 0 23 23",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});