define("ember-svg-jar/inlined/light-bulb", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 2.668a9.667 9.667 0 019.667 9.667c0 2.794-1.201 5.36-3.551 7.655-.103.1-.182.222-.234.354l-.042.137-1.508 6.527a3 3 0 01-2.692 2.316l-.23.009h-2.82a3 3 0 01-2.863-2.103l-.06-.223-1.506-6.526a1 1 0 00-.276-.49c-2.24-2.188-3.436-4.618-3.545-7.259l-.008-.397.005-.32c.169-5.19 4.43-9.347 9.662-9.347zm2.82 21.997h-5.641l.438 1.893a1 1 0 00.836.765l.138.01h2.818a1 1 0 00.934-.643l.04-.132.437-1.893zM16 4.668a7.667 7.667 0 00-7.661 7.373l-.006.294.01.369c.1 2.084 1.067 4.026 2.94 5.856a3 3 0 01.763 1.235l.065.236.606 2.634h6.564l.61-2.635a3 3 0 01.659-1.292l.168-.178c1.873-1.83 2.838-3.771 2.94-5.856l.009-.37-.006-.293A7.667 7.667 0 0016 4.668z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});