define("ember-svg-jar/inlined/icon-burger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g stroke-width=\"0\" fill=\"#17203D\" fill-rule=\"evenodd\"><rect x=\"3\" y=\"11\" width=\"18\" height=\"2\" rx=\"1\"/><rect x=\"3\" y=\"3\" width=\"18\" height=\"2\" rx=\"1\"/><rect x=\"3\" y=\"19\" width=\"18\" height=\"2\" rx=\"1\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});