define("ember-svg-jar/inlined/analyze", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\"1\" y=\".5\" width=\"39\" height=\"39\" rx=\"19.5\" fill=\"#F5F6FA\"/><path d=\"M13.25 12a2.75 2.75 0 00-2.75 2.75v4.507a5.496 5.496 0 011.5-.882V14.75c0-.69.56-1.25 1.25-1.25h14.5c.69 0 1.25.56 1.25 1.25v10.5c0 .69-.56 1.25-1.25 1.25h-8.275l1.5 1.5h6.775a2.75 2.75 0 002.75-2.75v-10.5A2.75 2.75 0 0027.75 12h-14.5zm3.913 7a5.53 5.53 0 011.447 1.5h7.14a.75.75 0 000-1.5h-8.587zm6.587 4.5H19.5a5.5 5.5 0 00-.207-1.5h4.457a.75.75 0 010 1.5zm-8.5-7.5a.75.75 0 000 1.5h6.5a.75.75 0 000-1.5h-6.5zM14 28a4.48 4.48 0 002.607-.832l2.613 2.612a.75.75 0 101.06-1.06l-2.612-2.613A4.5 4.5 0 1014 28zm0-1.5a3 3 0 110-6 3 3 0 010 6z\" fill=\"#203151\"/><rect x=\"1\" y=\".5\" width=\"39\" height=\"39\" rx=\"19.5\" stroke=\"#CFD3DA\"/>",
    "attrs": {
      "width": "41",
      "height": "40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});