define("ember-svg-jar/inlined/sheildCheckInCircle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g transform=\"translate(5 5)\" fill=\"none\" fill-rule=\"evenodd\"><path class=\"tick svg-brand-primary-stroke svg-brand-primary-fill\" d=\"M73.597 91.359l-6.304-6.304a.999.999 0 111.414-1.414l5.621 5.62 18.207-16.993A1 1 0 0193.9 73.73L74.986 91.383a.997.997 0 01-1.389-.024z\" stroke=\"#2B6CDE\" stroke-width=\"2\" fill=\"#2B6CDE\"/><circle stroke=\"#F8F8F8\" stroke-width=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\" cx=\"78.9\" cy=\"78.9\" r=\"78.9\"/><path d=\"M56 58v17.32c0 16.154 8.783 30.874 22.957 38.546a43.726 43.726 0 0022.956-38.546V58H56zm22.957 58a.995.995 0 01-.466-.115C63.384 107.938 54 92.396 54 75.32V57a1 1 0 011-1h47.913a1 1 0 011 1v18.32c0 17.072-9.384 32.615-24.491 40.565a.994.994 0 01-.465.115h0z\" stroke=\"#2B6CDE\" fill=\"#2B6CDE\" class=\"svg-brand-primary-stroke svg-brand-primary-fill\"/></g>",
    "attrs": {
      "width": "168",
      "height": "168",
      "viewBox": "0 0 168 168",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});