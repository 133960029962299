define("ember-svg-jar/inlined/analytics-blue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M22 17a1 1 0 001 1h1.586L21 21.586l-2.293-2.293a1 1 0 00-1.414 0l-4 4a1 1 0 001.414 1.414L18 21.414l2.293 2.293a1 1 0 001.414 0L26 19.414V21a1 1 0 102 0v-4a1 1 0 00-1-1h-4a1 1 0 00-1 1z\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M35 21c0 7.732-6.268 14-14 14S7 28.732 7 21 13.268 7 21 7s14 6.268 14 14zm-2 0c0 6.627-5.373 12-12 12S9 27.627 9 21 14.373 9 21 9s12 5.373 12 12z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "42",
      "height": "42",
      "viewBox": "0 0 42 42",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});