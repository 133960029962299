define("ember-svg-jar/inlined/jaCircle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g transform=\"translate(1.6 .834)\" fill=\"none\" fill-rule=\"evenodd\"><ellipse stroke=\"#2B6CDE\" class=\"svg-brand-primary-stroke svg-brand-primary-fill\" stroke-width=\"1.28\" fill=\"#2B6CDE\" cx=\"10.8\" cy=\"10.801\" rx=\"10.8\" ry=\"10.801\"/><path stroke=\"#FFF\" stroke-width=\"1.8\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M7.2 11.326l2.577 2.625 5.523-6.75\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});