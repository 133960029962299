define("ember-cli-resolve-asset/initializers/ember-cli-resolve-asset", ["exports", "ember-cli-resolve-asset/-private/asset-map-loader"], function (_exports, _assetMapLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  /**
   * While we do preload the asset map already, we still need to pipe it through
   * `ember-fetch`, which requires some CPU time. So we kick off the loading
   * process as early as possible.
   *
   * Besides that, not all browsers support preloading via `<link>` tags yet.
   *
   * We intentionally do not defer app readiness.
   */
  function initialize() {
    (0, _assetMapLoader.load)();
  }
  var _default = _exports.default = {
    initialize
  };
});