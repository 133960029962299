define("ember-svg-jar/inlined/auto-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.5 17a.5.5 0 01.5-.5h3.5a.5.5 0 010 1H14a.5.5 0 01-.5-.5z\"/><path fill-rule=\"evenodd\" d=\"M26.47 13H26c-1.77 0-3.014-1.096-4.2-2.344-.13-.137-.26-.277-.392-.418-.444-.477-.893-.958-1.343-1.339C19.476 8.402 18.8 8 18 8H9.472l-.027.003c-4.675.52-6.654 3.29-7.391 5.262-.158.233-.306.572-.433.976a9.791 9.791 0 00-.355 1.697C1.114 17.15 1.1 18.702 1.4 20.5H.5a.5.5 0 000 1h1.49a.64.64 0 00.02 0h3.03a3 3 0 005.918 0H22.04a3 3 0 005.918 0H30c.753 0 1.4-.113 1.741-.68.152-.253.208-.546.233-.83.026-.28.026-.611.026-.971V19c0-1.116-.49-2.692-1.395-3.943-.906-1.253-2.304-2.272-4.135-2.057zM10 9h8c.45 0 .899.223 1.42.663.4.338.792.759 1.227 1.225.138.148.28.3.428.456.54.57 1.147 1.17 1.857 1.656H10V9zm-1 .072c-3.36.54-4.989 2.391-5.744 3.928H9V9.072zM2.784 14h23.747l.031-.004c1.353-.169 2.444.557 3.233 1.647C30.59 16.743 31 18.116 31 19c0 .383 0 .67-.021.9-.021.232-.06.346-.095.405-.035.058-.137.195-.884.195h-2.041a3 3 0 00-5.918 0H10.96a3 3 0 00-5.918 0H2.415c-.313-1.773-.301-3.285-.156-4.438a8.8 8.8 0 01.316-1.521c.074-.234.146-.413.209-.541zM27 20.995v.01a2 2 0 110-.01zM6 21a2 2 0 104 0 2 2 0 00-4 0z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});