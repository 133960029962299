define("ember-svg-jar/inlined/optimize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.525 2.033l2.476 2.51 1.324-1.289a.845.845 0 011.175-.02l3.532 3.228c.373.34.417.94.099 1.338a.849.849 0 01-1.252.106L7.942 5.223 6.587 6.54a.846.846 0 01-1.209-.013L2.12 3.225v1.743c0 .525-.398.95-.888.95s-.888-.425-.888-.95V1.083c0-.524.397-.95.888-.95h3.945c.49 0 .888.426.888.95 0 .525-.398.95-.888.95H3.525z\" fill-rule=\"nonzero\" fill=\"#FFF\"/>",
    "attrs": {
      "width": "13",
      "height": "9",
      "viewBox": "0 0 13 9",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});