define("ember-svg-jar/inlined/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 8a8 8 0 1016 0A8 8 0 000 8zm14.667-.002A6.666 6.666 0 018 14.664a6.666 6.666 0 110-13.33 6.666 6.666 0 016.667 6.664zM8.045 4.39c.39 0 .727.324.727.737a.733.733 0 01-.727.727.731.731 0 110-1.463zm-.735 6.795c0 .4.297.707.685.707.4 0 .696-.307.696-.707V7.282c0-.4-.296-.697-.685-.697-.4 0-.696.298-.696.697z\"/>",
    "attrs": {
      "fill": "currentColor",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});