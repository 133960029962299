define("ember-svg-jar/inlined/people", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.5 4a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0zM6 0a4 4 0 100 8 4 4 0 000-8zm7.5 5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM15 2a3 3 0 100 6 3 3 0 000-6zm-2.752 13.038c.703.285 1.604.462 2.753.462 2.282 0 3.586-.697 4.297-1.558.345-.418.52-.84.61-1.162a2.728 2.728 0 00.093-.574V12.179A2.179 2.179 0 0017.822 10H12.18c-.028 0-.055 0-.082.002.394.41.68.925.816 1.498h4.908c.372 0 .674.299.679.669a1.236 1.236 0 01-.04.212c-.043.16-.133.38-.32.605-.35.426-1.172 1.014-3.14 1.014-.98 0-1.676-.146-2.17-.345-.108.4-.286.883-.583 1.383zM2.25 10A2.25 2.25 0 000 12.25v.278a2.073 2.073 0 00.014.208 4.487 4.487 0 00.778 2.07C1.61 15.974 3.172 17 6 17c2.828 0 4.39-1.025 5.208-2.195a4.484 4.484 0 00.778-2.07 2.992 2.992 0 00.014-.207v-.278A2.25 2.25 0 009.75 10h-7.5zm-.75 2.507v-.257a.75.75 0 01.75-.75h7.5a.75.75 0 01.75.75v.257l-.007.08a2.986 2.986 0 01-.514 1.358C9.486 14.65 8.422 15.5 6 15.5s-3.486-.85-3.98-1.555a2.986 2.986 0 01-.513-1.359 1.527 1.527 0 01-.007-.079z\" fill=\"#fff\" opacity=\".6\"/>",
    "attrs": {
      "width": "20",
      "height": "17",
      "viewBox": "0 0 20 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});