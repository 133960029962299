define("ember-svg-jar/inlined/user-friends", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M6.14 2.667a2 2 0 100 4 2 2 0 000-4zm-3.333 2a3.333 3.333 0 116.667 0 3.333 3.333 0 01-6.667 0zM9.745 1.92a.667.667 0 01.811-.48 3.334 3.334 0 010 6.458.667.667 0 11-.33-1.292 2 2 0 000-3.875.667.667 0 01-.481-.81zm-8.628 8.39a3.333 3.333 0 012.357-.977h5.333a3.333 3.333 0 013.334 3.334V14a.667.667 0 11-1.334 0v-1.333a2 2 0 00-2-2H3.474a2 2 0 00-2 2V14a.667.667 0 11-1.333 0v-1.333c0-.884.35-1.732.976-2.357zm11.378-.39a.667.667 0 01.812-.479 3.333 3.333 0 012.5 3.225V14a.667.667 0 01-1.333 0v-1.333m0 0a2 2 0 00-1.5-1.935.667.667 0 01-.479-.812\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});