define("ember-svg-jar/inlined/life-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M1.466 16.346a1 1 0 01-.43-1.11c.768-2.82 2.01-5.886 4.339-8.25C7.735 4.589 11.125 3 16 3c4.965 0 8.244 1.448 10.53 3.804 2.237 2.304 3.419 5.38 4.419 8.38a1 1 0 01-1.65 1.029c-1.377-1.354-2.836-1.618-4.177-1.423-1.39.201-2.676.905-3.54 1.523a1 1 0 01-1.24-.06c-1.578-1.379-3.404-1.71-5.039-1.522-1.677.192-3.036.916-3.596 1.476a1 1 0 01-1.262.125c-2.068-1.379-3.731-1.594-4.975-1.406-1.27.192-2.226.823-2.818 1.332a1 1 0 01-1.186.088zm1.012-2.42A27.828 27.828 0 002 15.5c.331-.285.769-.61 1.307-.894 1.665-.88 4.292-1.373 7.693.894 1.5-1.5 6.339-3.2 10 0 1.597-1.142 4.674-2.675 7.569-1.08.485.266.964.621 1.431 1.08a58.74 58.74 0 00-.614-1.76C27.437 8.466 24.56 4 16 4 7.612 4 4.168 8.915 2.478 13.926z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M16 .5a.5.5 0 01.5.5v2.5a.5.5 0 01-1 0V1a.5.5 0 01.5-.5zM16 14a.5.5 0 01.5.5v12c0 1.374-.485 2.447-1.249 3.175-.758.721-1.76 1.075-2.751 1.075-.991 0-1.993-.354-2.751-1.075-.764-.728-1.249-1.8-1.249-3.175a.5.5 0 011 0c0 1.126.39 1.928.939 2.45.554.529 1.302.8 2.061.8.759 0 1.507-.271 2.061-.8.549-.522.939-1.324.939-2.45v-12a.5.5 0 01.5-.5z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});