define("ember-svg-jar/inlined/privathaftpflicht", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>privathaftpflicht</title><defs><path id=\"privathaftpflicht_svg__a\" d=\"M0 0h24.24v40H0z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><mask id=\"privathaftpflicht_svg__b\" fill=\"#fff\"><use xlink:href=\"#privathaftpflicht_svg__a\"/></mask><path d=\"M22.447 3.878l-2.181 17.456a1.83 1.83 0 01-1.819 1.575H5.962a1.83 1.83 0 01-1.818-1.575L1.962 3.819c-.181-.97.667-2.06 1.819-2.06h4.97l.726.726-4.182 4.182 3.697 4.425-1.272 4.665 3.152-4.665-2.486-4.425 4.183-4.182-.545-.818h8.726c1.394.091 1.819 1.363 1.697 2.211M20.63 0H3.66C1.477 0-.22 1.938.022 4.122l2.182 17.515c.243 1.818 1.758 3.212 3.638 3.212h3.515c1.03 0 1.817.788 1.817 1.818v10.301c0 .669-.545 1.214-1.212 1.214h-6.06a.932.932 0 00-.91.908c0 .485.425.91.91.91h16.485a.932.932 0 00.909-.91.93.93 0 00-.909-.908h-6.122a1.215 1.215 0 01-1.211-1.214V26.667c0-1.03.787-1.819 1.818-1.819h3.514c1.82 0 3.395-1.393 3.638-3.211l2.181-17.515C24.508 1.939 22.812 0 20.63 0\" fill=\"#2B6CDE\" mask=\"url(#privathaftpflicht_svg__b)\"/></g>",
    "attrs": {
      "width": "25",
      "height": "40",
      "viewBox": "0 0 25 40",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
});