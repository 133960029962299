define("ember-svg-jar/inlined/important", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.5 12.5L15 11V1h2.5v11.5z\" fill=\"#DADDEC\"/><path d=\"M22.5 3H20v17.5a.5.5 0 01-.5.5H2v2.5a.5.5 0 00.5.5h20a.5.5 0 00.5-.5v-20a.5.5 0 00-.5-.5z\" fill=\"#DADDEC\"/><path d=\"M22.5 2h-3a.5.5 0 000 1h3a.5.5 0 01.5.5v20a.5.5 0 01-.5.5h-20a.5.5 0 01-.5-.5v-20a.5.5 0 01.5-.5h7a.5.5 0 000-1h-7A1.5 1.5 0 001 3.5v20A1.5 1.5 0 002.5 25h20a1.5 1.5 0 001.5-1.5v-20A1.5 1.5 0 0022.5 2z\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.757 12.928a.496.496 0 01-.61-.075A.5.5 0 0111 12.5V1.5A1.5 1.5 0 0112.5 0h4A1.5 1.5 0 0118 1.5v11a.5.5 0 01-.757.428L14.5 11l-2.743 1.928zM12.5 1a.5.5 0 00-.5.5v10.035l1.925-1.353a1 1 0 011.15 0L17 11.535V1.5a.5.5 0 00-.5-.5h-4z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "25",
      "height": "25",
      "viewBox": "0 0 25 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});