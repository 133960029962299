define("ember-svg-jar/inlined/clark-home-assets--heart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.424 10.155a10.42 10.42 0 0113.806.684l2.271 2.224 2.267-2.22a10.42 10.42 0 0113.809-.688c4.85 3.887 5.241 11.13.837 15.516l-16.03 15.965a1.25 1.25 0 01-1.764 0L7.587 25.67c-4.404-4.387-4.014-11.629.837-15.516zm12.057 2.471a7.92 7.92 0 00-10.494-.52c-3.687 2.954-3.984 8.46-.636 11.793l15.15 15.087L39.65 23.9c3.347-3.334 3.05-8.84-.637-11.794a7.921 7.921 0 00-10.495.523l-3.142 3.076a1.25 1.25 0 01-1.749 0l-3.146-3.079z\" fill=\"#222326\"/>",
    "attrs": {
      "width": "49",
      "height": "48",
      "viewBox": "0 0 49 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});