define("ember-svg-jar/inlined/person-heart-alt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.11 15.5a4.46 4.46 0 01.41-1.5H4.254a2.249 2.249 0 00-2.25 2.248v.578c0 .892.32 1.756.9 2.435C4.467 21.095 6.853 22 10 22c1.413 0 2.673-.183 3.774-.55l-1.215-1.215c-.763.176-1.615.264-2.559.264-2.738 0-4.704-.745-5.957-2.213a2.25 2.25 0 01-.54-1.461v-.578c0-.414.336-.749.75-.749h5.856zM10 2.003a5 5 0 110 10 5 5 0 010-10zm0 1.5a3.5 3.5 0 100 7 3.5 3.5 0 000-7zm7.044 19.492a.747.747 0 01-.53-.22l-4.409-4.408a3.467 3.467 0 014.903-4.903l.036.036.036-.036a3.467 3.467 0 014.903 4.903l-4.409 4.408a.747.747 0 01-.53.22z\" fill=\"#0439D7\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none"
    }
  };
});