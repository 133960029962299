define("ember-svg-jar/inlined/checkboxUnchecked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\"1\" y=\"1\" width=\"22\" height=\"22\" rx=\"4\" stroke=\"#D6D6D6\" stroke-width=\"2\" fill=\"none\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});