define("ember-svg-jar/inlined/sparkles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.164 15.735c.245.173.537.265.836.264v-.004a1.441 1.441 0 001.327-.872l.613-1.864a2.872 2.872 0 011.817-1.812l1.778-.578a1.442 1.442 0 00-.052-2.74l-1.755-.57a2.876 2.876 0 01-1.822-1.823l-.578-1.777a1.446 1.446 0 00-2.732.022l-.583 1.792a2.877 2.877 0 01-1.77 1.786l-1.777.57a1.444 1.444 0 00.017 2.735l1.754.569a2.887 2.887 0 011.822 1.826l.578 1.775c.099.283.283.527.527.7zm-.374-4.25a4.054 4.054 0 00-.363-.413h.003a4.393 4.393 0 00-1.72-1.063L4.11 9.5l1.611-.524a4.4 4.4 0 001.69-1.065 4.448 4.448 0 001.041-1.708l.515-1.582.516 1.587a4.374 4.374 0 002.781 2.773l1.62.522-1.59.515a4.379 4.379 0 00-2.774 2.775l-.515 1.582-.515-1.585a4.368 4.368 0 00-.7-1.306zm8.041 9.297a1.123 1.123 0 01-.41-.55l-.328-1.006a1.292 1.292 0 00-.821-.823l-.991-.323a1.148 1.148 0 01-.781-1.083 1.143 1.143 0 01.771-1.08l1.006-.326a1.3 1.3 0 00.8-.82l.324-.991a1.143 1.143 0 012.157-.021l.329 1.014a1.3 1.3 0 00.82.816l.992.323a1.141 1.141 0 01.039 2.165l-1.014.329a1.3 1.3 0 00-.818.822l-.322.989c-.078.23-.226.43-.425.57a1.14 1.14 0 01-1.328-.005zm-1.03-3.783a2.79 2.79 0 011.699 1.709 2.793 2.793 0 011.7-1.7 2.813 2.813 0 01-1.718-1.708 2.808 2.808 0 01-1.682 1.699z\" fill=\"#222326\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});