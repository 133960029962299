define("ember-svg-jar/inlined/icon-nav-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 13.5a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0v-2.25a.75.75 0 01.75-.75z\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.5 8.25V6a4.5 4.5 0 019 0v2.25H18a1.5 1.5 0 011.5 1.5V21a1.5 1.5 0 01-1.5 1.5H6A1.5 1.5 0 014.5 21V9.75A1.5 1.5 0 016 8.25h1.5zM15 6v2.25H9V6a3 3 0 116 0zM6 9.75V21h12V9.75H6z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});