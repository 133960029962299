define("ember-svg-jar/inlined/icon-phone-sms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<mask id=\"icon-phone-sms_svg__a\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"64\" height=\"64\"><path fill=\"#C4C4C4\" d=\"M0 0h64v64H0z\"/></mask><g mask=\"url(#icon-phone-sms_svg__a)\"><path d=\"M56.32 10.92H52.6v21.075c0 .684-.556 1.24-1.24 1.24H36.48a6.238 6.238 0 00-3.72 1.24l-2.48 1.859v4.342l4.96-3.716a6.238 6.238 0 013.72-1.24h17.36c.684 0 1.24-.556 1.24-1.24V12.16c0-.684-.556-1.24-1.24-1.24zM37.72 3.48h4.96v9.925h-4.96z\" fill=\"#fff\"/><path d=\"M38.96 35.725a6.15 6.15 0 00-1.24.142V60.52h4.96V35.725h-3.72z\" fill=\"#fff\"/><path d=\"M29.04 53.085h-4.96a1.24 1.24 0 100 2.48h4.96a1.24 1.24 0 100-2.48z\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M56.32 10.925H45.16v-6.2a3.72 3.72 0 00-3.72-3.72H11.68a3.72 3.72 0 00-3.72 3.72v54.56a3.72 3.72 0 003.72 3.72h29.76a3.72 3.72 0 003.72-3.72v-21.08h11.16a3.72 3.72 0 003.72-3.72v-19.84a3.72 3.72 0 00-3.72-3.72zm-13.64 48.36c0 .684-.556 1.24-1.24 1.24H11.68c-.684 0-1.24-.556-1.24-1.24V4.725c0-.685.556-1.24 1.24-1.24h29.76c.684 0 1.24.555 1.24 1.24v6.2H31.52a3.72 3.72 0 00-3.72 3.72V40.68a2.482 2.482 0 002.49 2.484c.534 0 1.053-.176 1.478-.5l4.96-3.715a3.72 3.72 0 012.232-.744h3.72v21.08zm13.64-23.56c.684 0 1.24-.556 1.24-1.24v-19.84c0-.684-.556-1.24-1.24-1.24h-24.8c-.684 0-1.24.556-1.24 1.24V40.68l4.96-3.715a6.238 6.238 0 013.72-1.24h17.36z\" fill=\"#0439D7\"/><path d=\"M36.48 25.805a1.24 1.24 0 100-2.48 1.24 1.24 0 000 2.48zM43.92 25.805a1.24 1.24 0 100-2.48 1.24 1.24 0 000 2.48zM51.36 25.805a1.24 1.24 0 100-2.48 1.24 1.24 0 000 2.48z\" fill=\"#0439D7\"/></g>",
    "attrs": {
      "width": "64",
      "height": "64",
      "viewBox": "0 0 64 64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});