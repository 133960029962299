define("ember-svg-jar/inlined/icon-reset-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#icon-reset-password_svg__clip0)\"><path d=\"M62.863 13.094a3.488 3.488 0 013.488 3.49v41.874a3.489 3.489 0 01-3.488 3.49h-46.5a3.489 3.489 0 01-3.488-3.49V30.167h25.599c1.735 0 1.03-1.608 1.03-17.073h23.36zm0 2.327H40.776v12.795a3.489 3.489 0 01-3.488 3.49H15.2v26.752c.001.642.521 1.163 1.163 1.164h46.5c.642-.001 1.162-.522 1.163-1.164V16.584c0-.642-.52-1.163-1.163-1.163z\" fill=\"#0439D7\"/><path d=\"M55.888 43.337h-32.55a1.163 1.163 0 000 2.326h32.55a1.163 1.163 0 000-2.326zM55.888 50.316h-32.55a1.163 1.163 0 000 2.326h32.55a1.163 1.163 0 000-2.326zM55.888 36.358h-18.6a1.163 1.163 0 000 2.326h18.6a1.163 1.163 0 000-2.326z\" fill=\"#0439D7\"/><path d=\"M38.144 30.233a.812.812 0 00.811-.812V3.436a.812.812 0 00-.811-.812h-5.681v21.11a6.494 6.494 0 01-6.493 6.496H21.1v.003h1.889a2.45 2.45 0 011.474.498l7.995 5.994.005-5.356c0-.628.508-1.136 1.136-1.136h4.545z\" fill=\"#DADDEC\"/><path d=\"M38.144 1H2.434A2.438 2.438 0 000 3.436v25.985a2.438 2.438 0 002.435 2.436h20.553c.18.001.354.06.497.17l7.995 5.993a1.622 1.622 0 002.606-1.292v-4.87h4.058a2.438 2.438 0 002.434-2.437V3.436A2.438 2.438 0 0038.144 1zm.811 28.421a.813.813 0 01-.811.812h-4.545a1.138 1.138 0 00-1.136 1.137l-.005 5.354-7.995-5.994a2.45 2.45 0 00-1.475-.497H2.435a.813.813 0 01-.812-.812V3.436c0-.448.364-.812.812-.812h35.709c.448 0 .81.364.811.812V29.42z\" fill=\"#0439D7\"/><path d=\"M20.29 7.496a5.69 5.69 0 00-5.682 5.684.812.812 0 101.623 0 4.059 4.059 0 118.116 0v.908a2.432 2.432 0 01-1.664 2.31l-1.541.515a2.432 2.432 0 00-1.665 2.31v2.078a.812.812 0 101.624 0v-2.078a.807.807 0 01.555-.769l1.54-.514a4.057 4.057 0 002.774-3.852v-.907a5.69 5.69 0 00-5.68-5.685zM21.1 23.737h-1.623v1.624h1.624v-1.624z\" fill=\"#D51E50\"/></g><defs><clipPath id=\"icon-reset-password_svg__clip0\"><path fill=\"#fff\" d=\"M0 0h66.351v62H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "67",
      "height": "62",
      "viewBox": "0 0 67 62",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});