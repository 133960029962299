define("ember-svg-jar/inlined/no-appointment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.9 3.6a3 3 0 013 3v4.92a6.56 6.56 0 00-1.2-.471V8.4H5.3v9a1.8 1.8 0 001.8 1.8h4.448c.119.418.277.82.472 1.2H7.1a3 3 0 01-3-3V6.6a3 3 0 013-3h10.8zm0 1.2H7.1a1.8 1.8 0 00-1.8 1.8v.6h14.4v-.6a1.8 1.8 0 00-1.8-1.8zM17.15 15a.75.75 0 111.5 0 .75.75 0 01-1.5 0zm1.35 4.8a.6.6 0 01-1.2 0v-2.4a.6.6 0 011.2 0v2.4zm-6-2.4a5.4 5.4 0 1110.8 0 5.4 5.4 0 01-10.8 0zm1.2 0a4.2 4.2 0 108.4 0 4.2 4.2 0 00-8.4 0z\" fill=\"#0028BD\"/>",
    "attrs": {
      "width": "25",
      "height": "24",
      "viewBox": "0 0 25 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});