define("ember-svg-jar/inlined/revoke", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>icon</title><g transform=\"translate(.8)\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M7.2 49.2V9.6H4.8v40.8a4.8 4.8 0 004.8 4.8h33.6v-2.4H10.8a3.6 3.6 0 01-3.6-3.6zM48.631 14.4h-9.04c-.66 0-1.197-.535-1.2-1.195l-.045-9.096L48.63 14.4z\"/><path d=\"M15.6 45.6a1.2 1.2 0 01-1.2-1.2V1.2h-3.6a1.2 1.2 0 00-1.2 1.2v46.8a1.2 1.2 0 001.2 1.2h39.6a1.2 1.2 0 001.2-1.2v-3.6h-36z\"/><path d=\"M51.745 14.121L38.685 1.055A3.601 3.601 0 0036.14 0H10.8a3.6 3.6 0 00-3.6 3.6v3.6H3.6A3.604 3.604 0 000 10.8v45.6A3.604 3.604 0 003.6 60H42a3.604 3.604 0 003.6-3.6v-3.6h3.6a3.6 3.6 0 003.6-3.6V16.669c0-.956-.38-1.872-1.055-2.548zm-3.114.279h-9.04c-.66 0-1.197-.535-1.2-1.195l-.045-9.096L48.63 14.4zm-5.431 42a1.2 1.2 0 01-1.2 1.2H3.6a1.2 1.2 0 01-1.2-1.2V10.8a1.2 1.2 0 011.2-1.2h3.6v39.6a3.6 3.6 0 003.6 3.6h32.4v3.6zm7.2-7.2a1.2 1.2 0 01-1.2 1.2H10.8a1.2 1.2 0 01-1.2-1.2V3.6a1.2 1.2 0 011.2-1.2h25.139l.052 10.814a3.606 3.606 0 003.6 3.586H50.4v32.4z\"/><rect fill=\"#D51E50\" fill-rule=\"nonzero\" x=\"28.8\" y=\"33.6\" width=\"2.4\" height=\"2.4\" rx=\"1.2\"/><rect fill=\"#D51E50\" x=\"28.8\" y=\"16.8\" width=\"2.4\" height=\"14.4\" rx=\"1.2\"/></g>",
    "attrs": {
      "width": "54",
      "height": "60",
      "viewBox": "0 0 54 60",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});