define("ember-svg-jar/inlined/retake", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#retake_svg__clip0_380_7887)\"><rect width=\"40\" height=\"40\" rx=\"20\" fill=\"#E1EAFD\"/><path d=\"M20 12.75a7.25 7.25 0 107.201 6.406c-.068-.587.358-1.156.95-1.156.515 0 .968.358 1.03.87a9.25 9.25 0 11-3.432-6.116v-.504a1 1 0 112.001 0v2.698l.034.052h-.034v.25a1 1 0 01-1 1h-3a1 1 0 110-2h.666A7.218 7.218 0 0020 12.75z\" fill=\"#0439D7\"/></g><defs><clipPath id=\"retake_svg__clip0_380_7887\"><rect width=\"40\" height=\"40\" rx=\"20\" fill=\"#fff\"/></clipPath></defs>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});