define("ember-svg-jar/inlined/thumbs-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.6 6.6h-.8a.8.8 0 00.8.8v-.8zM8.2 1V.2a.8.8 0 00-.731.475L8.2 1zM5 8.2l-.731-.325A.8.8 0 004.2 8.2H5zM5 17h-.8a.8.8 0 00.8.8V17zm9.024 0l.009-.8h-.009v.8zm1.6-1.36l-.79-.12h-.001l.791.12zm1.104-7.2l.79.122V8.56l-.79-.12zm-1.6-1.84v.8h.01l-.01-.8zM5 17.8a.8.8 0 000-1.6v1.6zM5 9a.8.8 0 000-1.6V9zm6.4-2.4V3.4H9.8v3.2h1.6zm0-3.2A3.2 3.2 0 008.2.2v1.6a1.6 1.6 0 011.6 1.6h1.6zM7.469.675l-3.2 7.2 1.462.65 3.2-7.2L7.47.675zM4.2 8.2V17h1.6V8.2H4.2zm.8 9.6h9.024v-1.6H5v1.6zm9.015 0a2.4 2.4 0 002.4-2.04l-1.582-.24a.8.8 0 01-.8.68l-.018 1.6zm2.4-2.038l1.104-7.2-1.582-.243-1.104 7.2 1.582.243zm1.104-7.202a2.4 2.4 0 00-.562-1.935l-1.207 1.05a.8.8 0 01.187.645l1.582.24zm-.562-1.935A2.4 2.4 0 0015.12 5.8l.018 1.6a.8.8 0 01.613.275l1.207-1.05zM15.128 5.8H10.6v1.6h4.528V5.8zM5 16.2H2.6v1.6H5v-1.6zm-2.4 0a.8.8 0 01-.8-.8H.2a2.4 2.4 0 002.4 2.4v-1.6zm-.8-.8V9.8H.2v5.6h1.6zm0-5.6a.8.8 0 01.8-.8V7.4A2.4 2.4 0 00.2 9.8h1.6zm.8-.8H5V7.4H2.6V9z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});