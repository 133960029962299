define("ember-svg-jar/inlined/presentation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.28 3.72c0 2.054-1.558 3.72-3.48 3.72S2.32 5.774 2.32 3.72C2.32 1.666 3.878 0 5.8 0s3.48 1.665 3.48 3.72zm-1.16 0c0-1.37-1.039-2.48-2.32-2.48-1.28.002-2.319 1.111-2.32 2.48 0 1.37 1.039 2.48 2.32 2.48s2.32-1.11 2.32-2.48zm3.48 11.16v4.34c0 1.027-.779 1.86-1.74 1.86h-.58v6.82c0 1.027-.779 1.86-1.74 1.86H4.06c-.961 0-1.74-.833-1.74-1.86v-6.82h-.58c-.961 0-1.74-.833-1.74-1.86v-4.34c0-3.424 2.597-6.2 5.8-6.2s5.8 2.776 5.8 6.2zm-1.74 4.96c.32 0 .58-.278.58-.62v-4.34c0-2.74-2.077-4.96-4.64-4.96-2.563 0-4.64 2.22-4.64 4.96v4.34c0 .342.26.62.58.62h.58c.64 0 1.16.555 1.16 1.24v6.82c0 .342.26.62.58.62h3.48c.32 0 .58-.278.58-.62v-6.82c0-.685.52-1.24 1.16-1.24h.58z\" fill=\"currentColor\"/><path d=\"M25.52 19.22V9.3c0-.342-.26-.62-.58-.62-.32 0-.58.278-.58.62v9.92c0 .342.26.62.58.62.32 0 .58-.278.58-.62zm-10.44 0V15.5c0-.342-.26-.62-.58-.62-.32 0-.58.278-.58.62v3.72c0 .342.26.62.58.62.32 0 .58-.278.58-.62zm6.96 0v-6.2c0-.342-.26-.62-.58-.62-.32 0-.58.278-.58.62v6.2c0 .342.26.62.58.62.32 0 .58-.278.58-.62zm-3.48 0V8.06c0-.342-.26-.62-.58-.62-.32 0-.58.278-.58.62v11.16c0 .342.26.62.58.62.32 0 .58-.278.58-.62z\" fill=\"currentColor\"/><path d=\"M27.26 2.48H11.02c-.32 0-.58.278-.58.62 0 .342.26.62.58.62h16.24c.32 0 .58.278.58.62V21.7c0 .342-.26.62-.58.62H11.02c-.32 0-.58.278-.58.62 0 .342.26.62.58.62h16.24c.961 0 1.74-.833 1.74-1.86V4.34c0-1.027-.779-1.86-1.74-1.86z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "29",
      "height": "30",
      "viewBox": "0 0 29 30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});