define("ember-svg-jar/inlined/remind-suggestions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M59.2 30.35H20.65C9.245 30.35 0 39.595 0 51s9.245 20.65 20.65 20.65H59.2c11.405 0 20.65-9.245 20.65-20.65S70.605 30.35 59.2 30.35z\" fill=\"#ECF0FE\"/><path d=\"M59.13 26.47h3.76a4.77 4.77 0 014.58 5l-.36 20.3a4.76 4.76 0 01-4.58 4.85H55.3v10.45L40.19 56.62H17.48a4.77 4.77 0 01-4.58-4.93v-20.3a4.76 4.76 0 014.58-4.92h41.65z\" fill=\"#fff\" stroke=\"#080F8C\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M32.17 31.17c.93-5.06 1.18-2.48 3.57-6.52a9.65 9.65 0 10-16.58 0c2.4 4 2.65 1.46 3.58 6.52\" fill=\"#0439D7\"/><path d=\"M32.17 31.17c.93-5.06 1.18-2.48 3.57-6.52a9.65 9.65 0 10-16.58 0c2.4 4 2.65 1.46 3.58 6.52\" stroke=\"#0439D7\" stroke-width=\"1.17\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M32.38 31.17h-9.86c-.64 0-1.16.52-1.16 1.16v.01c0 .64.52 1.16 1.16 1.16h9.86c.64 0 1.16-.52 1.16-1.16v-.01c0-.64-.52-1.16-1.16-1.16zM30.32 34.99h-5.75a.8.8 0 00-.8.8v.01a.8.8 0 00.8.8h5.75a.8.8 0 00.8-.8v-.01a.8.8 0 00-.8-.8z\" fill=\"#080F8C\"/><path d=\"M21.49 7.34l-2.78-2.73M27.83 4.89L28.05 1M34.01 6.98l2.19-3.23\" stroke=\"#080F8C\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M49.67 36.48A9.48 9.48 0 0059.15 27a9.48 9.48 0 10-9.48 9.48z\" fill=\"#fff\" stroke=\"#080F8C\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M48.7 30.38a1 1 0 112 0 1 1 0 01-2 0zm.31-2.36l-.12-4.53a.778.778 0 011.094-.8.78.78 0 01.456.8l-.11 4.53a.66.66 0 01-1.32 0zM30.99 45.37a1.41 1.41 0 100-2.82 1.41 1.41 0 000 2.82zM41.33 45.37a1.41 1.41 0 100-2.82 1.41 1.41 0 000 2.82zM51.67 45.37a1.41 1.41 0 100-2.82 1.41 1.41 0 000 2.82z\" fill=\"#080F8C\"/>",
    "attrs": {
      "width": "80",
      "height": "72",
      "viewBox": "0 0 80 72",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});