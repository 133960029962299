define("ember-svg-jar/inlined/progress-bar-finished", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Done</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M24 0c13.254 0 24 10.745 24 24S37.254 48 24 48 0 37.255 0 24 10.746 0 24 0z\" fill=\"#2B6CDE\" fill-rule=\"nonzero\" opacity=\".1\"/><path d=\"M24 40c-8.836 0-16-7.163-16-16S15.164 8 24 8s16 7.163 16 16-7.164 16-16 16z\" fill=\"#2B6CDE\" fill-rule=\"nonzero\"/><path d=\"M27.364 18.577c.52-.672 1.456-.772 2.091-.221.635.55.729 1.542.21 2.214l-6.839 8.853c-.56.725-1.59.774-2.21.106l-3.19-3.443a1.64 1.64 0 01.019-2.225 1.428 1.428 0 012.1.02l2.03 2.19 5.79-7.494z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "viewBox": "0 0 48 48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});