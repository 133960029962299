define("ember-svg-jar/inlined/caravan-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M20.117 24.214H7.234v-1h12.883v1zm10.008 0h-5.982v-1h5.982a.5.5 0 110 1z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M2.754 6.827c-.159.186-.254.488-.254.94v14.74c0 .12.04.314.147.462.089.123.24.245.56.245v1c-.645 0-1.097-.28-1.371-.66a1.849 1.849 0 01-.336-1.047V7.766c0-.564.114-1.145.494-1.59.392-.458.986-.677 1.738-.677H24.73l.032.004c.626.081 1.803.66 1.893 2.234v15.976h-1V7.782c-.058-.93-.697-1.23-.994-1.282H3.73c-.56 0-.832.157-.977.327z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M5.22 25.13a1.416 1.416 0 100-2.831 1.416 1.416 0 000 2.83zm0 1a2.416 2.416 0 100-4.831 2.416 2.416 0 000 4.83zm16.91-1a1.416 1.416 0 100-2.831 1.416 1.416 0 000 2.83zm0 1a2.416 2.416 0 100-4.831 2.416 2.416 0 000 4.83zM10.169 10.957c-.01.046-.02.13-.02.277v12.48h-1v-12.48c0-.394.043-.889.5-1.142a1.4 1.4 0 01.572-.15c.179-.013.392-.013.62-.013h5.653c.214 0 .524.06.795.255.296.213.51.565.51 1.05v12.48h-1v-12.48c0-.09-.018-.14-.033-.169a.194.194 0 00-.061-.07.402.402 0 00-.211-.066h-5.637c-.249 0-.422 0-.557.01a1.024 1.024 0 00-.131.018z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M9.65 14.15H2v-1h7.65v1zm16.506 0H17.7v-1h8.455v1zM9.649 17.772H2v-1h7.65v1zm8.052 0v-1h8.052v1h-8.052z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});