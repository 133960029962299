define("ember-svg-jar/inlined/bu-gap-calculator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7 7a2 2 0 012-2h6a2 2 0 012 2v1a2 2 0 01-2 2H9a2 2 0 01-2-2V7zm2-.5a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h6a.5.5 0 00.5-.5V7a.5.5 0 00-.5-.5H9zm-.75 8a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zm1.25 2.75a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zm6.25-2.75a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zM17 17.25a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zm-5-2.75a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zm1.25 2.75a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0zM7.25 2A3.25 3.25 0 004 5.25v13.5A3.25 3.25 0 007.25 22h9.5A3.25 3.25 0 0020 18.75V5.25A3.25 3.25 0 0016.75 2h-9.5zM5.5 5.25c0-.966.784-1.75 1.75-1.75h9.5c.966 0 1.75.784 1.75 1.75v13.5a1.75 1.75 0 01-1.75 1.75h-9.5a1.75 1.75 0 01-1.75-1.75V5.25z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});