define("ember-svg-jar/inlined/info-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#222326\" d=\"M24 6.5C14.335 6.5 6.5 14.335 6.5 24S14.335 41.5 24 41.5 41.5 33.665 41.5 24 33.665 6.5 24 6.5zM4 24C4 12.954 12.954 4 24 4s20 8.954 20 20-8.954 20-20 20S4 35.046 4 24zm20-4c.69 0 1.25.56 1.25 1.25v12.5a1.25 1.25 0 11-2.5 0v-12.5c0-.69.56-1.25 1.25-1.25zm0-3a2 2 0 100-4 2 2 0 000 4z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "48",
      "height": "48",
      "fill": "none",
      "viewBox": "0 0 48 48"
    }
  };
});