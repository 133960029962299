define("ember-svg-jar/inlined/union", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.183.582a.75.75 0 01.75.75v.75h7.5v-.75a.75.75 0 011.5 0v.75h2.25a1.5 1.5 0 011.5 1.5v15a1.5 1.5 0 01-1.5 1.5h-15a1.5 1.5 0 01-1.5-1.5v-15a1.5 1.5 0 011.5-1.5h2.25v-.75a.75.75 0 01.75-.75zm-.75 3h-2.25v3h15v-3h-2.25v.75a.75.75 0 01-1.5 0v-.75h-7.5v.75a.75.75 0 11-1.5 0v-.75zm12.75 4.5h-15v10.5h15v-10.5z\" fill=\"#D51E50\"/>",
    "attrs": {
      "width": "19",
      "height": "21",
      "viewBox": "0 0 19 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});