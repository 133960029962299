define("ember-svg-jar/inlined/chat-bubbles-question", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"currentColor\"><path d=\"M8.144 6.307c.434-.232.901-.306 1.356-.306.526 0 1.138.173 1.632.577.517.424.868 1.074.868 1.922 0 .975-.689 1.504-1.077 1.802l-.085.066c-.424.333-.588.511-.588.882a.75.75 0 01-1.5 0c0-1.134.711-1.708 1.162-2.062.513-.403.588-.493.588-.688 0-.397-.149-.622-.32-.761A1.115 1.115 0 009.5 7.5c-.295 0-.498.049-.65.13-.143.076-.294.21-.44.48a.75.75 0 11-1.32-.715c.264-.486.612-.853 1.054-1.089zM9.5 15a1 1 0 100-2 1 1 0 000 2z\"/><path d=\"M9.5 3a7.5 7.5 0 00-6.797 10.673l-.725 2.842a1.25 1.25 0 001.504 1.524c.75-.18 1.903-.457 2.93-.702A7.5 7.5 0 109.5 3zm-6 7.5a6 6 0 113.33 5.375l-.243-.121-.265.063-2.788.667c.2-.78.462-1.812.69-2.708l.07-.276-.13-.253A5.971 5.971 0 013.5 10.5z\"/><path d=\"M14.5 21c-1.97 0-3.761-.759-5.1-2h.1c.718 0 1.415-.089 2.081-.257.864.482 1.86.757 2.92.757.96 0 1.866-.225 2.669-.625l.243-.121.265.063c.921.22 1.965.445 2.74.61-.176-.751-.415-1.756-.642-2.651l-.07-.276.13-.253A5.971 5.971 0 0020.5 13.5a5.995 5.995 0 00-2.747-5.042 8.443 8.443 0 00-.8-2.047 7.503 7.503 0 014.344 10.263c.253 1.008.51 2.1.672 2.803a1.244 1.244 0 01-1.468 1.5c-.727-.152-1.87-.396-2.913-.64A7.476 7.476 0 0114.5 21z\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});