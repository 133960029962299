define("ember-svg-jar/inlined/bell-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.478 5.727a5.525 5.525 0 0111.045 0l.061 1.966c.003.07.025.14.065.198l.945 1.396c.681 1.005.06 2.388-1.169 2.507-1.493.145-3.737.331-5.425.331-1.688 0-3.932-.186-5.425-.33-1.228-.12-1.85-1.503-1.169-2.508l.945-1.395.503.34-.503-.34a.375.375 0 00.065-.199l.062-1.966zM7 1.625a4.275 4.275 0 00-4.273 4.141l-.062 1.966c-.01.308-.106.606-.279.86l-.945 1.396a.357.357 0 00.255.562c1.49.144 3.682.325 5.304.325 1.623 0 3.814-.18 5.304-.325a.357.357 0 00.255-.562l-.945-1.396a1.625 1.625 0 01-.279-.86l-.062-1.966A4.275 4.275 0 007 1.625zm-.964 12.423c.24.21.585.327.964.327s.724-.117.964-.327c.227-.197.411-.52.411-1.048h1.25c0 .856-.316 1.534-.84 1.99-.509.445-1.164.635-1.785.635s-1.276-.19-1.786-.635c-.523-.456-.839-1.134-.839-1.99h1.25c0 .529.184.85.41 1.048z\" fill=\"#575970\"/>",
    "attrs": {
      "width": "14",
      "height": "16",
      "viewBox": "0 0 14 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});