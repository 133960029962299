define("ember-svg-jar/inlined/person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.754 12a2.249 2.249 0 012.249 2.25v.575c0 .894-.32 1.759-.901 2.438-1.57 1.833-3.957 2.738-7.102 2.738-3.146 0-5.532-.905-7.098-2.74a3.75 3.75 0 01-.899-2.434v-.578A2.249 2.249 0 012.253 12h11.501zm0 1.5H2.252a.749.749 0 00-.749.75v.577c0 .535.192 1.053.54 1.46C3.295 17.757 5.261 18.502 8 18.502s4.706-.745 5.962-2.213a2.25 2.25 0 00.54-1.463v-.576a.749.749 0 00-.748-.749zM8 .005a5 5 0 110 10 5 5 0 010-10zm0 1.5a3.5 3.5 0 100 7 3.5 3.5 0 000-7z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "17",
      "height": "21",
      "viewBox": "0 0 17 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});