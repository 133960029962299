define("ember-svg-jar/inlined/people-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.754 7c.966 0 1.75.784 1.75 1.75V12H14.5v.25a.75.75 0 01-1.5 0V10h.004V8.75a.25.25 0 00-.25-.25H7.252a.25.25 0 00-.25.25V12H7v.25a.75.75 0 01-1.5 0V10h.002V8.75c0-.966.783-1.75 1.75-1.75h5.502zM18.5 8.75v3.5a.75.75 0 001.5 0v-3.5A1.75 1.75 0 0018.25 7h-3.375c.343.415.567.933.618 1.5h2.757a.25.25 0 01.25.25zM0 12.25a.75.75 0 001.5 0v-3.5a.25.25 0 01.25-.25h2.763A2.738 2.738 0 015.131 7H1.75A1.75 1.75 0 000 8.75v3.5zM10 0a3 3 0 110 6 3 3 0 010-6zm0 1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm6.5-.5a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm0 1.5a1 1 0 100 2 1 1 0 000-2zM3.5 1a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm0 1.5a1 1 0 100 2 1 1 0 000-2zM.75 14a.75.75 0 00-.75.75v.5A3.75 3.75 0 003.75 19h12.5A3.75 3.75 0 0020 15.25v-.5a.75.75 0 00-.75-.75H.75zm3 3.5a2.25 2.25 0 01-2.236-2h16.972a2.25 2.25 0 01-2.236 2H3.75z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "35",
      "height": "35",
      "viewBox": "0 0 35 35",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});