define("ember-svg-jar/inlined/orangeCheckMarkBulletin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><path id=\"orangeCheckMarkBulletin_svg__a\" d=\"M12 21.167a9.167 9.167 0 110-18.334 9.167 9.167 0 010 18.334zm2.477-12.14l-3.71 4.684-1.302-1.369a.931.931 0 00-1.347-.012 1.007 1.007 0 00-.012 1.39l2.046 2.152a.933.933 0 001.416-.066l4.383-5.533c.333-.42.273-1.04-.134-1.384a.932.932 0 00-1.34.138z\"/></defs><use fill=\"#F99400\" xlink:href=\"#orangeCheckMarkBulletin_svg__a\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 24 24"
    }
  };
});