define("ember-svg-jar/inlined/icon-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 4.5H8.5V1M12 4.5v8.25a.25.25 0 01-.25.25H1.25a.25.25 0 01-.25-.25V1.25A.25.25 0 011.25 1H8.5M12 4.5L8.5 1\" stroke=\"#0439D7\" stroke-width=\"1.5\"/>",
    "attrs": {
      "width": "13",
      "height": "14",
      "viewBox": "0 0 13 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});