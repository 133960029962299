define("ember-svg-jar/inlined/backup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M9.824.376a.6.6 0 10-.848.848L10.35 2.6H9.4a9 9 0 109 9 .6.6 0 00-1.2 0 7.8 7.8 0 11-7.8-7.8h.951L8.976 5.176a.6.6 0 10.848.848l2.4-2.4a.6.6 0 000-.848l-2.4-2.4zm4.192 7.192a.6.6 0 01.016.848l-4.62 4.8a.6.6 0 01-.864 0l-1.98-2.057a.6.6 0 01.864-.832l1.548 1.608 4.188-4.351a.6.6 0 01.848-.016z\" fill=\"#203151\"/>",
    "attrs": {
      "width": "19",
      "height": "21",
      "viewBox": "0 0 19 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});