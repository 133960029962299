define("ember-svg-jar/inlined/clark-shape", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g filter=\"url(#filter0_d)\"><rect x=\"6\" y=\"5\" width=\"16\" height=\"16\" rx=\"8\" fill=\"#0439D7\"/><path d=\"M9.25 13c0-2.795 2.196-5 5.02-5 1.497 0 2.886.644 3.763 1.731.163.202.217.334.217.482 0 .28-.248.512-.543.512a.58.58 0 01-.481-.233c-.76-.955-1.754-1.436-2.94-1.436-2.196 0-3.849 1.7-3.849 3.944 0 2.244 1.653 3.944 3.848 3.944 1.187 0 2.18-.497 2.94-1.436a.59.59 0 01.482-.233c.295 0 .543.233.543.512 0 .148-.062.28-.217.482C17.187 17.356 15.775 18 14.27 18c-2.824-.008-5.02-2.205-5.02-5z\" fill=\"#fff\"/><rect x=\"5.25\" y=\"4.25\" width=\"17.5\" height=\"17.5\" rx=\"8.75\" stroke=\"#fff\" stroke-width=\"1.5\"/></g><defs><filter id=\"filter0_d\" x=\".5\" y=\".5\" width=\"27\" height=\"27\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\"><feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/><feColorMatrix in=\"SourceAlpha\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\"/><feOffset dy=\"1\"/><feGaussianBlur stdDeviation=\"2\"/><feColorMatrix values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.17 0\"/><feBlend in2=\"BackgroundImageFix\" result=\"effect1_dropShadow\"/><feBlend in=\"SourceGraphic\" in2=\"effect1_dropShadow\" result=\"shape\"/></filter></defs>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});