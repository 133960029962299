define("ember-svg-jar/inlined/tick-filled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"9\" cy=\"9\" r=\"9\" fill=\"currentColor\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.644 5.36a.932.932 0 011.34-.138c.407.344.467.964.134 1.385l-4.383 5.532a.933.933 0 01-1.417.067l-2.045-2.152a1.007 1.007 0 01.012-1.391.931.931 0 011.347.013l1.301 1.369 3.71-4.684z\" fill=\"#fff\"/>",
    "attrs": {
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});