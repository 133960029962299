define("ember-svg-jar/inlined/attachment-success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>1EC7AAA5-7471-41B9-B0C8-ABE9C13E5ACA</title><defs><path d=\"M10.489.28a.839.839 0 011.252.001c.346.375.345.981-.001 1.355L5.18 8.72a.839.839 0 01-1.246.006L.265 4.83a1.015 1.015 0 01-.012-1.355.839.839 0 011.253-.012l3.043 3.231L10.49.28z\" id=\"attachment-success_svg__a\"/></defs><g transform=\"translate(1 1)\" fill=\"none\" fill-rule=\"evenodd\"><mask id=\"attachment-success_svg__b\" fill=\"#fff\"><use xlink:href=\"#attachment-success_svg__a\"/></mask><use stroke=\"#2B6CDE\" stroke-width=\".2\" fill=\"#979797\" fill-rule=\"nonzero\" xlink:href=\"#attachment-success_svg__a\"/><g mask=\"url(#attachment-success_svg__b)\" fill=\"#2B6CDE\"><path d=\"M-6-8h24v24H-6z\"/></g></g>",
    "attrs": {
      "width": "14",
      "height": "11",
      "viewBox": "0 0 14 11",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
});