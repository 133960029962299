define("ember-svg-jar/inlined/accident-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2.06 9.132a2.5 2.5 0 000 3.535l1.586 1.587a.5.5 0 00.708-.707L2.768 11.96a1.5 1.5 0 010-2.121l7.071-7.071a1.5 1.5 0 012.121 0l1.586 1.586a.5.5 0 10.708-.708L12.667 2.06a2.5 2.5 0 00-3.535 0L2.06 9.133zM18.253 27.446a.5.5 0 00-.706.708l1.484 1.484a2.5 2.5 0 003.536 0l7.07-7.071a2.5 2.5 0 00.001-3.536l-1.484-1.484a.5.5 0 00-.708.706l1.485 1.485a1.5 1.5 0 010 2.122l-7.072 7.07a1.5 1.5 0 01-2.12 0l-1.486-1.484zM17 14a1 1 0 11-2 0 1 1 0 012 0zM18 17a1 1 0 100-2 1 1 0 000 2zM17 18a1 1 0 11-2 0 1 1 0 012 0zM14 17a1 1 0 100-2 1 1 0 000 2z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M22.567 2.06a2.5 2.5 0 00-3.536 0L2.061 19.032a2.5 2.5 0 000 3.536l7.07 7.07a2.5 2.5 0 003.536 0l16.97-16.97a2.5 2.5 0 000-3.535l-7.07-7.071zm-2.829.708a1.5 1.5 0 012.122 0l7.07 7.07a1.5 1.5 0 010 2.122L11.96 28.93a1.5 1.5 0 01-2.121 0l-7.071-7.07a1.5 1.5 0 010-2.122l16.97-16.97z\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});