define("ember-asset-loader/errors/asset-load", ["exports", "ember-asset-loader/errors/load", "ember-asset-loader/services/asset-loader"], function (_exports, _load, _assetLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Represents an error that occurred while trying to load an asset.
   *
   * @class AssetLoadError
   * @extends LoadError
   */
  class AssetLoadError extends _load.default {
    /**
     * Constructs a new AssetLoadError from the original error and the info of the
     * asset that was attempting to load.
     *
     * @param {AssetLoader} assetLoader
     * @param {Asset} asset
     * @param {Error} error
     */
    constructor(assetLoader, asset, error) {
      super("The ".concat(asset.type, " asset with uri \"").concat(asset.uri, "\" failed to load with the error: ").concat(error, "."), assetLoader);
      this.name = 'AssetLoadError';
      this.asset = asset;
      this.originalError = error;
    }
    retryLoad() {
      return this._invokeAndCache('loadAsset', this.asset, _assetLoader.RETRY_LOAD_SECRET);
    }
  }
  _exports.default = AssetLoadError;
});