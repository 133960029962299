define("ember-svg-jar/inlined/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.55.532a2.25 2.25 0 012.9 0l6.75 5.692c.507.428.8 1.057.8 1.72v9.31a1.75 1.75 0 01-1.75 1.75h-3.5a1.75 1.75 0 01-1.75-1.75v-5.007a.25.25 0 00-.25-.25h-3.5a.25.25 0 00-.25.25v5.007a1.75 1.75 0 01-1.75 1.75h-3.5A1.75 1.75 0 013 17.254v-9.31c0-.663.293-1.292.8-1.72L10.55.532zm1.933 1.147a.75.75 0 00-.966 0L4.767 7.37a.75.75 0 00-.267.573v9.31c0 .138.112.25.25.25h3.5a.25.25 0 00.25-.25v-5.007c0-.967.784-1.75 1.75-1.75h3.5c.966 0 1.75.783 1.75 1.75v5.007c0 .138.112.25.25.25h3.5a.25.25 0 00.25-.25v-9.31a.75.75 0 00-.267-.573l-6.75-5.692z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "24",
      "height": "20",
      "viewBox": "0 0 24 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});