define("ember-svg-jar/inlined/like-blue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.272 4.122C22 4.89 22 9 22 9v5h8.64a2 2 0 011.972 2.329l-2.334 14A2 2 0 0128.306 32H7a2 2 0 01-2-2V19a2 2 0 012-2h4l5.464-11.838c.327-.708 1.044-1.197 1.808-1.04zM13 17.44l5.202-11.27c.811.267 1.226.823 1.485 1.446A4.366 4.366 0 0120 9.014V14a2 2 0 002 2h8.64l-2.334 14H13V17.44zM11 19H7v11h4V19z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "42",
      "height": "42",
      "viewBox": "0 0 42 42",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});