define("ember-svg-jar/inlined/contract-adding", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21.7 56.55c7.04-.35 12.9-.47 22.2-10.23-7.21-5.22-10.23-17.14-12-28.53L.32 18.96C2.68 34.27 7.2 56.75 21.7 56.53\" fill=\"#fff\"/><path d=\"M20.59 56.77C7.43 55.91 2.7 36.23.05 19L0 18.71l32.14-1.19.04.24c1.62 10.36 4.43 22.95 11.89 28.36l.24.18-.2.21c-9.22 9.67-15.15 9.96-22.03 10.29l-.36.02v-.23.21c-.38 0-.75 0-1.11-.03h-.02zM.63 19.21c2.7 17.42 7.53 37.25 21.06 37.05v.02l.35-.02c6.7-.33 12.5-.61 21.45-9.91-7.39-5.57-10.21-17.98-11.83-28.29L.63 19.21z\" fill=\"#222326\"/><path d=\"M7.99 28.38c-.19-.01-.36-.17-.36-.36a.38.38 0 01.37-.39l20.08-.75c.21-.01.39.15.4.36a.38.38 0 01-.37.39l-20.08.75h-.04zM9.16 32.25c-.19-.01-.36-.17-.36-.36a.38.38 0 01.37-.39l20.07-.75c.21-.01.39.15.4.36a.38.38 0 01-.37.39l-20.07.75h-.04zM10.46 35.64c-.19-.01-.36-.17-.36-.36a.38.38 0 01.37-.39l20.07-.75c.21-.01.39.15.4.36a.38.38 0 01-.37.39l-20.07.75h-.04zM12.37 39.51c-.19-.01-.36-.17-.36-.36a.38.38 0 01.37-.39l20.08-.75c.21-.01.39.15.4.36a.38.38 0 01-.37.39l-20.08.75h-.04zM15.47 43.6c-.19-.01-.36-.17-.36-.36a.38.38 0 01.37-.39l20.07-.75c.23 0 .39.15.4.36.01.21-.16.39-.37.39l-20.07.75h-.04z\" fill=\"#222326\"/><path d=\"M29.74 24.51c4.98 0 9.02-3.92 9.02-8.76S34.72 7 29.74 7c-4.98 0-9.02 3.92-9.02 8.76s4.04 8.76 9.02 8.76v-.01z\" fill=\"#1638CE\"/><path d=\"M30.29 11.71H29.2v9.48h1.09v-9.48z\" fill=\"#fff\"/><path d=\"M34.62 15.93h-9.76v1.05h9.76v-1.05z\" fill=\"#fff\"/><path d=\"M64 45.5c-3.55 5.17-8.5 9.71-12.39 9.94L21.7 56.55c3.29-.29 11.3-3.37 14.02-10.43L64 45.5z\" fill=\"#222326\"/>",
    "attrs": {
      "width": "64",
      "height": "64",
      "viewBox": "0 0 64 64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});