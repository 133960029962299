define("ember-svg-jar/inlined/clark-contracts-assets--arrows-bidirectional", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.501 9H8.504l.001-2.25a.75.75 0 00-1.268-.543l-5.503 5.25a.75.75 0 000 1.086l5.503 5.25.083.069a.75.75 0 001.185-.612L8.504 15h5.997v2.25c0 .66.791.998 1.269.543l5.497-5.25a.75.75 0 000-1.085l-5.497-5.25a.75.75 0 00-1.268.542L14.5 9zM3.337 12l3.667-3.499.001 1.249c0 .414.336.75.75.75h7.497l.101-.007a.75.75 0 00.649-.743L16 8.503 19.662 12l-3.661 3.496v-1.245a.75.75 0 00-.75-.75H7.756l-.102.007a.75.75 0 00-.648.743l-.001 1.247L3.337 12z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});