define("ember-svg-jar/inlined/eye", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<mask id=\"eye_svg__a\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"24\" height=\"24\"><path fill=\"#C4C4C4\" d=\"M0 0h24v24H0z\"/></mask><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21 12s-3.375-6.188-9-6.188S3 12 3 12s3.375 6.188 9 6.188S21 12 21 12zM4.32 12a14.78 14.78 0 001.867 2.298c1.448 1.45 3.428 2.764 5.813 2.764s4.364-1.314 5.814-2.764A14.782 14.782 0 0019.681 12a14.78 14.78 0 00-1.867-2.298c-1.45-1.45-3.43-2.764-5.814-2.764-2.385 0-4.364 1.314-5.814 2.764A14.778 14.778 0 004.319 12z\" fill=\"#575970\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 9.188a2.812 2.812 0 100 5.624 2.812 2.812 0 000-5.624zM8.062 12a3.937 3.937 0 117.875 0 3.937 3.937 0 01-7.874 0z\" fill=\"#575970\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});