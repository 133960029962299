define("ember-svg-jar/inlined/info-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><path d=\"M9 18A9 9 0 119 0a9 9 0 010 18zm0-1.503A7.5 7.5 0 109 1.5a7.5 7.5 0 100 14.997zm.05-11.558c.439 0 .818.364.818.828 0 .455-.38.819-.817.819a.823.823 0 110-1.646zm-.055 8.44c-.438 0-.771-.346-.771-.796v-4.39c0-.45.333-.784.782-.784a.76.76 0 01.772.783v4.391c0 .45-.334.795-.783.795z\" id=\"info-light_svg__a\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><mask id=\"info-light_svg__b\" fill=\"#fff\"><use xlink:href=\"#info-light_svg__a\"/></mask><use fill=\"#2B6CDE\" fill-rule=\"nonzero\" xlink:href=\"#info-light_svg__a\"/><g mask=\"url(#info-light_svg__b)\"><path fill=\"#2B6CDE\" d=\"M0 0h18v18H0z\"/><path fill=\"#C3CBD9\" d=\"M0 0h18v18H0z\"/></g></g>",
    "attrs": {
      "width": "18",
      "height": "18",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
});