define("ember-svg-jar/inlined/x-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.04 7.345a.687.687 0 00.005.95c.264.259.705.254.95.01L5 5.299 8.002 8.3c.253.254.69.254.95-.005a.682.682 0 00.004-.95l-3-3 3-3.007a.677.677 0 00-.005-.95.682.682 0 00-.95-.005l-3 3.001-3.007-3a.682.682 0 00-.95.004c-.258.26-.253.706-.005.95l3.002 3.006-3.002 3.001z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "10",
      "height": "9",
      "viewBox": "0 0 10 9",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});