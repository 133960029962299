define("ember-svg-jar/inlined/task-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.667 12.333a1 1 0 011-1h4.666a1 1 0 010 2h-4.666a1 1 0 01-1-1zm1 6.334a1 1 0 100 2h4.666a1 1 0 100-2h-4.666zm-3.293-6.96a1 1 0 10-1.414-1.414L11 12.253l-.626-.627A1 1 0 008.96 13.04l1.333 1.334a1 1 0 001.414 0l2.667-2.667zm0 5.92a1 1 0 010 1.413l-2.667 2.667a1 1 0 01-1.414 0L8.96 20.374a1 1 0 111.414-1.414l.626.626 1.96-1.96a1 1 0 011.414 0zM8.334 4A4.333 4.333 0 004 8.333v15.334A4.333 4.333 0 008.333 28h15.334A4.333 4.333 0 0028 23.667V8.333A4.333 4.333 0 0023.667 4H8.333zM6 8.333A2.333 2.333 0 018.333 6h15.334A2.333 2.333 0 0126 8.333v15.334A2.333 2.333 0 0123.667 26H8.333A2.333 2.333 0 016 23.667V8.333z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});