define("ember-svg-jar/inlined/exclamation-filled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 8A7 7 0 111 8a7 7 0 0114 0zM8 4.5a.5.5 0 01.5.5v3a.5.5 0 01-1 0V5a.5.5 0 01.5-.5zm.5 6a.5.5 0 00-1 0v.5a.5.5 0 001 0v-.5z\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "fill": "var(--i-exclamation-filled, currentColor)",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});