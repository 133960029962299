define("ember-svg-jar/inlined/scan-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.25 3A3.25 3.25 0 003 6.25v2.12a.75.75 0 001.5 0V6.25c0-.966.784-1.75 1.75-1.75h2.12a.75.75 0 000-1.5H6.25zm9.38 1.5a.75.75 0 010-1.5h2.12A3.25 3.25 0 0121 6.25v2.12a.75.75 0 01-1.5 0V6.25a1.75 1.75 0 00-1.75-1.75h-2.12zM3 15.63a.75.75 0 011.5 0v2.12a1.75 1.75 0 001.75 1.75h2.12a.75.75 0 010 1.5H6.25A3.25 3.25 0 013 17.75v-2.12zm16.5 0a.75.75 0 011.5 0v2.12A3.25 3.25 0 0117.75 21h-2.12a.75.75 0 010-1.5h2.12a1.75 1.75 0 001.75-1.75v-2.12zM7 7.75A.75.75 0 017.75 7h8.5a.75.75 0 010 1.5h-8.5A.75.75 0 017 7.75zM7.75 11a.75.75 0 000 1.5h8.5a.75.75 0 000-1.5h-8.5zM7 15.75a.75.75 0 01.75-.75h4.5a.75.75 0 010 1.5h-4.5a.75.75 0 01-.75-.75z\" fill=\"#001DA4\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});