define("ember-svg-jar/inlined/answer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\"1\" y=\".5\" width=\"39\" height=\"39\" rx=\"19.5\" fill=\"#F5F6FA\"/><path d=\"M18.062 11a7.5 7.5 0 00-6.798 10.673l-.724 2.842a1.25 1.25 0 001.504 1.524c.75-.18 1.903-.457 2.93-.702A7.5 7.5 0 1018.062 11zm-6 7.5a6 6 0 113.33 5.375l-.244-.121-.264.063c-.923.22-1.99.475-2.788.667l.69-2.708.07-.276-.13-.253a5.97 5.97 0 01-.664-2.747zm11 10.5a7.475 7.475 0 01-5.1-2h.1c.718 0 1.415-.089 2.08-.257.865.482 1.86.757 2.92.757.96 0 1.866-.225 2.67-.625l.243-.121.264.063c.922.22 1.966.445 2.74.61-.175-.751-.414-1.756-.642-2.651l-.07-.276.13-.253a5.971 5.971 0 00.665-2.747 5.995 5.995 0 00-2.747-5.042 8.441 8.441 0 00-.8-2.047 7.503 7.503 0 014.344 10.263c.253 1.008.509 2.1.671 2.803a1.244 1.244 0 01-1.467 1.5 132.62 132.62 0 01-2.913-.64 7.476 7.476 0 01-3.088.663z\" fill=\"#203151\"/><rect x=\"1\" y=\".5\" width=\"39\" height=\"39\" rx=\"19.5\" stroke=\"#CFD3DA\"/>",
    "attrs": {
      "width": "41",
      "height": "40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});