define("ember-svg-jar/inlined/rightArrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Shape</title><path d=\"M1.527 0L0 1.517 5.016 6.5 0 11.483 1.527 13l6.542-6.5L1.527 0z\" fill=\"#FFF\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "9",
      "height": "13",
      "viewBox": "0 0 9 13",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});