define("ember-svg-jar/inlined/supplementary-health-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M13.5 12a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2.5H19a.5.5 0 01.5.5v2.4a.5.5 0 01-.5.5h-2.5V21a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-3H11a.5.5 0 01-.5-.5V15a.5.5 0 01.5-.5h2.5V12zm1 .5V15a.5.5 0 01-.5.5h-2.5V17H14a.5.5 0 01.5.5v3h1v-3.1a.5.5 0 01.5-.5h2.5v-1.4H16a.5.5 0 01-.5-.5v-2.5h-1z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M28.117 5.216c1.269 1.96 1.575 4.506 1.409 6.33-.52 5.724-4.678 11.338-14.188 18.846l-.319.252-.396-.33c-2.973-2.478-6.346-5.288-9.04-8.387C2.864 18.803.781 15.326.528 11.533.268 7.616 1.8 5.151 3.808 3.775c1.968-1.349 4.364-1.63 5.841-1.26l.008.002.008.002c1.517.433 2.798 1.416 3.774 2.435a13.03 13.03 0 011.538 1.955c.679-1.255 1.66-2.204 2.653-2.895 1.218-.846 2.478-1.322 3.29-1.502l.01-.002.011-.002c1.777-.314 3.248-.15 4.447.362 1.199.512 2.089 1.357 2.729 2.346zM21.126 3.49c1.617-.283 2.883-.122 3.869.3.988.422 1.733 1.12 2.283 1.97 1.11 1.715 1.405 4.018 1.252 5.695-.474 5.216-4.236 10.54-13.494 17.9-2.924-2.437-6.132-5.133-8.7-8.084-2.66-3.06-4.578-6.333-4.81-9.804C1.289 7.884 2.673 5.766 4.374 4.6 6.11 3.41 8.208 3.189 9.4 3.483c1.28.368 2.412 1.217 3.318 2.163.905.946 1.552 1.954 1.864 2.578l.513 1.025.402-1.073c.555-1.48 1.6-2.573 2.705-3.34 1.102-.767 2.235-1.19 2.925-1.346z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});