define("ember-svg-jar/inlined/icon-checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.415 1.212a.939.939 0 011.4.002c.38.412.38 1.078-.002 1.49l-6.56 7.084a.939.939 0 01-1.392.006L1.192 5.9a1.114 1.114 0 01-.013-1.49.939.939 0 011.4-.015l2.97 3.153 5.866-6.335z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "14",
      "height": "11",
      "viewBox": "0 0 14 11",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});