define("ember-svg-jar/inlined/retirementLogoNew", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g stroke=\"#2B6CDF\" class=\"retirementLogoStroke svg-retirement-stroke\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M71.743 67.418V23.683H60.27c-.686 0-.849-.397-.367-.881L92.185 2.367a1.227 1.227 0 011.741-.01l32.584 20.447c.478.485.298.874-.39.867l-9.863-.09v43.837\" stroke-width=\"3.014\" fill=\"#17203D\" class=\"svg-brand-secondary-fill\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><g transform=\"matrix(-1 0 0 1 78.548 28.684)\" stroke-width=\"3.014\" fill=\"#17203D\" class=\"svg-brand-secondary-fill\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M38.8 12.021v21.37H21.407a5.347 5.347 0 00-5.352-5.342 5.347 5.347 0 00-5.352 5.343H0V4.007h37.02L43.242.513 69.572 0v12.021H38.8z\"/><path d=\"M62.882 33.392h9.366L61.544 16.028h-18.73v17.364h9.365a5.347 5.347 0 015.352-5.343 5.347 5.347 0 015.351 5.343z\"/><ellipse cx=\"16.055\" cy=\"33.392\" rx=\"5.352\" ry=\"5.343\"/><ellipse cx=\"57.531\" cy=\"33.392\" rx=\"5.352\" ry=\"5.343\"/><path d=\"M4.014 10.685h30.772v9.35H4.014z\"/></g><g stroke-width=\"3.014\"><path d=\"M84.569 50.036c0-1.582 1.312-2.865 2.923-2.865 1.614 0 2.923 1.285 2.923 2.865v18.167\" stroke-linecap=\"round\"/><g fill=\"#17203D\" class=\"svg-brand-secondary-fill\"><path d=\"M104.454 68.22l2.106-18.861a.832.832 0 01.57-.694 2.756 2.756 0 001.885-2.274l1.252-9.187c.211-1.83-1.151-3.5-3.041-3.71a3.53 3.53 0 00-.387-.02h-9.635 0c-1.902 0-3.447 1.506-3.449 3.358 0 .13.007.258.022.386l1.237 9.154a2.772 2.772 0 001.83 2.3.831.831 0 01.553.687L99.5 68.224M102.152 23.519c-2.328 0-4.224 1.85-4.227 4.124 0 2.276 1.896 4.13 4.228 4.132 2.331 0 4.228-1.852 4.228-4.128-.001-2.276-1.898-4.128-4.23-4.128z\"/></g></g><path d=\"M2 68.62h124.19\" stroke-width=\"3.014\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></g>",
    "attrs": {
      "width": "129",
      "height": "71",
      "viewBox": "0 0 129 71",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});