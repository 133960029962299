define("ember-svg-jar/inlined/detailsDots", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g transform=\"translate(1 1)\" fill=\"none\" fill-rule=\"evenodd\"><circle stroke=\"#4679D9\" class=\"svg-brand-primary-stroke\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" cx=\"10\" cy=\"10\" r=\"10\"/><g transform=\"translate(9 5)\" fill=\"#2B6CDE\" class=\"svg-brand-primary-fill\"><circle cx=\"1.25\" cy=\"1.25\" r=\"1.25\"/><circle cx=\"1.25\" cy=\"8.75\" r=\"1.25\"/><circle cx=\"1.25\" cy=\"5\" r=\"1.25\"/></g></g>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});