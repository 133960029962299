define("ember-svg-jar/inlined/star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.876 0l2.713 5.575 6.163.848-4.486 4.293 1.095 6.099-5.485-2.922-5.486 2.922 1.095-6.1L0 6.424l6.162-.848L8.876 0z\" fill=\"#333\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "class": "svg-cta-path-fill",
      "width": "18",
      "height": "17",
      "viewBox": "0 0 18 17",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});