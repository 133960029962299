define("ember-svg-jar/inlined/chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.5 9.75A.75.75 0 018.25 9h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM8.25 13.5a.75.75 0 000 1.5h5.25a.75.75 0 000-1.5H8.25z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.64 21.031A10.452 10.452 0 0012 22.5c5.799 0 10.5-4.701 10.5-10.5S17.799 1.5 12 1.5 1.5 6.201 1.5 12c0 1.957.535 3.79 1.468 5.358l-.974 3.412a1 1 0 001.236 1.236l3.41-.975zm.211-1.62l.557.331A8.951 8.951 0 0012 21a9 9 0 10-9-9c0 1.68.459 3.248 1.257 4.591l.33.556-.904 3.17 3.168-.906z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});