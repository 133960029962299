define("ember-svg-jar/inlined/sms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g mask=\"url(#sms_svg__mask0)\"><path d=\"M28.16 5.46H26.3v10.538a.62.62 0 01-.62.62h-7.44a3.12 3.12 0 00-1.86.62l-1.24.929v2.171l2.48-1.858a3.118 3.118 0 011.86-.62h8.68a.62.62 0 00.62-.62V6.08a.62.62 0 00-.62-.62zM18.86 1.74h2.48v4.962h-2.48z\" fill=\"#fff\"/><path d=\"M19.48 17.863a3.07 3.07 0 00-.62.07V30.26h2.48V17.862h-1.86z\" fill=\"#fff\"/><path d=\"M14.52 26.543h-2.48a.62.62 0 000 1.24h2.48a.62.62 0 000-1.24z\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M28.16 5.463h-5.58v-3.1a1.86 1.86 0 00-1.86-1.86H5.84a1.86 1.86 0 00-1.86 1.86v27.28a1.86 1.86 0 001.86 1.86h14.88a1.86 1.86 0 001.86-1.86v-10.54h5.58a1.86 1.86 0 001.86-1.86v-9.92a1.86 1.86 0 00-1.86-1.86zm-6.82 24.18a.62.62 0 01-.62.62H5.84a.62.62 0 01-.62-.62V2.362a.62.62 0 01.62-.62h14.88a.62.62 0 01.62.62v3.1h-5.58a1.86 1.86 0 00-1.86 1.86V20.34a1.241 1.241 0 001.245 1.242c.267 0 .527-.088.739-.25l2.48-1.857a1.86 1.86 0 011.116-.372h1.86v10.54zm6.82-11.78a.62.62 0 00.62-.62v-9.92a.62.62 0 00-.62-.62h-12.4a.62.62 0 00-.62.62V20.34l2.48-1.857a3.118 3.118 0 011.86-.62h8.68z\" fill=\"#0439D7\"/><path d=\"M18.24 12.902a.62.62 0 100-1.24.62.62 0 000 1.24zM21.96 12.902a.62.62 0 100-1.24.62.62 0 000 1.24zM25.68 12.902a.62.62 0 100-1.24.62.62 0 000 1.24z\" fill=\"#0439D7\"/></g>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});