define("ember-svg-jar/inlined/euro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>&#x20ac;</title><path d=\"M6.987 2.284c-1.869 0-3.035 1.18-3.498 3.542h4.483V6.96H3.366l-.017.5v.563l.017.396h4.07v1.134h-3.93c.218 1.054.623 1.87 1.218 2.447.595.578 1.39.866 2.386.866.914 0 1.82-.193 2.716-.58v1.318a6.772 6.772 0 01-2.786.572c-1.389 0-2.506-.394-3.353-1.182-.847-.788-1.405-1.935-1.674-3.441H.553V8.419H1.89l-.018-.37v-.386l.018-.703H.554V5.826h1.441c.229-1.53.77-2.722 1.626-3.577C4.477 1.394 5.6.966 6.987.966c1.178 0 2.25.284 3.217.852L9.58 3.04c-.973-.504-1.837-.756-2.593-.756z\" fill=\"#9B9B9B\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "11",
      "height": "15",
      "viewBox": "0 0 11 15",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});