define("ember-resolver/string/index", ["exports", "ember-resolver/string/cache"], function (_exports, _cache) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.classify = classify;
  _exports.dasherize = dasherize;
  _exports.decamelize = decamelize;
  _exports.getString = getString;
  _exports.getStrings = getStrings;
  _exports.setStrings = setStrings;
  _exports.underscore = underscore;
  /* eslint-disable no-useless-escape */

  let STRINGS = {};
  function setStrings(strings) {
    STRINGS = strings;
  }
  function getStrings() {
    return STRINGS;
  }
  function getString(name) {
    return STRINGS[name];
  }
  const STRING_DASHERIZE_REGEXP = /[ _]/g;
  const STRING_DASHERIZE_CACHE = new _cache.default(1000, key => decamelize(key).replace(STRING_DASHERIZE_REGEXP, '-'));
  const STRING_CLASSIFY_REGEXP_1 = /^(\-|_)+(.)?/;
  const STRING_CLASSIFY_REGEXP_2 = /(.)(\-|\_|\.|\s)+(.)?/g;
  const STRING_CLASSIFY_REGEXP_3 = /(^|\/|\.)([a-z])/g;
  const CLASSIFY_CACHE = new _cache.default(1000, str => {
    const replace1 = (_match, _separator, chr) => chr ? "_".concat(chr.toUpperCase()) : '';
    const replace2 = (_match, initialChar, _separator, chr) => initialChar + (chr ? chr.toUpperCase() : '');
    const parts = str.split('/');
    for (let i = 0; i < parts.length; i++) {
      parts[i] = parts[i].replace(STRING_CLASSIFY_REGEXP_1, replace1).replace(STRING_CLASSIFY_REGEXP_2, replace2);
    }
    return parts.join('/').replace(STRING_CLASSIFY_REGEXP_3, (match /*, separator, chr */) => match.toUpperCase());
  });
  const STRING_UNDERSCORE_REGEXP_1 = /([a-z\d])([A-Z]+)/g;
  const STRING_UNDERSCORE_REGEXP_2 = /\-|\s+/g;
  const UNDERSCORE_CACHE = new _cache.default(1000, str => str.replace(STRING_UNDERSCORE_REGEXP_1, '$1_$2').replace(STRING_UNDERSCORE_REGEXP_2, '_').toLowerCase());
  const STRING_DECAMELIZE_REGEXP = /([a-z\d])([A-Z])/g;
  const DECAMELIZE_CACHE = new _cache.default(1000, str => str.replace(STRING_DECAMELIZE_REGEXP, '$1_$2').toLowerCase());
  /**
    Converts a camelized string into all lower case separated by underscores.
  
    ```javascript
    import { decamelize } from '@ember/string';
  
    decamelize('innerHTML');          // 'inner_html'
    decamelize('action_name');        // 'action_name'
    decamelize('css-class-name');     // 'css-class-name'
    decamelize('my favorite items');  // 'my favorite items'
    ```
  
    @method decamelize
    @param {String} str The string to decamelize.
    @return {String} the decamelized string.
    @public
  */
  function decamelize(str) {
    return DECAMELIZE_CACHE.get(str);
  }
  /**
    Replaces underscores, spaces, or camelCase with dashes.
  
    ```javascript
    import { dasherize } from '@ember/string';
  
    dasherize('innerHTML');                // 'inner-html'
    dasherize('action_name');              // 'action-name'
    dasherize('css-class-name');           // 'css-class-name'
    dasherize('my favorite items');        // 'my-favorite-items'
    dasherize('privateDocs/ownerInvoice';  // 'private-docs/owner-invoice'
    ```
  
    @method dasherize
    @param {String} str The string to dasherize.
    @return {String} the dasherized string.
    @public
  */
  function dasherize(str) {
    return STRING_DASHERIZE_CACHE.get(str);
  }
  /**
    Returns the UpperCamelCase form of a string.
  
    ```javascript
    import { classify } from '@ember/string';
  
    classify('innerHTML');                   // 'InnerHTML'
    classify('action_name');                 // 'ActionName'
    classify('css-class-name');              // 'CssClassName'
    classify('my favorite items');           // 'MyFavoriteItems'
    classify('private-docs/owner-invoice');  // 'PrivateDocs/OwnerInvoice'
    ```
  
    @method classify
    @param {String} str the string to classify
    @return {String} the classified string
    @public
  */
  function classify(str) {
    return CLASSIFY_CACHE.get(str);
  }
  /**
    More general than decamelize. Returns the lower\_case\_and\_underscored
    form of a string.
  
    ```javascript
    import { underscore } from '@ember/string';
  
    underscore('innerHTML');                 // 'inner_html'
    underscore('action_name');               // 'action_name'
    underscore('css-class-name');            // 'css_class_name'
    underscore('my favorite items');         // 'my_favorite_items'
    underscore('privateDocs/ownerInvoice');  // 'private_docs/owner_invoice'
    ```
  
    @method underscore
    @param {String} str The string to underscore.
    @return {String} the underscored string.
    @public
  */
  function underscore(str) {
    return UNDERSCORE_CACHE.get(str);
  }
});