define("ember-svg-jar/inlined/icon-nav-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.25 6.75a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0zm-1.5 0a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM3 21a1.5 1.5 0 001.5 1.5h15A1.5 1.5 0 0021 21v-3.797c0-.446-.196-.866-.565-1.116C19.223 15.264 16.141 13.5 12 13.5c-4.14 0-7.223 1.764-8.435 2.587-.369.25-.565.67-.565 1.116V21zm1.5-3.735V21h15v-3.735C18.355 16.505 15.62 15 12 15s-6.355 1.506-7.5 2.265z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});