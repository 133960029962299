define("ember-svg-jar/inlined/alert-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M25.036 4.684A14.696 14.696 0 014.404 25.316 14.703 14.703 0 1025.036 4.684z\" fill=\"#BFD2F5\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 .28c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16a16 16 0 00-16-16zM16 31C7.87 31 1.28 24.41 1.28 16.28S7.87 1.56 16 1.56s14.72 6.59 14.72 14.72C30.71 24.406 24.126 30.99 16 31z\" fill=\"#2B6CDE\"/><path fill=\"#2B6CDE\" d=\"M15.36 10.52h1.28v7.68h-1.28zM15.36 20.76h1.28v1.28h-1.28z\"/>",
    "attrs": {
      "width": "32",
      "height": "33",
      "viewBox": "0 0 32 33",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});