define("ember-svg-jar/inlined/trash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g stroke=\"#BBB\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M21.5 4.375v21.273c0 .953-.722 1.726-1.614 1.726H4.84c-.891 0-1.614-.776-1.614-1.724V4.378h0\"/><path d=\"M1.5 4.25H24\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M7.763 4.395v-1.17h0c0-.952.768-1.725 1.73-1.725h5.74c.955 0 1.73.777 1.73 1.72V4.4\"/><path d=\"M12.25 8.975v14.064M16.25 8.975v14.064M8.25 8.975v14.064\" stroke-linecap=\"round\"/></g>",
    "attrs": {
      "width": "23",
      "height": "26",
      "viewBox": "0 0 23 29",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});