define("ember-svg-jar/inlined/whitelistInsurerTick", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><ellipse id=\"whitelistInsurerTick_svg__a\" cx=\"13.508\" cy=\"13.632\" rx=\"13.508\" ry=\"13.632\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><g transform=\"translate(4 4)\"><use fill=\"#7ED321\" xlink:href=\"#whitelistInsurerTick_svg__a\"/><ellipse stroke=\"#F6F6F6\" stroke-width=\"3.217\" cx=\"13.508\" cy=\"13.632\" rx=\"15.117\" ry=\"15.24\"/></g><path stroke=\"#FFF\" stroke-width=\"3.217\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M12.443 19.105l3.022 3.106 6.475-7.987\"/></g>",
    "attrs": {
      "width": "35",
      "height": "35",
      "viewBox": "0 0 35 35",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
});