define("ember-svg-jar/inlined/icon2-checkmark-o", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19.5 12a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0zm1.5 0a9 9 0 11-18 0 9 9 0 0118 0zm-5.016-3.778a.932.932 0 00-1.34.139l-3.71 4.684-1.302-1.37a.931.931 0 00-1.347-.012 1.006 1.006 0 00-.012 1.39l2.045 2.153a.933.933 0 001.417-.067l4.383-5.532c.333-.42.273-1.04-.134-1.385z\" fill=\"#18C154\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});