define("ember-svg-jar/inlined/leftArrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Shape</title><path d=\"M7.68.37l1.793 1.517L3.584 6.87l5.889 4.983L7.68 13.37 0 6.87 7.68.37z\" fill=\"currentColor\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "class": "svg-cta-path-fill",
      "width": "10",
      "height": "14",
      "viewBox": "0 0 10 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});