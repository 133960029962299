define("ember-svg-jar/inlined/delete-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 3h6v1.5H9V3zM7.5 4.5V3A1.5 1.5 0 019 1.5h6A1.5 1.5 0 0116.5 3v1.5h3.75a.75.75 0 010 1.5h-.836c0 .028 0 .055-.002.083l-.833 15A1.5 1.5 0 0117.08 22.5H6.919a1.5 1.5 0 01-1.498-1.417l-.833-15A1.529 1.529 0 014.586 6H3.75a.75.75 0 010-1.5H7.5zm9 1.5H6.086l.833 15h10.162l.833-15H16.5zM10 9.25a.75.75 0 01.75.75v7a.75.75 0 01-1.5 0v-7a.75.75 0 01.75-.75zm4 0a.75.75 0 01.75.75v7a.75.75 0 01-1.5 0v-7a.75.75 0 01.75-.75z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});