define("ember-svg-jar/inlined/finance-ring", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20.52 20.52l5.04 5.04c2.16-2.16 3.72-5.16 4.2-8.4l-7.08-1.08c-.24 1.8-.96 3.24-2.16 4.44zm7.92-2.28a13.31 13.31 0 01-2.88 5.64l-3.36-3.36c.72-.84 1.2-1.92 1.44-2.88l4.8.6zM17.4.24l-1.2 7.08c3.72.6 6.6 3.84 6.6 7.68H30C30 7.56 24.48 1.32 17.4.24zm6.48 13.56c-.48-3.48-2.88-6.36-6.36-7.44l.72-4.8C24 3 28.08 7.8 28.56 13.68l-4.68.12zm-8.88 9c-4.32 0-7.8-3.48-7.8-7.8s3.48-7.8 7.8-7.8V0C6.72 0 0 6.72 0 15c0 8.28 6.72 15 15 15 3.24 0 6.24-.96 8.64-2.76l-4.2-5.88c-1.2.96-2.76 1.44-4.44 1.44zm0 6C7.44 28.8 1.2 22.56 1.2 15c0-7.2 5.52-13.08 12.6-13.8V6C9.36 6.6 6 10.32 6 14.88c0 4.92 4.08 9 9 9 1.44 0 2.88-.36 4.2-.96l2.76 3.96C19.8 28.2 17.4 28.8 15 28.8z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "30",
      "height": "30",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});