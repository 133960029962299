define("ember-svg-jar/inlined/cross-outlined", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"currentColor\" fill-rule=\"evenodd\"><path d=\"M9 16.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15zM9 18A9 9 0 109 0a9 9 0 000 18z\"/><path d=\"M12.766 5.234a.8.8 0 00-1.132 0L9 7.87 6.366 5.234a.8.8 0 00-1.132 1.132L7.87 9l-2.635 2.634a.8.8 0 001.132 1.132L9 10.13l2.634 2.635a.8.8 0 001.132-1.132L10.13 9l2.635-2.634a.8.8 0 000-1.132z\"/></g>",
    "attrs": {
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});