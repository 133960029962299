define("ember-svg-jar/inlined/offerConfirmationIcon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><g stroke-width=\"4.352\" stroke=\"#2B6CDE\" class=\"svg-brand-primary-stroke\"><path d=\"M101.597 69.05v27.608c0 3.155-2.012 5.712-4.425 5.712H53.046c-2.445 0-4.426-2.59-4.426-5.698V39.861c0-3.147 1.978-5.698 4.421-5.698h31.077\"/><path d=\"M71.325 55.261l9.46 9.006 20.272-23.157M59.972 67.95h7.568M59.972 79.319h28.381M59.972 90.687h22.705\"/></g><g stroke=\"#D6D6D6\" stroke-width=\"2.888\"><path d=\"M106.683 17.696l-6.001 3.229 1.145-6.838-4.854-4.844 6.71-.998 3-6.221 3 6.22 6.71 1-4.854 4.843 1.146 6.838zM55.225 131.014l-4.413 5.255-1.942-6.639-5.136-4.515 6.488-1.988 4.413-5.255 1.942 6.639 5.135 4.515zM29.009 31.53c3.383 0 6.126-2.806 6.126-6.27 0-3.462-2.743-6.268-6.126-6.268-3.384 0-6.126 2.806-6.126 6.269s2.742 6.269 6.126 6.269h0zM75.26 118.04h0L79.27 145l13.273-5.224-10.526-22.081M7.105 57.61L2 77.46h11.231zM33.737 84.754h0l-13.821 7.92-6.126 20.897 19.975-22.419M31.128 54.224L16.834 44.82l8.168 12.539zM116.951 91.42l17.357 1.045-17.357 8.359zM113.725 70.69l31.43-20.102 2.041 15.673-33.068 12.32M114.312 47.577l14.71-15.055 5.518 8.468-15.63 10.35zM62.785 20.026h0L59.793 4.28l13.274-1.044-2.588 16.329M110.998 126.11c3.384 0 6.126-2.807 6.126-6.27 0-3.462-2.742-6.269-6.126-6.269-3.383 0-6.126 2.807-6.126 6.27 0 3.462 2.743 6.268 6.126 6.268h0z\"/></g></g>",
    "attrs": {
      "width": "149",
      "height": "147",
      "viewBox": "0 0 149 147"
    }
  };
});