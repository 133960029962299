define("ember-svg-jar/inlined/personal-liability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M7.821 2.493C8.186 1.884 8.878 1.5 10 1.5h11.5c.365 0 .869.14 1.284.54.427.41.716 1.05.716 1.96v.029l-1.507 13.06-.008.032A2.499 2.499 0 0119.5 19H16v10.5h5.5a.5.5 0 010 1h-12a.5.5 0 010-1H15V19h-3.042c-.47 0-1.06 0-1.592-.228-.594-.254-1.054-.756-1.34-1.614l-.017-.05L7.5 4.029v-.056c0-.323 0-.942.321-1.48zM8.5 3.97l1.49 12.92c.213.607.495.846.77.964.326.14.717.147 1.24.147h7.5c.902 0 1.372-.6 1.507-1.092L22.5 3.972c-.006-.671-.214-1.024-.409-1.211A.907.907 0 0021.5 2.5h-5.75L16 3l-3 3 2 3.5-2 3 .5-3L11 6l3-3-.5-.5H10c-.878 0-1.186.282-1.321.507-.164.273-.178.622-.179.964z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});