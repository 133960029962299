define("ember-svg-jar/inlined/rente-screen-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#01051C\" d=\"M60.334 18.452L45.104 3.215a4.2 4.2 0 00-2.969-1.23h-29.55a4.197 4.197 0 00-4.198 4.198v4.198H4.189a4.202 4.202 0 00-4.198 4.198v53.177a4.202 4.202 0 004.198 4.198H48.97a4.202 4.202 0 004.198-4.198v-4.198h4.198a4.197 4.197 0 004.198-4.198V21.423a4.202 4.202 0 00-1.23-2.971zm-3.632.325H46.159a1.4 1.4 0 01-1.399-1.394l-.052-10.607 11.994 12.001zm-6.333 48.979a1.4 1.4 0 01-1.399 1.399H4.189a1.4 1.4 0 01-1.399-1.399V14.579a1.4 1.4 0 011.399-1.399h4.198v46.18a4.198 4.198 0 004.198 4.198h37.784v4.198zm8.396-8.396a1.4 1.4 0 01-1.399 1.399H12.585a1.4 1.4 0 01-1.399-1.399V6.183a1.4 1.4 0 011.399-1.399h29.316l.06 12.611a4.206 4.206 0 004.198 4.182h12.605V59.36zM45.181 30.383a1.398 1.398 0 111.979 1.979L33.166 46.355a1.396 1.396 0 01-1.885.086l-8.396-6.997a1.401 1.401 0 011.793-2.151l7.414 6.18 13.089-13.09z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "81.515",
      "height": "101.894",
      "viewBox": "-11.236 -14.045 81.515 101.894"
    }
  };
});