define("ember-svg-jar/inlined/bedarf-screen-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#01051C\" d=\"M7.222 28.636H4.333C1.936 28.636 0 30.555 0 32.932v25.773C0 61.081 1.936 63 4.333 63h2.889c2.398 0 4.333-1.919 4.333-4.296V32.932c.001-2.377-1.935-4.296-4.333-4.296zm1.445 30.068c0 .802-.636 1.432-1.444 1.432h-2.89c-.809 0-1.444-.63-1.444-1.432V32.932c0-.802.636-1.432 1.444-1.432h2.889c.809 0 1.444.63 1.444 1.432v25.772zm18.777-44.386h-2.889c-2.398 0-4.333 1.919-4.333 4.295v40.091c0 2.377 1.936 4.296 4.333 4.296h2.889c2.398 0 4.333-1.919 4.333-4.296v-40.09c.001-2.377-1.935-4.296-4.333-4.296zm1.445 44.386c0 .802-.636 1.432-1.444 1.432h-2.889c-.809 0-1.444-.63-1.444-1.432v-40.09c0-.802.636-1.432 1.444-1.432h2.889c.809 0 1.444.63 1.444 1.432v40.09zM47.667 0h-2.889c-2.398 0-4.333 1.919-4.333 4.295v54.409c0 2.377 1.936 4.296 4.333 4.296h2.889C50.064 63 52 61.081 52 58.704V4.295C52 1.919 50.064 0 47.667 0zm1.444 58.704c0 .802-.635 1.432-1.444 1.432h-2.889c-.809 0-1.444-.63-1.444-1.432V4.295c0-.802.636-1.432 1.444-1.432h2.889c.809 0 1.444.63 1.444 1.432v54.409z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "72.579",
      "height": "87.933",
      "viewBox": "-9.079 -11 72.579 87.933"
    }
  };
});