define("ember-can/services/abilities", ["exports", "@ember/service", "ember-can/ability", "@ember/debug", "@ember/application", "ember-can/utils/normalize"], function (_exports, _service, _ability, _debug, _application, _normalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AbilitiesService extends _service.default {
    /**
     * Parse abilityString into an object with extracted propertyName and abilityName
     * eg. for 'create projects in account' -> `{ propertyName: 'createProjects', abilityName: 'account'}`
     * @public
     * @param  {String} string eg. 'create projects in account'
     * @return {Object}        extracted propertyName and abilityName
     */
    parse(abilityString) {
      return (0, _normalize.default)(abilityString);
    }

    /**
     * Create an instance of Ability
     * @public
     * @param  {String} abilityName     name of ability class
     * @param  {*}      model
     * @param  {Object} [properties={}] extra properties (to be set on the ability instance)
     * @return {Ability}                Ability instance of requested ability
     */
    abilityFor(abilityName, model) {
      let properties = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      let AbilityFactory = (0, _application.getOwner)(this).factoryFor("ability:".concat(abilityName));
      (false && !(AbilityFactory) && (0, _debug.assert)("No ability type found for '".concat(abilityName, "'"), AbilityFactory));
      if (typeof model !== 'undefined') {
        properties = {
          model,
          ...properties
        };
      }
      let ability = AbilityFactory.create(properties);
      (false && !(ability instanceof _ability.default) && (0, _debug.assert)("Ability '".concat(abilityName, "' has to inherit from ember-can Ability"), ability instanceof _ability.default));
      return ability;
    }

    /**
     * Returns a value for requested ability in specified ability class
     * @public
     * @param  {String} propertyName name of ability, eg `createProjects`
     * @param  {String} abilityName  name of ability class
     * @param  {*}      model
     * @param  {Object} properties   extra properties (to be set on the ability instance)
     * @return {*}                   value of ability
     */
    valueFor(propertyName, abilityName, model, properties) {
      let ability = this.abilityFor(abilityName, model, properties);
      let result = ability.getAbility(propertyName, model, properties);
      ability.destroy();
      return result;
    }

    /**
     * Returns `true` if ability is permitted
     * @public
     * @param  {[type]} abilityString eg. 'create projects in account'
     * @param  {*}      model
     * @param  {[type]} properties    extra properties (to be set on the ability instance)
     * @return {Boolean}              value of ability converted to boolean
     */
    can(abilityString, model, properties) {
      let {
        propertyName,
        abilityName
      } = this.parse(abilityString);
      return !!this.valueFor(propertyName, abilityName, model, properties);
    }

    /**
     * Returns `true` if ability is not permitted
     * @public
     * @param  {[type]} abilityString eg. 'create projects in account'
     * @param  {*}      model
     * @param  {[type]} properties    extra properties (to be set on the ability instance)
     * @return {Boolean}              value of ability converted to boolean
     */
    cannot(abilityString, model, properties) {
      return !this.can(abilityString, model, properties);
    }
  }
  _exports.default = AbilitiesService;
});