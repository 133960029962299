define("ember-svg-jar/inlined/pdfDocument", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><path d=\"M0 2.85A2.847 2.847 0 012.859 0h12.63l6.06 6.19v19.603a2.851 2.851 0 01-2.85 2.843H2.851A2.845 2.845 0 010 25.786V2.85z\" id=\"pdfDocument_svg__a\"/><path id=\"pdfDocument_svg__b\" d=\"M15.494 0h-.673v7.5h6.734v-.75z\"/><mask id=\"pdfDocument_svg__c\" maskContentUnits=\"userSpaceOnUse\" maskUnits=\"objectBoundingBox\" x=\"0\" y=\"0\" width=\"21.55\" height=\"28.636\" fill=\"#fff\"><use xlink:href=\"#pdfDocument_svg__a\"/></mask><mask id=\"pdfDocument_svg__d\" maskContentUnits=\"userSpaceOnUse\" maskUnits=\"objectBoundingBox\" x=\"0\" y=\"0\" width=\"6.734\" height=\"7.5\" fill=\"#fff\"><use xlink:href=\"#pdfDocument_svg__b\"/></mask></defs><g fill=\"none\" fill-rule=\"evenodd\"><g transform=\"translate(.168 .34)\" stroke=\"#626262\"><use mask=\"url(#pdfDocument_svg__c)\" stroke-width=\"3\" fill=\"#FFF\" xlink:href=\"#pdfDocument_svg__a\"/><use mask=\"url(#pdfDocument_svg__d)\" stroke-width=\"2\" xlink:href=\"#pdfDocument_svg__b\"/></g><path d=\"M11.614 14.854a1.74 1.74 0 001.731-1.75 1.74 1.74 0 00-1.731-1.749 1.74 1.74 0 00-1.732 1.75c0 .966.776 1.749 1.732 1.749zm1.118 5.629a.578.578 0 00-.681-.364l-.74.187.845-3.42a1.167 1.167 0 00-.84-1.415 1.086 1.086 0 00-.245-.033c-.012 0-.024-.003-.034-.003a.566.566 0 00-.259.062L8.47 16.664a.585.585 0 00.257 1.104.57.57 0 00.14-.017l.742-.187-.847 3.42a1.167 1.167 0 001.12 1.45c.088 0 .176-.02.258-.061l2.31-1.167a.584.584 0 00.283-.723z\" fill=\"#626262\"/></g>",
    "attrs": {
      "width": "22",
      "height": "29",
      "viewBox": "0 0 22 29",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
});