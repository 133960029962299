define("ember-svg-jar/inlined/sparkle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.554 20.98c.326.23.715.353 1.114.352v-.006a1.921 1.921 0 001.77-1.162l.817-2.486a3.827 3.827 0 012.423-2.416l2.37-.77a1.924 1.924 0 00-.069-3.654l-2.34-.76a3.837 3.837 0 01-2.43-2.43l-.77-2.37a1.928 1.928 0 00-3.643.03l-.777 2.39a3.837 3.837 0 01-2.36 2.38l-2.37.762a1.925 1.925 0 00-.933 2.933c.236.332.57.581.956.712l2.339.759a3.848 3.848 0 012.43 2.434l.77 2.367c.131.377.377.704.703.934zm-.499-5.667a5.41 5.41 0 00-.484-.55h.004a5.857 5.857 0 00-2.293-1.418l-2.134-.677 2.148-.699a5.867 5.867 0 002.254-1.42 5.93 5.93 0 001.388-2.277l.686-2.11.688 2.117a5.832 5.832 0 003.708 3.697l2.16.696-2.12.686a5.84 5.84 0 00-3.698 3.7l-.687 2.11-.687-2.113a5.826 5.826 0 00-.933-1.742zM21.776 27.71a1.498 1.498 0 01-.547-.732l-.437-1.343a1.722 1.722 0 00-1.095-1.097l-1.321-.43a1.53 1.53 0 01-1.041-1.444 1.523 1.523 0 011.028-1.44l1.341-.435a1.734 1.734 0 001.067-1.092l.432-1.323a1.524 1.524 0 012.876-.028l.438 1.352a1.731 1.731 0 001.094 1.088l1.322.43a1.52 1.52 0 01.052 2.887l-1.352.44a1.732 1.732 0 00-1.09 1.096l-.43 1.318a1.525 1.525 0 01-.566.76 1.52 1.52 0 01-1.77-.007zm-1.374-5.044a3.724 3.724 0 012.266 2.279 3.725 3.725 0 012.267-2.267 3.75 3.75 0 01-2.291-2.277 3.743 3.743 0 01-2.242 2.265z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});