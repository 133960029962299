define("ember-svg-jar/inlined/starHalf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#D6D6D6\" class=\"star-bg\" d=\"M10.282 15.675l-5.933 3.23 1.133-6.84-4.8-4.844 6.633-.998L10.282 0l2.966 6.223 6.633.998-4.8 4.844 1.134 6.84z\"/><path fill=\"#F6A823\" d=\"M10.282 15.675l-5.933 3.23 1.133-6.84-4.8-4.844 6.633-.998L10.282 0\"/></g>",
    "attrs": {
      "width": "20",
      "height": "19",
      "viewBox": "0 0 20 19",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});