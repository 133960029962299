define("ember-svg-jar/inlined/rechtsschutz", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Page 1</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M23.813 25.906l-1.05 1.578c-.42.627-1.26.797-1.89.38l-12.64-8.422a1.366 1.366 0 01-.387-1.893l1.05-1.578a1.374 1.374 0 011.901-.381L23.44 24.01c.63.418.792 1.268.374 1.895zM33.303 11.66l-1.056 1.584a1.357 1.357 0 01-1.888.378l-12.642-8.42a1.364 1.364 0 01-.385-1.893l1.054-1.585a1.365 1.365 0 011.895-.374l12.642 8.42c.63.419.797 1.262.38 1.89z\" stroke=\"#2B6CDE\" stroke-width=\"1.4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path fill=\"#BFD2F5\" d=\"M12.04 16.419l6.919-10.39 10.155 6.765-6.918 10.388z\"/><path stroke=\"#2B6CDE\" stroke-width=\"1.4\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M12.04 16.419l6.919-10.39 10.155 6.765-6.918 10.388z\"/><path d=\"M44.37 30.853a3.115 3.115 0 01-4.49.746L24.49 19.74l1.963-2.951 16.876 9.632a3.115 3.115 0 011.041 4.43z\" stroke=\"#2B6CDE\" stroke-width=\"1.4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M18.945 34.798c-1.175 0-2.239 1.817-3.192 5.452h7.286v-2.26a3.193 3.193 0 00-3.192-3.192h-.902z\" fill=\"#BFD2F5\"/><path d=\"M19.847 34.798H4.311a3.193 3.193 0 00-3.192 3.192v2.26h21.92v-2.26a3.193 3.193 0 00-3.192-3.192z\" stroke=\"#2B6CDE\" stroke-width=\"1.4\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></g>",
    "attrs": {
      "width": "46",
      "height": "41",
      "viewBox": "0 0 46 41",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});