define("ember-css-modules-active-route/utils/events", ["exports", "@ember/object/events"], function (_exports, _events) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.removeListener = _exports.addListener = void 0;
  // Unfortunately the upstream types are incorrect. `ObserverMethod` makes no
  // sense here.

  const addListener = (object, key, method) => (0, _events.addListener)(object, key, method);
  _exports.addListener = addListener;
  const removeListener = (object, key, method) => (0, _events.removeListener)(object, key, method);
  _exports.removeListener = removeListener;
});