define("ember-svg-jar/inlined/marked-shield-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.233 8.084c6.945 0 11.906-4.886 11.906-4.886S14 8 14 8.5l-7.5 3c-.2 3-.5 5.003-.5 7.5 0 7.817 7.5 11.5 7.5 11.5S3.233 28.603 3.233 20.786V8.084z\" fill=\"#fff\"/><path d=\"M14.848 30.028c-.412.177-1.06.435-1.941.774C4.969 27.87 1 23.799 1 18.587V5.885c4.63 0 8.6-1.628 11.907-4.885 3.308 3.257 7.276 4.886 11.907 4.886\" stroke=\"currentColor\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M13.651 9.061s-3.969 1.954-7.442 1.954v6.84c0 2.104 1.03 4.001 3.09 5.693\" stroke=\"currentColor\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M24.07 28.114c4.932 0 8.93-3.937 8.93-8.793 0-4.857-3.998-8.794-8.93-8.794-4.932 0-8.93 3.937-8.93 8.794 0 4.856 3.998 8.793 8.93 8.793z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M31.5 19a7.5 7.5 0 11-15 0 7.5 7.5 0 0115 0zm1.5 0a9 9 0 11-18 0 9 9 0 0118 0zm-5.016-3.778a.93.93 0 00-1.34.139l-3.71 4.684-1.302-1.37a.93.93 0 00-1.347-.012 1.006 1.006 0 00-.012 1.39l2.045 2.153a.934.934 0 001.417-.067l4.383-5.532c.333-.42.273-1.04-.134-1.385z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "36",
      "height": "32",
      "viewBox": "0 0 36 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});