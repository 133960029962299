define("ember-svg-jar/inlined/medical-liquid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.94 3.532h-4.5v-.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5v.5h-4.5c-.8 0-1.5.7-1.5 1.5v10.1c0 .9.4 1.8 1.1 2.3l2.7 2.2c.6.5 1.4.8 2.2.9v2.5c0 .3.2.5.5.5s.5-.2.5-.5v-2.5c.8-.1 1.5-.4 2.2-.9l2.7-2.2c.7-.6 1.1-1.4 1.1-2.3v-10.1c0-.8-.7-1.5-1.5-1.5zm.5 11.6c0 .6-.3 1.2-.8 1.6l-2.7 2.2c-.6.5-1.3.7-2 .7s-1.5-.3-2-.7l-2.7-2.2c-.5-.4-.8-1-.8-1.6v-10.1c0-.3.2-.5.5-.5h10c.3 0 .5.2.5.5v10.1zm-1-7.1c0 .3-.2.5-.5.5h-2c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h2c.3 0 .5.2.5.5zm0 3c0 .3-.2.5-.5.5h-2c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h2c.3 0 .5.2.5.5zm0 3c0 .3-.2.5-.5.5h-2c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h2c.3 0 .5.2.5.5zm5-11v21c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-21c0-.8-.7-1.5-1.5-1.5h-8c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h8c1.4 0 2.5 1.1 2.5 2.5z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 17 25",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});