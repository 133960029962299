define("ember-svg-jar/inlined/shield-task", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#222326\" d=\"M16.757 9.303a.75.75 0 00-1.014-1.106l-5.47 5.015L8.28 11.22a.75.75 0 00-1.06 1.06l2.5 2.5a.75.75 0 001.037.023l6-5.5zM20.25 5c-2.663 0-5.258-.943-7.8-2.85a.75.75 0 00-.9 0C9.008 4.057 6.413 5 3.75 5a.75.75 0 00-.75.75V11c0 5.001 2.958 8.676 8.725 10.948a.75.75 0 00.55 0C18.042 19.676 21 16 21 11V5.75a.75.75 0 00-.75-.75zM4.5 6.478c2.577-.152 5.08-1.09 7.5-2.8 2.42 1.71 4.923 2.648 7.5 2.8V11c0 4.256-2.453 7.379-7.5 9.442C6.953 18.379 4.5 15.256 4.5 11V6.478z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});