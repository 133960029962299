define("ember-svg-jar/inlined/icon-nav-support", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.977 3c.073 2.706.767 8.372 4.834 12.44C11.914 19.542 18.05 20.633 21 20.91v-4.233l-2.551-1.275-.748.996c-.862 1.15-2.482 1.572-3.81.784-1.123-.667-2.929-1.848-4.452-3.371a15.37 15.37 0 01-2.92-4.05c-.57-1.164-.215-2.436.576-3.227l.939-.94L7.169 3H2.977zM2.88 1.5H7.17a1.5 1.5 0 011.423 1.026l.865 2.595a1.5 1.5 0 01-.363 1.535l-.939.938c-.401.402-.54.996-.29 1.507A13.87 13.87 0 0010.5 12.75c1.395 1.395 3.079 2.502 4.157 3.142.623.37 1.41.186 1.844-.393l.748-.998a1.5 1.5 0 011.87-.441l2.552 1.275a1.5 1.5 0 01.829 1.342V21c0 .828-.668 1.49-1.493 1.418-2.963-.262-9.694-1.355-14.257-5.918C2.182 11.932 1.527 5.626 1.474 2.875 1.459 2.095 2.1 1.5 2.88 1.5z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});