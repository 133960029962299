define("ember-svg-jar/inlined/ringBadgeArrowIcon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.182 1.9l2.476 2.51L6.98 3.12a.845.845 0 011.175-.019l3.533 3.227c.373.34.417.94.098 1.34a.849.849 0 01-1.252.104L7.598 5.09 6.243 6.408a.846.846 0 01-1.209-.013L1.776 3.092v1.743c0 .524-.397.95-.888.95-.49 0-.888-.426-.888-.95V.95C0 .425.398 0 .888 0h3.945c.49 0 .888.425.888.95 0 .524-.397.95-.888.95H3.182z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "12",
      "height": "8",
      "viewBox": "0 0 12 8"
    }
  };
});