define("ember-svg-jar/inlined/consent-locker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M35.18 22.4h-3.36V12.32C31.82 5.516 26.304 0 19.5 0S7.18 5.516 7.18 12.32V22.4H3.82a3.36 3.36 0 00-3.36 3.36v26.88A3.36 3.36 0 003.82 56h31.36a3.36 3.36 0 003.36-3.36V25.76a3.36 3.36 0 00-3.36-3.36zM9.42 12.32c0-5.567 4.513-10.08 10.08-10.08s10.08 4.513 10.08 10.08V22.4H9.42V12.32zm25.76 41.44c.618 0 1.12-.502 1.12-1.12V25.76c0-.618-.502-1.12-1.12-1.12H3.82c-.618 0-1.12.502-1.12 1.12v26.88c0 .618.502 1.12 1.12 1.12h31.36z\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.962 31.565a5.969 5.969 0 011.538-.205 5.578 5.578 0 013.647 9.819l1.895 4.387a1.12 1.12 0 01-1.062 1.474h-8.96a1.12 1.12 0 01-1.06-1.475l1.895-4.387a5.573 5.573 0 01-1.7-5.917 5.508 5.508 0 013.807-3.696zm.491 8.9L16.575 44.8h5.85l-1.878-4.335 1.133-.98a3.306 3.306 0 001.18-2.525 3.364 3.364 0 00-3.36-3.36c-.325 0-.648.044-.961.128A3.255 3.255 0 0016.3 35.9a3.352 3.352 0 001.02 3.584l1.133.98z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "39",
      "height": "56",
      "viewBox": "0 0 39 56",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});