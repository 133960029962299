define("ember-svg-jar/inlined/clark-contracts-assets--checkbox-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.75 3A3.25 3.25 0 002.5 6.25v11.5A3.25 3.25 0 005.75 21h6.922a3.738 3.738 0 01-.172-1.125v-.103c0-.092.005-.182.013-.272H5.75A1.75 1.75 0 014 17.75V6.25c0-.966.784-1.75 1.75-1.75h11.5c.966 0 1.75.784 1.75 1.75v4.895a3.5 3.5 0 011.5.905v-5.8A3.25 3.25 0 0017.25 3H5.75zm11.03 6.28a.75.75 0 10-1.06-1.06l-6.223 6.216L7.28 12.22a.75.75 0 00-1.06 1.06l2.745 2.746a.75.75 0 001.06 0l6.754-6.745zm3.72 5.22a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm2 5.375C22.5 21.431 21.214 23 18 23s-4.5-1.563-4.5-3.125v-.103c0-.98.794-1.772 1.773-1.772h5.454c.98 0 1.773.793 1.773 1.772v.103z\" fill=\"#222326\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});