define("ember-svg-jar/inlined/horizon-checkmark-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M8 2a6 6 0 110 12A6 6 0 018 2zm2.12 4.164L7.25 9.042 5.854 7.646a.5.5 0 10-.708.708l1.75 1.75a.5.5 0 00.708 0l3.224-3.234a.5.5 0 00-.708-.706z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});