define("ember-svg-jar/inlined/pencil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><path id=\"pencil_svg__a\" d=\"M.567 0H4.5v3.932H.567z\"/><path id=\"pencil_svg__c\" d=\"M0 12h12V0H0z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFF\" d=\"M1.815 7.534l2.653 2.65 5.282-5.28L7.099 2.25z\"/><g transform=\"translate(7.5)\"><mask id=\"pencil_svg__b\" fill=\"#fff\"><use xlink:href=\"#pencil_svg__a\"/></mask><path d=\"M4.28 1.81L2.69.22a.75.75 0 00-1.061 0L.567 1.28 3.22 3.932l1.06-1.06a.75.75 0 000-1.061\" fill=\"#FFF\" mask=\"url(#pencil_svg__b)\"/></g><mask id=\"pencil_svg__d\" fill=\"#fff\"><use xlink:href=\"#pencil_svg__c\"/></mask><path fill=\"#FFF\" mask=\"url(#pencil_svg__d)\" d=\"M0 12l3.447-.796L.794 8.553z\"/></g>",
    "attrs": {
      "width": "12",
      "height": "12",
      "viewBox": "0 0 12 12",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
});