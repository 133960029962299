define("ember-svg-jar/inlined/gavLogoSmall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill-rule=\"nonzero\" fill=\"none\"><path d=\"M8 19.06V8.345C8 4.748 9.597 3 14.25 3h18.064c4.004 0 5.262 1.422 5.262 4.885v4.436c0 3.932-3.374 5.037-6.742 5.037H13.757C10.002 17.358 8 19.06 8 19.06\" fill=\"#717582\"/><path d=\"M37.576 17.868v10.73c0 3.599-1.598 5.346-6.25 5.346H13.264C9.257 33.944 8 32.522 8 29.059v-4.453c0-3.933 3.374-5.038 6.741-5.038H31.82c3.753 0 5.756-1.7 5.756-1.7\" fill=\"#CDCDCD\"/></g>",
    "attrs": {
      "width": "46",
      "height": "47",
      "viewBox": "0 0 46 47",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});