define("ember-svg-jar/inlined/group-people", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 4.09a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-3 1.5a3 3 0 116 0 3 3 0 01-6 0zm-.732 4c-.977 0-1.768.791-1.768 1.768v.36c0 .207 0 1.408.7 2.575.548.91 1.477 1.727 2.975 2.093a2.776 2.776 0 011.062-1.335c-1.603-.172-2.361-.883-2.75-1.531C4.003 12.714 4 11.857 4 11.715v-.357c0-.148.12-.268.268-.268H8.53c.067-.541.243-1.049.504-1.5H4.268zm11.697 0c.261.451.437.959.504 1.5h4.263c.148 0 .268.12.268.268v.357c0 .142-.003 1-.487 1.805-.389.648-1.147 1.36-2.75 1.531.48.32.856.786 1.062 1.335 1.498-.366 2.427-1.183 2.974-2.093.701-1.167.701-2.368.701-2.576v-.36c0-.976-.791-1.767-1.768-1.767h-4.767zm-.465-4a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm1.5-3a3 3 0 100 6 3 3 0 000-6zm-4.5 7.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-3 1.5a3 3 0 116 0 3 3 0 01-6 0zM7 17.358c0-.977.791-1.768 1.768-1.768h7.464c.977 0 1.768.791 1.768 1.768v.36c0 .207 0 1.408-.7 2.575-.742 1.233-2.186 2.297-4.8 2.297s-4.058-1.064-4.8-2.297C7 19.125 7 17.925 7 17.716v-.36zm1.768-.268a.268.268 0 00-.268.268v.357c0 .142.003 1 .487 1.805.446.742 1.377 1.57 3.513 1.57s3.067-.828 3.513-1.57c.484-.806.487-1.663.487-1.805v-.357a.268.268 0 00-.268-.268H8.768z\" fill=\"#01051C\"/>",
    "attrs": {
      "width": "25",
      "height": "25",
      "viewBox": "0 0 25 25",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});