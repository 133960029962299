define("ember-svg-jar/inlined/change-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\".5\" y=\".5\" width=\"39\" height=\"39\" rx=\"19.5\" fill=\"#F5F6FA\"/><path d=\"M23.207 10.29l4 3.996a1 1 0 01.084 1.32l-.083.095-4 4.005a1 1 0 01-1.498-1.32l.083-.094L24.083 16H13.5a1 1 0 01-.993-.883l-.007-.117a1 1 0 01.883-.993l.117-.007h10.59l-2.296-2.293a1 1 0 01-.084-1.32l.083-.095a1 1 0 011.32-.084l.094.084zm4.284 14.592l.006.117a1 1 0 01-.883.993l-.117.007H15.914l2.293 2.293a1 1 0 01.084 1.32l-.083.094a1 1 0 01-1.32.084l-.094-.084-4-3.996a1 1 0 01-.084-1.32l.083-.094 4-4.004a1 1 0 011.498 1.32l-.083.094-2.29 2.293h10.58a1 1 0 01.993.883z\" fill=\"#203151\"/><rect x=\".5\" y=\".5\" width=\"39\" height=\"39\" rx=\"19.5\" stroke=\"#CFD3DA\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});