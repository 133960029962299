define("ember-svg-jar/inlined/checkbg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g stroke=\"#2B6CDE\" class=\"svg-cta-stroke\" stroke-width=\"1.8\" fill=\"none\" fill-rule=\"evenodd\"><path class=\"svg-cta-stroke\" d=\"M10.172 1H2.256C1.562 1 1 1.57 1 2.262v15.216c0 .697.567 1.262 1.254 1.262h14.54a1.25 1.25 0 001.253-1.255V9.848\"/></g>",
    "attrs": {
      "width": "19",
      "height": "20",
      "viewBox": "0 0 19 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});