define("ember-svg-jar/inlined/uploadIcon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.166 6.265L10.589 2.69a.683.683 0 00-.633-.188.686.686 0 00-.41.2L5.967 6.276a.69.69 0 00.007.983l.028.028c.278.278.71.28.984.007L9.2 5.08v7.849c0 .412.364.737.818.737h.032c.457 0 .817-.327.817-.737V5.004l2.28 2.28a.689.689 0 00.983-.008l.028-.028a.69.69 0 00.007-.983zM16.7 18.32a.833.833 0 100-1.667H3.368a.833.833 0 100 1.667h13.333z\" fill=\"inherit\"/>",
    "attrs": {
      "viewBox": "0 0 21 20",
      "fill": "#2B6CDE",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});