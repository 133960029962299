define("ember-svg-jar/inlined/barLargeEmpty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect width=\"16\" height=\"6\" rx=\"1\" fill=\"#DADDEC\"/>",
    "attrs": {
      "width": "18",
      "height": "6",
      "viewBox": "0 0 18 6",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});