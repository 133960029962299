define("ember-svg-jar/inlined/deal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M28.14 2.925h-3.965a.59.59 0 01-.344-.11L20.424.395a.594.594 0 00-.344-.11h-3.72a.605.605 0 00-.459.215l-2.07 2.424H8.054a1.77 1.77 0 00-1.031.333L3.928 5.454a.59.59 0 01-.343.111H.86c-.342 0-.62.296-.62.66v9.24c0 .365.278.66.62.66h5.12a.582.582 0 01.395.152c.174.159.411.392.697.674a23.95 23.95 0 004.851 3.956c.608.328 1.28.499 1.96.498 1.224 0 2.392-.544 3.222-1.5l4.094-5.126c.04.013.08.022.121.026h6.82c.343 0 .62-.295.62-.66V3.585a.682.682 0 00-.181-.467.602.602 0 00-.439-.193zM16.177 19.03c-.948 1.083-2.454 1.368-3.694.7a22.968 22.968 0 01-4.568-3.748l-.043-.041a24.19 24.19 0 00-.696-.67 1.785 1.785 0 00-1.196-.465h-4.5v-7.92h2.105c.367 0 .726-.116 1.031-.333l3.095-2.196a.59.59 0 01.344-.11h4.649l-1.851 2.167c-.884 1.033-.83 2.627.119 3.591.95.964 2.448.944 3.376-.044l3.2-3.407 3.529 4.695c.39.518.357 1.262-.075 1.74l-4.825 6.041zm6.027-5.544h5.316v-9.24h-3.345c-.367 0-.726-.115-1.031-.332l-3.252-2.308h-3.258L11.77 7.301a1.309 1.309 0 00.056 1.75c.463.47 1.194.459 1.645-.024l3.69-3.928a.645.645 0 01.352-.176.56.56 0 01.123-.01.634.634 0 01.448.24l3.961 5.27c.666.879.73 2.111.16 3.062z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "29",
      "height": "22",
      "viewBox": "0 0 29 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});