define("ember-svg-jar/inlined/ark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><path id=\"ark_svg__a\" d=\"M24.295.096v26.796H.023V.096z\"/><path id=\"ark_svg__c\" d=\"M.058.067h4.25V4.16H.059V.067z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><g transform=\"translate(1 1.017)\"><mask id=\"ark_svg__b\" fill=\"#fff\"><use xlink:href=\"#ark_svg__a\"/></mask><path d=\"M23.969 23.71l-9.75-22.2C13.811.569 13.118.097 12.14.097c-.938 0-1.632.472-2.04 1.415L.349 23.71c-.245.471-.326.865-.326 1.179 0 1.1.938 2.003 2.121 2.003.979 0 1.673-.51 2.04-1.454l1.537-3.53-.007-.01 1.624-3.782 4.8-11.93 4.723 11.935 1.666 3.807 1.607 3.51c.408.943 1.06 1.454 2.04 1.454 1.183 0 2.121-.903 2.121-2.003 0-.314-.122-.708-.326-1.18\" fill=\"#384256\" mask=\"url(#ark_svg__b)\"/></g><g transform=\"translate(10.976 1.017)\"><mask id=\"ark_svg__d\" fill=\"#fff\"><use xlink:href=\"#ark_svg__c\"/></mask><path d=\"M4.309 2.114c0 1.13-.952 2.047-2.126 2.047C1.01 4.16.058 3.244.058 2.114.058.984 1.01.067 2.183.067c1.174 0 2.126.916 2.126 2.047\" fill=\"#31EC79\" class=\"svg-things-fill\" mask=\"url(#ark_svg__d)\"/></g><path d=\"M1.41 24.663l4.722-10.825c.215-.495.813-.73 1.328-.522l1.935.783a.967.967 0 01.543 1.28L5.216 26.202c-.216.496-.814.73-1.329.522l-1.935-.783a.967.967 0 01-.542-1.28\" fill=\"#D42353\"/><path d=\"M10.288 14.313c0 1.13-.951 2.047-2.125 2.047s-2.125-.916-2.125-2.047c0-1.13.951-2.046 2.125-2.046s2.125.916 2.125 2.046\" fill=\"#FB4D7C\" class=\"svg-health-fill\"/><path d=\"M5.25 25.862c0 1.13-.951 2.047-2.125 2.047C1.952 27.91 1 26.993 1 25.862c0-1.13.952-2.047 2.125-2.047 1.174 0 2.126.917 2.126 2.047\" fill=\"#D42353\"/><path d=\"M22.185 27.5c-1.195 0-2.164-.938-2.164-2.093 0-1.156.97-2.093 2.164-2.093 1.196 0 2.165.937 2.165 2.093 0 1.155-.97 2.092-2.165 2.092z\" fill=\"#F8D11C\"/></g>",
    "attrs": {
      "width": "27",
      "height": "30",
      "viewBox": "0 0 27 30",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
});