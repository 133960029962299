define("ember-svg-jar/inlined/starEmpty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.364 15.675l-5.933 3.23 1.133-6.84-4.8-4.844 6.634-.998L10.364 0l2.967 6.223 6.633.998-4.8 4.844 1.133 6.84z\" fill=\"#D6D6D6\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "20",
      "height": "19",
      "viewBox": "0 0 20 19",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});