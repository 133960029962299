define("ember-svg-jar/inlined/expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><circle stroke=\"#D6D6D6\" stroke-width=\"2\" cx=\"10\" cy=\"10\" r=\"9\"/><path d=\"M11 10.716l2.336 1.46a1 1 0 01-1.06 1.695L9.473 12.12a1 1 0 01-.462-.974A1.008 1.008 0 019 11V5a1 1 0 112 0v5.716z\" fill=\"#D6D6D6\"/></g>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});