define("ember-svg-jar/inlined/bike-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M6 24.25a5 5 0 100-10 5 5 0 000 10zm0 1a6 6 0 100-12 6 6 0 000 12zm20-1a5 5 0 100-10 5 5 0 000 10zm0 1a6 6 0 100-12 6 6 0 000 12z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M10.443 8.136a.5.5 0 01.67.224l1.196 2.39h11.024a.5.5 0 01.369.838l-7.334 8a.5.5 0 01-.368.162H6.667a.5.5 0 01-.416-.777l5.172-7.759-1.204-2.407a.5.5 0 01.224-.67zm1.495 4.108L7.601 18.75h7.59l-3.253-6.506zm4.184 6.133l6.075-6.627h-9.388l3.313 6.627z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M8.167 8.583a.5.5 0 01.5-.5h4.666a.5.5 0 010 1H8.667a.5.5 0 01-.5-.5zM18.833 7.25a.5.5 0 01.5-.5h7.334c.302 0 .744.087 1.125.36.407.294.708.777.708 1.473 0 .303-.087.745-.36 1.126-.294.406-.777.708-1.473.708h-2a.5.5 0 110-1h2c.37 0 .553-.143.66-.292a.992.992 0 00.173-.542c0-.37-.143-.553-.292-.66a.99.99 0 00-.541-.173h-7.334a.5.5 0 01-.5-.5z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M21.827 6.78a.5.5 0 01.642.297l4.667 12.667a.5.5 0 01-.938.346L21.53 7.423a.5.5 0 01.296-.642z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});