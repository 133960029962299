define("ember-svg-jar/inlined/digital", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<rect x=\"1\" y=\".5\" width=\"39\" height=\"39\" rx=\"19.5\" fill=\"#F5F6FA\"/><path d=\"M12.5 11.77c0-.967.784-1.75 1.75-1.75h12.5c.966 0 1.75.783 1.75 1.75v2.5a1.75 1.75 0 01-1.75 1.75h-12.5a1.75 1.75 0 01-1.75-1.75v-2.5zm1.75-.25a.25.25 0 00-.25.25v2.5c0 .138.112.25.25.25h12.5a.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25h-12.5zm-1.75 7.25c0-.967.784-1.75 1.75-1.75h12.5c.966 0 1.75.783 1.75 1.75v2.5a1.75 1.75 0 01-1.75 1.75h-12.5a1.75 1.75 0 01-1.75-1.75v-2.5zm1.75-.25a.25.25 0 00-.25.25v2.5c0 .138.112.25.25.25h12.5a.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25h-12.5zm0 5.5a1.75 1.75 0 00-1.75 1.75v2.5c0 .966.784 1.75 1.75 1.75h12.5a1.75 1.75 0 001.75-1.75v-2.5a1.75 1.75 0 00-1.75-1.75h-12.5zM14 25.77a.25.25 0 01.25-.25h12.5a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-12.5a.25.25 0 01-.25-.25v-2.5z\" fill=\"#203151\"/><rect x=\"1\" y=\".5\" width=\"39\" height=\"39\" rx=\"19.5\" stroke=\"#CFD3DA\"/>",
    "attrs": {
      "width": "41",
      "height": "40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});