define("ember-svg-jar/inlined/messenger-send", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>C7D53214-E446-4557-8B88-1C7A62F3A5DE</title><defs><path d=\"M12 5.893L6.887 19.77l4.617-2.638a1 1 0 01.992 0l4.617 2.638L12 5.893zm0 13.259l-6.504 3.716c-.81.463-1.757-.338-1.434-1.214l7-19c.321-.872 1.555-.872 1.876 0l7 19c.323.876-.624 1.677-1.434 1.214L12 19.152z\" id=\"messenger-send_svg__a\"/></defs><g transform=\"rotate(90 13.5 9.5)\" fill=\"none\" fill-rule=\"evenodd\"><mask id=\"messenger-send_svg__b\" fill=\"#fff\"><use xlink:href=\"#messenger-send_svg__a\"/></mask><use fill=\"#000\" fill-rule=\"nonzero\" xlink:href=\"#messenger-send_svg__a\"/><g mask=\"url(#messenger-send_svg__b)\" fill=\"#B0BACD\"><path d=\"M0 0h24v24H0z\"/></g></g>",
    "attrs": {
      "width": "21",
      "height": "16",
      "viewBox": "0 0 21 16",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
});