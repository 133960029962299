define("ember-svg-jar/inlined/document-add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M18.5 20a.5.5 0 01-.5.5h-5.732A6.518 6.518 0 0111.19 22H18a2 2 0 002-2V9.828a2 2 0 00-.586-1.414l-5.829-5.828a.491.491 0 00-.049-.04.63.63 0 01-.036-.03 2.072 2.072 0 00-.219-.18.652.652 0 00-.08-.044l-.048-.024-.05-.029c-.054-.031-.109-.063-.166-.087a1.977 1.977 0 00-.624-.138.56.56 0 01-.059-.007.605.605 0 00-.082-.007H6a2 2 0 00-2 2v7.498a6.451 6.451 0 011.5-.422V4a.5.5 0 01.5-.5h6V8a2 2 0 002 2h4.5v10zm-5-15.379L17.378 8.5H14a.5.5 0 01-.5-.5V4.621zM12 17.5a5.5 5.5 0 10-11 0 5.5 5.5 0 0011 0zM7 18l.001 2.503a.5.5 0 11-1 0V18H3.496a.5.5 0 010-1H6v-2.5a.5.5 0 111 0V17h2.497a.5.5 0 010 1H7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none"
    }
  };
});