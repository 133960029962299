define("ember-svg-jar/inlined/tea-cup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M36.438 50.695h-26.5c-.61 0-1.105.516-1.105 1.153 0 .636.495 1.152 1.104 1.152h26.5c.61 0 1.105-.516 1.105-1.152 0-.637-.495-1.153-1.105-1.153z\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.208 16.13l44.167-.003c2.367 0 4.554 1.318 5.737 3.457a7.175 7.175 0 010 6.914c-1.183 2.139-3.37 3.457-5.737 3.457h-2.208c0 10.182-7.91 18.436-17.667 18.436h-8.833C7.91 48.391 0 40.137 0 29.955v-11.52c0-1.273.989-2.305 2.208-2.305zm17.667 15.455l-3.313-3.457-3.312 3.456v5.284h6.625v-5.283zm22.083-1.63c-.01 8.905-6.925 16.121-15.458 16.131h-8.833c-8.534-.01-15.45-7.226-15.459-16.131v-11.52h13.25v7.587l-4.093 4.271a1.178 1.178 0 00-.323.814v6.913c0 .637.494 1.153 1.104 1.153h8.833c.61 0 1.104-.516 1.104-1.153v-6.913c0-.306-.116-.599-.323-.815l-4.093-4.27v-7.587h24.291v11.52zm2.209-2.305h2.208c2.44 0 4.417-2.063 4.417-4.609s-1.978-4.61-4.417-4.61h-2.208v9.22z\" fill=\"#0439D7\"/><path d=\"M29.885 13.823a.985.985 0 00.198-.02c.58-.144.946-.74.828-1.35-.473-1.964.32-4.023 1.963-5.1 2.06-1.41 2.956-4.089 2.185-6.531a1.074 1.074 0 00-.489-.679.995.995 0 00-.808-.099C33.2.25 32.89.88 33.06 1.477c.574 2.14-.221 2.885-1.537 4.12-2.222 1.642-3.273 4.522-2.66 7.29.08.53.51.923 1.023.936zM21.051 13.823a.983.983 0 00.2-.02c.578-.144.944-.74.827-1.35-.473-1.964.32-4.023 1.963-5.1 2.06-1.41 2.955-4.089 2.184-6.531a1.074 1.074 0 00-.488-.679.995.995 0 00-.808-.099c-.564.205-.874.836-.704 1.433.574 2.14-.22 2.885-1.536 4.12-2.223 1.642-3.274 4.523-2.661 7.292.08.529.51.922 1.023.934zM12.218 13.823a.991.991 0 00.199-.02c.58-.144.945-.74.828-1.35-.473-1.963.319-4.022 1.962-5.099 2.06-1.41 2.955-4.089 2.184-6.531a1.074 1.074 0 00-.488-.679.995.995 0 00-.808-.099c-.564.205-.874.836-.704 1.433.574 2.14-.22 2.884-1.536 4.12-2.222 1.642-3.273 4.522-2.66 7.29.08.53.51.923 1.023.935z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "53",
      "height": "53",
      "viewBox": "0 0 53 53",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});