define("ember-svg-jar/inlined/chevron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M-3 5l7.036 5.86L11.07 5\" stroke=\"#D6D6D6\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "8",
      "height": "16",
      "viewBox": "0 0 8 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});