define("ember-svg-jar/inlined/arrowRight", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.264 2.871A1.142 1.142 0 112.021 1.41l5.711 6.857a1.142 1.142 0 010 1.462l-5.71 6.858a1.143 1.143 0 01-1.758-1.462l5.103-6.127L.264 2.871z\" fill=\"currentColor\"/>",
    "attrs": {
      "viewBox": "0 0 8 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});