define("ember-svg-jar/inlined/gkvCheckBlue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g transform=\"rotate(10 44.726 50.461)\" stroke=\"#477CD9\" stroke-width=\"4\" fill=\"none\" fill-rule=\"evenodd\"><circle cx=\"50\" cy=\"50\" r=\"48\"/><path stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M68.46 22.319l-24.04 50.48-21.483-16.263\"/></g>",
    "attrs": {
      "width": "100",
      "height": "101",
      "viewBox": "0 0 100 101",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});