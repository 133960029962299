define("ember-svg-jar/inlined/clipboard-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M57.76 9.72v61.56h-45.6v6.48h51.68V9.72h-6.08z\" fill=\"#DADDEC\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.16 6.48h51.68c1.679 0 3.04 1.45 3.04 3.24v68.04c0 1.79-1.361 3.24-3.04 3.24H12.16c-1.679 0-3.04-1.45-3.04-3.24V9.72c0-1.79 1.361-3.24 3.04-3.24zm0 71.28h51.68V9.72H12.16v68.04z\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M45.6 27.54c0 4.473-3.403 8.1-7.6 8.1-4.197 0-7.6-3.627-7.6-8.1 0-4.474 3.403-8.1 7.6-8.1 4.197 0 7.6 3.626 7.6 8.1zm-3.04 0c0-2.684-2.042-4.86-4.56-4.86-2.517.003-4.557 2.177-4.56 4.86 0 2.684 2.042 4.86 4.56 4.86s4.56-2.176 4.56-4.86zm9.12 25.92a15.274 15.274 0 01-.152 1.62H24.472c-.08-.536-.13-1.077-.152-1.62 0-8.052 6.125-14.58 13.68-14.58s13.68 6.528 13.68 14.58zm-3.148-1.62c-.763-5.576-5.245-9.713-10.532-9.72-5.287.007-9.77 4.144-10.532 9.72h21.064z\" fill=\"#0439D7\"/><path d=\"M25.84 64.8h24.32c.84 0 1.52-.725 1.52-1.62 0-.895-.68-1.62-1.52-1.62H25.84c-.84 0-1.52.725-1.52 1.62 0 .895.68 1.62 1.52 1.62zm3.04-61.56h18.24c.84 0 1.52-.725 1.52-1.62 0-.895-.68-1.62-1.52-1.62H28.88c-.84 0-1.52.725-1.52 1.62 0 .895.68 1.62 1.52 1.62z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "76",
      "height": "81",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});