define("ember-svg-jar/inlined/voucher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.87 2.884a1.2 1.2 0 00-1.66.036L1.98 16.152a1.2 1.2 0 000 1.697l1.852 1.852s-.001 0 0 0c.004.002.02.007.047.007a.41.41 0 00.216-.071 3.8 3.8 0 015.27 5.27.41.41 0 00-.072.214.14.14 0 00.006.047l1.809 1.81a1.2 1.2 0 001.722-.027l13.199-14.023a1.2 1.2 0 00-.063-1.707l-1.773-1.625-.005-.001a.222.222 0 00-.086.002.557.557 0 00-.295.165 3.8 3.8 0 11-5.601-5.137.553.553 0 00.139-.306.221.221 0 00-.006-.085l-.002-.006-1.466-1.344zM14.08 1.79a2.8 2.8 0 013.871-.084l1.467 1.344c.438.402.561.947.52 1.413a2.15 2.15 0 01-.557 1.248 2.2 2.2 0 103.243 2.973 2.16 2.16 0 011.196-.661c.461-.083 1.015-.007 1.453.395l1.773 1.625a2.8 2.8 0 01.147 3.983L13.994 28.048a2.8 2.8 0 01-4.018.06L8.166 26.3c-.694-.694-.526-1.693-.134-2.281a2.2 2.2 0 00-3.05-3.05c-.59.391-1.588.558-2.282-.136L.847 18.981a2.8 2.8 0 010-3.96L14.08 1.789z\" fill=\"#212121\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});