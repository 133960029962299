define("ember-svg-jar/inlined/call", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.056 1.418l1.167-.351a2.75 2.75 0 013.302 1.505l.902 2.006a2.75 2.75 0 01-.633 3.139L6.3 9.11a.25.25 0 00-.078.155c-.044.397.225 1.17.845 2.245.451.781.86 1.33 1.207 1.637.242.215.375.261.432.245l2.01-.615a2.75 2.75 0 013.035 1.02l1.28 1.776a2.75 2.75 0 01-.339 3.605l-.886.84a3.75 3.75 0 01-3.587.889c-2.754-.769-5.223-3.093-7.435-6.924C.569 10.147-.208 6.843.508 4.07a3.75 3.75 0 012.548-2.652zm.433 1.437a2.25 2.25 0 00-1.529 1.59c-.602 2.332.087 5.261 2.123 8.788 2.033 3.522 4.222 5.582 6.54 6.23a2.25 2.25 0 002.151-.534l.887-.84a1.25 1.25 0 00.154-1.639l-1.28-1.775a1.25 1.25 0 00-1.38-.464l-2.015.617c-1.17.348-2.232-.593-3.372-2.568C5 10.93 4.642 9.9 4.731 9.099c.047-.416.24-.8.546-1.086L6.771 6.62a1.25 1.25 0 00.288-1.427l-.902-2.006a1.25 1.25 0 00-1.5-.684l-1.168.352z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "16",
      "height": "22",
      "viewBox": "0 0 16 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});