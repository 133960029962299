define("ember-css-modules-active-route/services/css-modules-active-route/engine-router", ["exports", "@ember/application", "@ember/debug", "@ember/object", "@ember/object/evented", "@ember/service", "ember-css-modules-active-route/utils/owner"], function (_exports, _application, _debug, _object, _evented, _service, _owner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  /**
   * This is an incomplete implementation of a would-be `RouterService` scoped to
   * engines.
   *
   * @see https://github.com/ember-engines/ember-engines/issues/587
   */
  let EngineRouterService = _exports.default = (_dec = (0, _object.computed)('rootApplication'), _class = class EngineRouterService extends _service.default.extend(_evented.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "engine", (0, _application.getOwner)(this));
      _defineProperty(this, "rootApplication", (0, _owner.getRootOwner)(this));
    }
    init() {
      super.init();
      this.externalRouter.on('routeWillChange', this.onRouteWillChange);
      this.externalRouter.on('routeDidChange', this.onRouteDidChange);
    }
    destroy() {
      this.externalRouter.off('routeWillChange', this.onRouteWillChange);
      this.externalRouter.off('routeDidChange', this.onRouteDidChange);
      return super.destroy();
    }
    onRouteWillChange(transition) {
      this.trigger('routeWillChange', this.buildInternalTransition(transition));
    }
    onRouteDidChange(transition) {
      this.trigger('routeDidChange', this.buildInternalTransition(transition));
    }
    get mountPoint() {
      return this.engine.mountPoint;
    }
    get externalRouter() {
      return this.rootApplication.lookup('service:router');
    }
    belongsToEngine(externalRouteName) {
      return externalRouteName === this.mountPoint || externalRouteName.startsWith("".concat(this.mountPoint, "."));
    }
    getRelativeInternalRouteName(externalRouteName) {
      if (externalRouteName === this.mountPoint) {
        return 'application';
      }
      (false && !(this.belongsToEngine(externalRouteName)) && (0, _debug.assert)("'".concat(externalRouteName, "' is not a sub-route of '").concat(this.mountPoint, "'"), this.belongsToEngine(externalRouteName)));
      return externalRouteName.slice(this.mountPoint.length + 1);
    }
    buildInternalTransition(transition) {
      return {
        from: transition.from && this.buildInternalRouteInfo(transition.from),
        to: this.buildInternalRouteInfo(transition.to)
      };
    }
    buildInternalRouteInfo(routeInfo) {
      if (!this.belongsToEngine(routeInfo.name)) return null;
      return {
        name: this.getRelativeInternalRouteName(routeInfo.name),
        parent: routeInfo.parent && this.buildInternalRouteInfo(routeInfo.parent)
      };
    }
  }, _applyDecoratedDescriptor(_class.prototype, "onRouteWillChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onRouteWillChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onRouteDidChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onRouteDidChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "externalRouter", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "externalRouter"), _class.prototype), _class);
});