define("ember-svg-jar/inlined/row-triple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.333 5.027a2.333 2.333 0 012.334-2.334h16.666a2.333 2.333 0 012.334 2.334V8.36a2.333 2.333 0 01-2.334 2.333H7.667A2.333 2.333 0 015.333 8.36V5.027zm2.334-.334a.333.333 0 00-.334.334V8.36c0 .184.15.333.334.333h16.666c.184 0 .334-.149.334-.333V5.027a.333.333 0 00-.334-.334H7.667zM5.333 14.36a2.333 2.333 0 012.334-2.333h16.666a2.333 2.333 0 012.334 2.333v3.333a2.333 2.333 0 01-2.334 2.334H7.667a2.333 2.333 0 01-2.334-2.334V14.36zm2.334-.333a.333.333 0 00-.334.333v3.333c0 .184.15.334.334.334h16.666c.184 0 .334-.15.334-.334V14.36a.333.333 0 00-.334-.333H7.667zM7.667 21.36a2.333 2.333 0 00-2.334 2.333v3.334a2.333 2.333 0 002.334 2.333h16.666a2.333 2.333 0 002.334-2.333v-3.334a2.333 2.333 0 00-2.334-2.333H7.667zm-.334 2.333c0-.184.15-.333.334-.333h16.666c.184 0 .334.15.334.333v3.334c0 .184-.15.333-.334.333H7.667a.333.333 0 01-.334-.333v-3.334z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});