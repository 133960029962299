define("ember-svg-jar/inlined/success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M-7 3h109v90H-7z\"/><g fill=\"#fff\" stroke-width=\"2.475\"><path d=\"M58.498 23.358c-1.853-.35-3.575.682-3.885 2.225l-8.274 41.086c-.308 1.531.872 3.096 2.714 3.445l26.435 5.003c1.853.35 3.575-.682 3.886-2.225l8.273-41.086c.308-1.531-.871-3.096-2.714-3.445z\" stroke=\"#d6d6d6\"/><path d=\"M10.852 28.36c-1.843.35-3.023 1.915-2.715 3.446l8.274 41.086c.31 1.543 2.032 2.576 3.885 2.225l26.435-5.003c1.843-.349 3.023-1.914 2.714-3.445l-8.273-41.086c-.31-1.543-2.033-2.576-3.886-2.225z\" stroke=\"#d6d6d6\"/><path d=\"M29.174 16.238c-.684 0-1.238.554-1.238 1.237v52.8c0 .683.554 1.238 1.238 1.238h37.404c.683 0 1.238-.555 1.238-1.238v-52.8c0-.683-.555-1.237-1.238-1.237z\" stroke=\"#2b6cde\"/></g><path d=\"M35.188 21h37.404a2.475 2.475 0 012.475 2.475v52.8a2.475 2.475 0 01-2.475 2.475H35.188a2.475 2.475 0 01-2.475-2.475v-52.8A2.475 2.475 0 0135.188 21z\" fill=\"#2b6cde\" opacity=\".264\"/><path d=\"M47.917 42.69l-4.672 2.46.893-5.21-3.78-3.69 5.223-.76 2.336-4.74 2.335 4.74 5.223.76-3.78 3.69.893 5.21z\" stroke=\"#2b6cde\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2.1\"/><path d=\"M72.853 7.65s-1.068 1.681-3.202 5.046m-3.92-5.946v4.584m10.723 3.184l-3.877.81\" stroke=\"#2b6cde\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.815\"/><g stroke=\"#2b6cde\" stroke-width=\"2.599\" transform=\"translate(47.124 51.75)\"><ellipse cx=\"17.064\" cy=\"16.813\" fill=\"#fff\" rx=\"17.064\" ry=\"16.813\"/><ellipse cx=\"22.838\" cy=\"17.325\" fill=\"#2b6cde\" opacity=\".231\" rx=\"15.364\" ry=\"14.85\"/><path d=\"M10.796 18.93l4.228 4.17 9.06-10.725\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></g>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 95 95",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});