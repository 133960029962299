define("ember-svg-jar/inlined/progress-bar-todo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>To do</title><path d=\"M10 2a8 8 0 100 16 8 8 0 000-16z\" fill-rule=\"nonzero\" stroke=\"#DADDEC\" stroke-width=\"4\" fill=\"none\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});