define("ember-svg-jar/inlined/noSignatures", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><ellipse fill=\"#2B6CDE\" class=\"svg-brand-primary-fill\" cx=\"50.8\" cy=\"51\" rx=\"50.8\" ry=\"51\"/><g stroke-width=\"2.472\" stroke=\"#FFF\"><path d=\"M79.067 49.737v31.737c0 1.686-1.396 3.054-3.132 3.054H27.932c-1.73 0-3.132-1.377-3.132-3.07V20.236c0-1.695 1.396-3.069 3.132-3.069h48.003c1.73 0 3.132 1.367 3.132 3.07v9.902\" fill=\"#2B6CDE\" class=\"svg-brand-primary-fill\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M59.098 54.33l33.276-39.657c1.225-1.46 3.433-1.624 4.922-.375l3.603 3.024c1.494 1.253 1.715 3.448.486 4.912L68.109 61.891h0\" fill=\"#2B6CDE\" class=\"svg-brand-primary-fill\"/><path d=\"M68.109 61.891l-10.175 5.585c-2.242 1.23-3.393.266-2.57-2.157l3.734-10.99\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M67.924 62.11l-9.059-7.6M96.888 27.593L87.83 19.99\"/><path d=\"M33.026 78.588s5.424-18.719 7.767-17.184c2.343 1.534-.875 12.17-.875 12.17-.65 2.477.428 3.164 2.19 1.307 0 0-.684 1.608 3.744-4.93 4.428-6.537.508 6.243 1.35 7.021.841.779 3.836 0 5.775-3.327\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M33.026 25.793h31.111M33.026 31.943H56.63M33.026 38.094h16.299M33.026 44.182h24.647M33.026 50.394h16.299\" stroke-linecap=\"round\"/></g></g>",
    "attrs": {
      "width": "107",
      "height": "102",
      "viewBox": "0 0 107 102",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});