define("ember-svg-jar/inlined/call-outbound", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 2.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v3a.5.5 0 01-1 0V3.707L9.854 6.854a.5.5 0 01-.708-.707L12.293 3H10.5a.5.5 0 01-.5-.5zm-5.626-.38a1.75 1.75 0 012.196.886l.584 1.228a1.5 1.5 0 01-.19 1.592l-.946 1.16a4.13 4.13 0 00.475 1.28 4.133 4.133 0 00.877 1.038l1.506-.276a1.5 1.5 0 011.354.437l.809.844a1.75 1.75 0 01-.063 2.483l-.296.28c-1.126 1.063-2.88 1.29-4.134.247-.94-.782-2.069-1.88-2.918-3.213-.94-1.477-1.38-3.23-1.588-4.55-.238-1.506.72-2.83 2.07-3.337l.264-.1zm2.862 7.717l-.289.408-.002-.001-.003-.002-.009-.007a1.76 1.76 0 01-.128-.1A5.132 5.132 0 015.63 8.77a5.128 5.128 0 01-.635-1.857L4.992 6.9v-.005l.497-.052-.497.05a.5.5 0 01.11-.366l1.086-1.334a.5.5 0 00.063-.53l-.584-1.229a.75.75 0 00-.941-.38l-.263.1c-.985.37-1.587 1.286-1.435 2.244.198 1.261.609 2.86 1.443 4.17.774 1.213 1.818 2.236 2.714 2.98.803.668 1.988.57 2.809-.204l.296-.28a.75.75 0 00.027-1.064l-.81-.844a.5.5 0 00-.45-.146l-1.73.318a.5.5 0 01-.38-.084l.29-.408z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});