define("ember-svg-jar/inlined/icon-nav-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zm-1.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15 3.75a1.5 1.5 0 00-1.5-1.5h-3A1.5 1.5 0 009 3.75v.533c0 .511-.31.966-.752 1.222s-.988.294-1.43.039l-.463-.267a1.5 1.5 0 00-2.049.549l-1.5 2.598a1.5 1.5 0 00.55 2.05l.462.266c.443.256.682.75.682 1.26s-.24 1.004-.682 1.26l-.463.267a1.5 1.5 0 00-.549 2.049l1.5 2.598a1.5 1.5 0 002.05.55l.462-.268c.442-.255.988-.217 1.43.04.442.255.752.71.752 1.22v.534a1.5 1.5 0 001.5 1.5h3a1.5 1.5 0 001.5-1.5v-.533c0-.511.31-.966.752-1.221.442-.257.988-.295 1.43-.04l.463.267a1.5 1.5 0 002.049-.549l1.5-2.598a1.5 1.5 0 00-.55-2.05l-.462-.266c-.443-.256-.682-.75-.682-1.26s.24-1.004.682-1.26l.463-.267a1.5 1.5 0 00.549-2.049l-1.5-2.598a1.5 1.5 0 00-2.05-.55l-.462.268c-.442.255-.988.217-1.43-.04-.442-.255-.752-.71-.752-1.22V3.75zm-4.5 0h3v.533c0 1.14.682 2.046 1.5 2.52.827.478 1.95.607 2.932.04l.463-.267 1.5 2.598-.463.267C18.449 10.01 18 11.048 18 12c0 .952.448 1.991 1.432 2.559l.463.267-1.5 2.598-.463-.267c-.982-.567-2.105-.438-2.931.04-.82.474-1.501 1.38-1.501 2.52v.533h-3v-.533c0-1.14-.682-2.046-1.5-2.52-.827-.478-1.95-.607-2.932-.04l-.463.267-1.5-2.598.463-.267C5.551 13.99 6 12.952 6 12c0-.952-.449-1.991-1.432-2.559l-.463-.267 1.5-2.598.463.267c.982.567 2.105.438 2.931-.04.82-.474 1.501-1.38 1.501-2.52V3.75z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});