define("ember-svg-jar/inlined/cross-filled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 18A9 9 0 109 0a9 9 0 000 18z\" fill=\"#F26363\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.634 5.234a.8.8 0 011.132 1.132L10.13 9l2.635 2.634a.8.8 0 01-1.132 1.132L9 10.13l-2.634 2.635a.8.8 0 01-1.132-1.132L7.87 9 5.234 6.366a.8.8 0 011.132-1.132L9 7.87l2.634-2.635z\" fill=\"#fff\"/>",
    "attrs": {
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});