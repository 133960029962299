define("ember-svg-jar/inlined/clark-communication-assets--notification-bell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"24.5\" cy=\"24\" r=\"24\" fill=\"currentColor\"/><path d=\"M24.5 14.996a7.49 7.49 0 017.496 7.25l.004.25v4.097l1.38 3.156a1.249 1.249 0 01-1.145 1.75l-4.735.002a3 3 0 01-5.995.177l-.005-.179h-4.725a1.25 1.25 0 01-1.146-1.747L17 26.594v-4.098c0-4.155 3.352-7.5 7.5-7.5zM26 31.5l-3 .002a1.5 1.5 0 002.993.145L26 31.5zm-1.5-15.003c-3.32 0-6 2.674-6 6v4.41L17.156 30h14.697L30.5 26.907v-4.398l-.003-.225a5.988 5.988 0 00-5.997-5.788z\" fill=\"#01051C\"/>",
    "attrs": {
      "width": "49",
      "height": "48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});