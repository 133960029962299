define("ember-svg-jar/inlined/heavyload-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<mask id=\"heavyload-icon_svg__a\" style=\"mask-type:alpha\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"68\" height=\"68\"><path fill=\"#C4C4C4\" d=\"M0 0h68v68H0z\"/></mask><g mask=\"url(#heavyload-icon_svg__a)\" fill=\"#0439D7\"><path d=\"M32.725 22.525h2.55v15.3h-2.55z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M64.755 13.754L54.245 3.246a3.825 3.825 0 00-2.704-1.121H16.459a3.825 3.825 0 00-2.704 1.12l-10.51 10.51a3.825 3.825 0 00-1.12 2.704V51.54c0 1.015.403 1.987 1.12 2.705l10.51 10.508a3.825 3.825 0 002.704 1.121h35.082a3.825 3.825 0 002.705-1.12l10.509-10.51a3.825 3.825 0 001.12-2.704V16.46a3.825 3.825 0 00-1.12-2.705zm-1.43 37.787c0 .338-.134.663-.373.902L52.442 62.95c-.238.24-.563.375-.901.374H16.459a1.264 1.264 0 01-.901-.374L5.048 52.443a1.27 1.27 0 01-.373-.902V16.46c0-.338.134-.663.373-.902L15.558 5.05c.238-.24.563-.375.901-.374h35.082c.338-.001.663.133.901.374l10.51 10.508c.24.239.374.563.373.902V51.54z\"/><path d=\"M32.725 42.925h2.55v2.55h-2.55z\"/></g>",
    "attrs": {
      "width": "68",
      "height": "68",
      "viewBox": "0 0 68 68",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});