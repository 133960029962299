define("ember-svg-jar/inlined/private-health-insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M13.451 10.903a4.451 4.451 0 100-8.903 4.451 4.451 0 000 8.903zm0 1a5.451 5.451 0 100-10.903 5.451 5.451 0 000 10.903z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M5.37 5.057C5.917 4.56 6.582 4.5 7.022 4.5H8.5v1H7.021c-.368 0-.714.057-.977.296-.258.236-.544.75-.544 1.92V31h15.04V7.583c.001-.57.002-1.095-.136-1.5-.07-.2-.162-.334-.28-.421-.115-.086-.302-.162-.624-.162H18v-1h1.5c.486 0 .896.118 1.221.36.323.24.514.567.628.9.197.573.194 1.27.192 1.799V31H29.5a.5.5 0 010 1h-27a.5.5 0 010-1h2V7.716c0-1.312.32-2.157.87-2.659z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M25.5 14.5H21v-1h4.5c.366 0 .869.14 1.284.54.427.41.716 1.05.716 1.96v15a.5.5 0 01-1 0V16c0-.69-.211-1.05-.409-1.24a.907.907 0 00-.591-.26zm-14.983 8.211a.699.699 0 00-.017.146V31h-1v-8.143c0-.201.044-.506.197-.78.167-.297.481-.577.97-.577h5.666c.222 0 .514.08.757.305.256.237.41.593.41 1.052V31h-1v-8.143c0-.226-.069-.3-.09-.319a.154.154 0 00-.051-.031c-.016-.006-.027-.007-.027-.007h-5.665c-.038 0-.05.008-.053.01a.159.159 0 00-.044.056.542.542 0 00-.053.145zM13.557 4.5a.5.5 0 01.5.5v3.115a.5.5 0 01-1 0V5a.5.5 0 01.5-.5z\" clip-rule=\"evenodd\"/><path fill-rule=\"evenodd\" d=\"M11.5 6.558a.5.5 0 01.5-.5h3.115a.5.5 0 110 1H12a.5.5 0 01-.5-.5zM8.5 19a.5.5 0 01.5-.5h8.566a.5.5 0 110 1H9a.5.5 0 01-.5-.5zm0-4a.5.5 0 01.5-.5h8.566a.5.5 0 110 1H9a.5.5 0 01-.5-.5z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "32",
      "height": "32",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});