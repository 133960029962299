define("ember-svg-jar/inlined/phone-call-consent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20.8 7.8a7.2 7.2 0 11-14.4 0 7.2 7.2 0 0114.4 0zm-3.434-2.966a.8.8 0 00-1.132 0L12 9.068l-1.034-1.034a.8.8 0 00-1.132 1.131l1.6 1.6a.8.8 0 001.132 0l4.8-4.8a.8.8 0 000-1.131zM6.81 2.2H2.4A2.4 2.4 0 000 4.6v20.8a2.4 2.4 0 002.4 2.4h11.2a2.4 2.4 0 002.4-2.4v-9.132a8.78 8.78 0 01-1.6.296V25.4a.8.8 0 01-.8.8H2.4a.8.8 0 01-.8-.8V4.6a.8.8 0 01.8-.8h3.36A8.812 8.812 0 016.81 2.2zM4.8 22.2a.8.8 0 01.8-.8h4.8a.8.8 0 010 1.6H5.6a.8.8 0 01-.8-.8z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "21",
      "height": "28",
      "viewBox": "0 0 21 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});