define("ember-svg-jar/inlined/recommendations-disable-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M42.318 10.25L33.75.825 33 0H4a4 4 0 00-4 4v50a4 4 0 004 4h26v-1.5H4A2.5 2.5 0 011.5 54V4A2.5 2.5 0 014 1.5h28.25V7A4.75 4.75 0 0037 11.75h4.5V48H43V11l-.682-.75zm-2.027 0L33.75 3.055V7A3.25 3.25 0 0037 10.25h3.291zM34.5 31.75H9v-1.5h25.5v1.5zm-25.5 6h25.5v-1.5H9v1.5zm25.5 6H9v-1.5h25.5v1.5z\" fill=\"#CFD3DA\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M33.53 47.47a.75.75 0 00-1.06 1.06l3.354 3.355c-1.925 1.535-3.273 3.425-3.909 4.422a1.28 1.28 0 000 1.386C33.1 59.549 36.75 64.5 42 64.5c1.858 0 3.516-.62 4.935-1.504l3.534 3.534a.75.75 0 001.061-1.06l-18-18zm12.312 14.433l-1.774-1.774a3.75 3.75 0 01-5.197-5.197l-1.98-1.98c-.163.128-.325.26-.482.395-1.495 1.28-2.582 2.77-3.155 3.653.573.883 1.66 2.374 3.155 3.653 1.565 1.34 3.455 2.347 5.59 2.347 1.393 0 2.681-.428 3.843-1.097zm-5.873-5.873a2.25 2.25 0 003 3l-3-3z\" fill=\"#0439D7\"/><path d=\"M50.746 57a18.023 18.023 0 01-2.504 3.06l1.06 1.06a19.554 19.554 0 002.782-3.427 1.28 1.28 0 000-1.386C50.9 54.451 47.25 49.5 42 49.5c-1.283 0-2.47.295-3.549.77l1.155 1.154A7.118 7.118 0 0142 51c2.136 0 4.026 1.007 5.591 2.347 1.495 1.28 2.582 2.77 3.155 3.653z\" fill=\"#0439D7\"/><path d=\"M41.47 53.287a3.75 3.75 0 014.244 4.243l-4.245-4.243z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "54",
      "height": "70",
      "viewBox": "0 0 54 70",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});