define("ember-svg-jar/inlined/progress-finalize-draft", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M52 23v-4.672c0-.77-.32-1.379-.696-2.093-.077-.148-.157-.3-.237-.46C50.6 14.847 36.133.928 36.133.928 35.433.464 34.5 0 33.567 0H6.5C4.633 0 3 1.624 3 3.48V23h2.333V3.48c0-.696.467-1.16 1.167-1.16h26.833v12.76c0 1.856 1.634 3.48 3.5 3.48h12.834V23H52zm0 7.5h-2.333v24.02c0 .696-.467 1.16-1.167 1.16h-42c-.7 0-1.167-.464-1.167-1.16V30.5H3v24.02C3 56.376 4.633 58 6.5 58h42c1.867 0 3.5-1.624 3.5-3.48V30.5zM35.667 15.08V3.944L48.033 16.24h-11.2c-.7 0-1.166-.464-1.166-1.16z\" fill=\"#BDC0CF\"/><rect y=\"26\" width=\"55\" height=\"1.5\" rx=\".75\" fill=\"#0439D7\"/><rect x=\"10\" y=\"7\" width=\"8\" height=\"8\" rx=\"4\" fill=\"#BDC0CF\"/><rect x=\"11\" y=\"34\" width=\"22\" height=\"2.5\" rx=\"1.25\" fill=\"#BDC0CF\"/><rect x=\"11\" y=\"40.5\" width=\"18\" height=\"2.5\" rx=\"1.25\" fill=\"#BDC0CF\"/><rect x=\"11\" y=\"47\" width=\"15\" height=\"2.5\" rx=\"1.25\" fill=\"#BDC0CF\"/>",
    "attrs": {
      "width": "55",
      "height": "58",
      "viewBox": "0 0 55 58",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});