define("ember-svg-jar/inlined/neinCircle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g transform=\"translate(1.6 .838)\" fill=\"none\" fill-rule=\"evenodd\"><ellipse stroke=\"#BBB\" stroke-width=\"1.28\" fill=\"#BBB\" cx=\"10.8\" cy=\"10.801\" rx=\"10.8\" ry=\"10.801\"/><g stroke=\"#FFF\" stroke-width=\"1.8\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M7.413 13.951l6.627-6.75M14.04 13.951l-6.627-6.75\"/></g></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
});