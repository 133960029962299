define("ember-svg-jar/inlined/like", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6 11.25l4.098-8.879c.245-.53.783-.897 1.356-.78 2.796.576 2.796 3.659 2.796 3.659V9h6.48a1.5 1.5 0 011.479 1.747l-1.75 10.5a1.5 1.5 0 01-1.48 1.253H3A1.5 1.5 0 011.5 21v-8.25a1.5 1.5 0 011.5-1.5h3zM12.75 9a1.5 1.5 0 001.5 1.5h6.48L18.98 21H7.5v-9.42l3.901-8.453c.609.2.92.617 1.114 1.084a3.274 3.274 0 01.235 1.05V9zM6 12.75H3V21h3v-8.25z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});