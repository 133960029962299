define("ember-svg-jar/inlined/send", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 30.008c7.736 0 14.008-6.271 14.008-14.008 0-7.736-6.272-14.008-14.008-14.008C8.264 1.992 1.992 8.264 1.992 16c0 7.736 6.272 14.008 14.008 14.008zM11.297 14.42a1 1 0 101.457 1.37l2.238-2.379v7.699a1 1 0 102 0v-7.708l2.255 2.388a1 1 0 101.455-1.373l-3.964-4.197a1.004 1.004 0 00-.21-.171 1 1 0 00-1.267.158l-3.964 4.213z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});