define("ember-svg-jar/inlined/addHealthInsurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g stroke-width=\"2\" stroke=\"#FFF\" class=\"add-health-insurance-icon\" fill=\"none\" fill-rule=\"evenodd\"><g stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"M44.147 1.66c-5.041 0-9.536 2.43-12.647 6.212-3.111-3.782-7.606-6.211-12.647-6.211C9.548 1.66 2 9.718 2 19.65c0 22.51 29.5 36.01 29.5 36.01S61 42.16 61 19.65c0-9.933-7.548-17.99-16.853-17.99h0z\"/><path d=\"M43.043 24.032h-6.926V17.09h-9.234v6.943h-6.926v9.257h6.926v6.943h9.234V33.29h6.926z\"/></g><g transform=\"translate(49.806 10.5)\"><circle fill=\"#497CD6\" cx=\"11\" cy=\"11\" r=\"11\"/><g stroke-linecap=\"round\"><path d=\"M11.25 6.525v10M16.25 11.525h-10\"/></g></g></g>",
    "attrs": {
      "class": "add-health-insurance",
      "width": "75",
      "height": "57",
      "viewBox": "0 0 75 57",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});