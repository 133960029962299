define("ember-css-modules-active-route/utils/owner", ["exports", "@ember/application", "@ember/application/instance"], function (_exports, _application, _instance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getRootOwner = void 0;
  const getRootOwner = object => {
    const owner = (0, _application.getOwner)(object);
    if (owner instanceof _instance.default) return owner;

    // @warning: This relies on the implicit fact that there is only one Router
    // per application. This could potentially break. By then, we hopefully have
    // a `RouterService` inside Engines.
    // https://github.com/ember-engines/ember-engines/issues/587
    return (0, _application.getOwner)(owner.lookup('router:main'));
  };
  _exports.getRootOwner = getRootOwner;
});