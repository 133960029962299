define("ember-svg-jar/inlined/noInsurer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g stroke-width=\"1.75\" fill=\"none\"><path d=\"M13.284 29c-.13 0-.26-.023-.386-.072C4.95 26.016 1.095 22.419 1.095 17.926V4.15c0-.283.136-.552.372-.73.234-.18.543-.251.84-.194 1.264.242 2.958.257 4.647.036 2.812-.37 4.83-1.249 5.594-1.962.076-.076.165-.14.266-.19a1.07 1.07 0 01.859-.038c.143.055.266.139.365.242.777.711 2.786 1.58 5.579 1.948 1.689.221 3.383.207 4.647-.036.296-.057.606.014.84.193a.92.92 0 01.372.731v13.776c0 4.496-3.858 8.094-11.794 10.998a1.083 1.083 0 01-.398.076z\" stroke=\"#A8AAB2\" fill=\"#FFF\"/><path d=\"M13.286 3v24\" stroke=\"#D6D6D6\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></g>",
    "attrs": {
      "width": "27",
      "height": "30",
      "viewBox": "0 0 27 30",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});