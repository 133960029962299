define("ember-svg-jar/inlined/clark-contracts-assets--building-bank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.5 6.25a1 1 0 11-2 0 1 1 0 012 0zm.032-3.926a1.75 1.75 0 00-2.064 0L3.047 7.74c-.978.713-.473 2.26.736 2.26H4v5.8a2.75 2.75 0 00-1.5 2.45v1.5c0 .414.336.75.75.75h16.5a.75.75 0 00.75-.75v-1.5A2.75 2.75 0 0019 15.8V10h.217c1.21 0 1.713-1.547.736-2.26l-7.421-5.416zm-1.18 1.212a.25.25 0 01.295 0L18.45 8.5H4.55l6.803-4.964zM17.5 10v5.5h-2V10h2zM14 10v5.5h-1.75V10H14zm-3.25 0v5.5H9V10h1.75zm-5.5 7h12.5c.69 0 1.25.56 1.25 1.25V19H4v-.75c0-.69.56-1.25 1.25-1.25zm.25-1.5V10h2v5.5h-2z\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "currentColor",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});