define("ember-svg-jar/inlined/gothaer-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 6a6 6 0 016-6h28a6 6 0 016 6v28a6 6 0 01-6 6H6a6 6 0 01-6-6V6z\" fill=\"#02648A\"/><path d=\"M35 17.643l-.589 1.201c-.672-.048-1.105-.334-1.105-.334l-.444.855v3.38l-1.388.299v-4.446l-.643-.291.56-1.143 1.471.661v.356c0 .12-.006.239-.006.239.004.002.059-.13.107-.23l.5-1.026s.679.41 1.537.479zM16.325 18.385h-.813l.501-1.101h.312v-.422l1.392-.76v1.182h.897l-.498 1.101h-.399v3.055c.297.236.86.401.86.395l-.594 1.209s-1.05-.308-1.658-.85v-3.809zM22.512 24.11l-1.389.67v-6.079l-.477-.226-.345.656v3.605l-1.389.308V16.57c0-.398-.103-.878-.103-.878L20.124 15s.177.443.177 1.15v1.855c0 .145-.007.235-.007.235.005.002.058-.127.107-.227l.414-.85 1.697.766v6.18z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.726 17.447l-1.292.975-.532-1.84a5.104 5.104 0 00-.023-.075c-.014-.046-.024-.082-.022-.084 0 0 .07.041.14.076.827.406 1.807.571 1.807.571l1.45-1.08s-1.493-.209-2.643-.721L6.5 16.837l.128.459-1.355.983S5 18.74 5 19.676c0 1.467.798 3.368 3.405 3.368.485 0 .87-.045.87-.045l1.851-1.382s.23-.58.23-1.777c0-1.204-.856-2.22-1.63-2.393zm-3.042.512c.048-.124.074-.207.074-.207h.009s.014.095.043.2l.977 3.528 1.244-.915-.446-1.613.446-.318c.335.123.846.678.846 1.901 0 .82-.209 1.44-.209 1.44s-.146.064-.497.064c-1.494 0-2.68-.917-2.68-2.962 0-.494.113-.896.193-1.118zM29.962 23.044l.587-1.198s-1.06-.19-1.741-.636v-.556l2.243-.673-1.125-2.817-2.507.758v4.012c.992.784 2.543 1.11 2.543 1.11zm-.993-4.669l.587 1.463-.748.224V18.42c0-.116-.002-.225-.004-.311a1.4 1.4 0 010-.177s.074.215.165.442zM23.224 22.25c.644.491 1.583.744 1.583.744l.439-.871c.037-.075.104-.222.104-.222s-.006.165-.006.24v.903l1.363-.303V18.05l-1.96-.886-1.523 1.131v3.954zm2.122-3.442v2.973s-.414-.062-.733-.31v-2.994l.733.331zM13.655 23.044l1.724-1.283V18.05l-1.961-.886-1.523 1.131v3.953c.693.532 1.76.796 1.76.796zm.36-4.236v3.021c-.466-.096-.732-.31-.732-.31v-3.042l.733.331z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});