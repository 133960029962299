define("ember-svg-jar/inlined/clark-customer-assets--rate-2-stars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 22.5c5.799 0 10.5-4.701 10.5-10.5S17.799 1.5 12 1.5 1.5 6.201 1.5 12 6.201 22.5 12 22.5zm0-1.5a9 9 0 100-18 9 9 0 000 18z\" fill=\"#0439D7\"/><ellipse cx=\"9\" cy=\"8.775\" rx=\"1.5\" ry=\"1.725\" transform=\"rotate(10 9 8.775)\" fill=\"#0439D7\"/><ellipse cx=\"15.001\" cy=\"8.776\" rx=\"1.5\" ry=\"1.725\" transform=\"rotate(-10 15.001 8.776)\" fill=\"#0439D7\"/><path d=\"M9.474 15.991a4.107 4.107 0 012.816-2.475c.914-.221 2.102-.213 3.655.419\" stroke=\"#0439D7\" stroke-width=\"1.5\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});