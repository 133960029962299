define("ember-svg-jar/inlined/signature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19.668 22c1.745 0 2.424.777 2.94 2.5l.09.316c.244.876.39 1.139.684 1.26.346.142.576.122.938-.064l.196-.11a6.82 6.82 0 00.234-.148l.884-.602c.822-.54 1.56-.897 2.458-1.122a1 1 0 11.485 1.94 5.376 5.376 0 00-1.527.653l-.398.255-.639.437a7.276 7.276 0 01-.778.476c-.858.44-1.694.514-2.615.134-.993-.409-1.393-1.04-1.768-2.295l-.208-.723c-.242-.785-.407-.907-.976-.907-.413 0-.84.207-1.425.698l-.246.214-1.228 1.167c-1.877 1.765-3.478 2.622-5.77 2.622-2.248 0-4.193-.339-5.825-1.025l3.93-1.072c.597.065 1.228.097 1.895.097 1.577 0 2.71-.553 4.115-1.817l.345-.318.709-.681c.315-.303.552-.52.79-.72.911-.764 1.74-1.165 2.71-1.165zm5.707-18.04a4.771 4.771 0 010 6.747l-.384.385c1.535 1.869 1.48 3.85.052 5.281l-2.669 2.668a1 1 0 01-1.413-1.415l2.665-2.665c.647-.649.722-1.454-.053-2.451l-11.49 11.489a2.996 2.996 0 01-1.331.773l-6.821 1.86a1 1 0 01-1.228-1.228l1.86-6.82a3 3 0 01.773-1.333L18.627 3.96a4.771 4.771 0 016.748 0zm-5.333 1.414L6.75 18.665a1 1 0 00-.258.444l-1.4 5.133 5.133-1.4a.999.999 0 00.444-.258l13.29-13.29a2.771 2.771 0 10-3.918-3.92z\" fill=\"#0439D7\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});