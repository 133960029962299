define("ember-cli-resolve-asset/index", ["exports", "ember-cli-resolve-asset/utils/resolve-asset", "ember-cli-resolve-asset/-private/asset-map-loader"], function (_exports, _resolveAsset, _assetMapLoader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  var _exportNames = {
    load: true
  };
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _resolveAsset.default;
    }
  });
  Object.defineProperty(_exports, "load", {
    enumerable: true,
    get: function () {
      return _assetMapLoader.load;
    }
  });
  Object.keys(_resolveAsset).forEach(function (key) {
    if (key === "default" || key === "__esModule") return;
    if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
    if (key in _exports && _exports[key] === _resolveAsset[key]) return;
    Object.defineProperty(_exports, key, {
      enumerable: true,
      get: function () {
        return _resolveAsset[key];
      }
    });
  });
});