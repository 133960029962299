define("ember-svg-jar/inlined/clavitar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><mask id=\"clavitar_svg__a\" x=\"0\" y=\"0\" width=\"26\" height=\"26\" maskUnits=\"userSpaceOnUse\"><circle class=\"cls-1\" fill=\"#fff\" cx=\"13\" cy=\"13\" r=\"13\"/></mask></defs><g data-name=\"Mask\"><circle data-name=\"path-1\" fill=\"#293143\" class=\"cls-2\" cx=\"13\" cy=\"13\" r=\"13\"/></g><g class=\"cls-3\" mask=\"url(#clavitar_svg__a)\"><path class=\"cls-4\" fill=\"#fffffe\" d=\"M17.45 17l-4.08-9.56a.62.62 0 00-1.19 0L8.1 17a1.05 1.05 0 00-.1.41.62.62 0 00.61.61.63.63 0 00.6-.42l.79-1.87v-.07l.46-1.13 2.29-5.64L15 14.53l.48 1.13.86 1.92A.66.66 0 0017 18a.63.63 0 00.61-.61.93.93 0 00-.11-.41\"/></g>",
    "attrs": {
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 26 26"
    }
  };
});