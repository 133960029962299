// @Legecy
// need to move the window functions into services, they ARE used in the client
// element in viewport is also mixed with fixedElementScroll so we may need to merge
// these funcs into a shared service
// the animate numbers is used in the new quality score modals in the client
// track is used to track events, I am sure we use it both in rails and ember should
// also be moved into the existing tracking service, I think we already did most of the qwork for this
// in general this needs to be picked appart and moved into services, but 90% of
// this is used in the client, they should have been put in modules in the first
// place

// Function to get a url parameter
window.getParameterByName = function (name) {
  var regex, results;
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  results = regex.exec(location.search);
  if (results === null) {
    return '';
  } else {
    return decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
};

// Function to apply native facebook handling
window.check_native_facebook = function () {
  if ($('.body--mobile-android').length || $('.body--mobile-ios').length) {
    // let facebook logins be handeled through native app
    return $('.btn--facebook').click(function (e) {
      e.preventDefault();
      e.stopPropagation();
      return clark.nativeapp.requestFacebookLogin();
    });
  }
};

$(function () {
  // Let the app know the user has authenticated
  if (getParameterByName('userAuthenticationComplete') === 'true') {
    window.clark.runFunctionWhenReady('userAuthenticationComplete');
  }

  // Click events on custom radio buttons
  $('.custom-radio span').click(function () {
    return $(this).next().click();
  });

  // check for native facebook register
  window.check_native_facebook();
});
