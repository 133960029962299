define("ember-svg-jar/inlined/explore-screen-3", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#01051C\" d=\"M100.625 17.412H86.1a4.375 4.375 0 00-4.069 2.742l-2.406 5.963h-6.956C67.988 11.013 53.9 0 37.188 0 16.669 0 0 16.585 0 37s16.669 37 37.188 37c20.519 0 37.188-16.585 37.188-37 0-.74-.088-1.437-.131-2.176H69.87c.086.739.13 1.436.13 2.176 0 18.021-14.7 32.647-32.812 32.647S4.375 55.021 4.375 37s14.7-32.647 32.812-32.647c14.263 0 26.381 9.098 30.887 21.765h-9.537c-3.981-7.748-12.031-13.059-21.35-13.059-13.3 0-24.062 10.708-24.062 23.941s10.763 23.941 24.062 23.941S61.25 50.233 61.25 37c0-.74-.044-1.437-.131-2.176h-4.506c.131.74.263 1.436.263 2.176 0 10.795-8.838 19.588-19.688 19.588S17.5 47.795 17.5 37s8.837-19.588 19.688-19.588c6.781 0 12.688 3.482 16.231 8.706H37.188C31.15 26.118 26.25 30.993 26.25 37s4.9 10.882 10.938 10.882S48.125 43.007 48.125 37c0-.74-.088-1.48-.219-2.176h-4.55c.219.696.394 1.392.394 2.176 0 3.613-2.931 6.529-6.562 6.529S30.625 40.613 30.625 37s2.931-6.529 6.562-6.529h42.438l2.406 5.964a4.374 4.374 0 004.069 2.742h14.525c2.406 0 4.375-1.959 4.375-4.353V21.765c0-2.394-1.969-4.353-4.375-4.353zm0 17.412H86.1l-2.625-6.529 2.625-6.529h14.525v13.058z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "124.651",
      "height": "87.849",
      "viewBox": "-10.859 -7.653 124.651 87.849"
    }
  };
});