define("ember-svg-jar/inlined/document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.732 4.425L11.701.394A1.343 1.343 0 0010.75 0H1.344C.602 0 0 .601 0 1.344v18.812c0 .742.601 1.344 1.344 1.344H14.78c.741 0 1.344-.601 1.344-1.344V5.376a1.334 1.334 0 00-.393-.95zm-4.981-3.081l4.031 4.031h-4.031V1.344zm4.031 18.812H1.345V1.344h8.734v4.031c0 .371.3.672.672.672h4.031v14.11z\" fill=\"#797C7C\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "17",
      "height": "22",
      "viewBox": "0 0 17 22",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});