define("ember-svg-jar/inlined/action-terminate-contract", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.7 2.4l6.4 6.4c.2.3.4.6.4 1.1V18h-1V9.9H18c-.8 0-1.5-.7-1.5-1.5V2.9H5c-.3 0-.5.2-.5.5V18h-1V3.5C3.5 2.7 4.2 2 5 2h11.6c.4 0 .8.1 1.1.4zm.3 6.5h4.8l-5.3-5.3v4.8c0 .3.2.5.5.5zM3.5 19.25a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-2.5zM7.75 22a.25.25 0 00-.25.25v1.5c0 .138.112.25.25.25h.5a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25h-.5zm-.25 4.25a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-1.5zM11.75 24a.25.25 0 00-.25.25v1.5c0 .138.112.25.25.25h.5a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25h-.5zm7.75 1.25a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-1.5zM23.75 19a.25.25 0 00-.25.25v2.5c0 .138.112.25.25.25h.5a.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25h-.5zm-12.25.25a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-2.5zM15.75 22a.25.25 0 00-.25.25v2.5c0 .138.112.25.25.25h.5a.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25h-.5zm-.25 5.25a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-.5zM3.75 24a.25.25 0 00-.25.25v1.5c0 .138.112.25.25.25h.5a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25h-.5zm15.75-3.75a.25.25 0 01.25-.25h.5a.25.25 0 01.25.25v2.5a.25.25 0 01-.25.25h-.5a.25.25 0 01-.25-.25v-2.5zM23.75 24a.25.25 0 00-.25.25v1.5c0 .138.112.25.25.25h.5a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25h-.5z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});