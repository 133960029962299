define("ember-svg-jar/inlined/horizon-dismiss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M2.089 2.216l.057-.07a.5.5 0 01.638-.057l.07.057L6 5.293l3.146-3.147a.5.5 0 11.708.708L6.707 6l3.147 3.146a.5.5 0 01.057.638l-.057.07a.5.5 0 01-.638.057l-.07-.057L6 6.707 2.854 9.854a.5.5 0 01-.708-.708L5.293 6 2.146 2.854a.5.5 0 01-.057-.638l.057-.07-.057.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 12 12"
    }
  };
});