define("ember-svg-jar/inlined/leftArrow.svs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M436.267 310.613c5.522 0 10-4.472 10-9.989 0-5.518-4.478-9.99-10-9.99-5.523 0-10 4.472-10 9.99 0 5.517 4.477 9.989 10 9.989zm0-1.827c-4.514 0-8.173-3.655-8.173-8.162 0-4.508 3.659-8.163 8.173-8.163s8.172 3.655 8.172 8.163c0 4.507-3.658 8.162-8.172 8.162zm2.107-4.523l-2.489-3.667 2.489-3.677h-2.489l-2.392 3.677 2.392 3.667h2.489z\" fill=\"#FFF\" class=\"svg-cta-fill left-arrow\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "style": "isolation:isolate",
      "viewBox": "426.267 290.634 20 19.98",
      "width": "20",
      "height": "19.98"
    }
  };
});