define("ember-svg-jar/inlined/checkmark-filled-primary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"12\" cy=\"12\" r=\"9\" fill=\"#0439D7\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.644 8.36a.932.932 0 011.34-.138c.407.344.467.964.134 1.385l-4.383 5.532a.933.933 0 01-1.417.067l-2.045-2.152a1.006 1.006 0 01.012-1.391.931.931 0 011.347.013l1.301 1.369 3.71-4.684z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});