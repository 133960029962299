define("ember-svg-jar/inlined/action-ask-expert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M25 2H3c-.8 0-1.5.7-1.5 1.5v18c0 .8.7 1.5 1.5 1.5h12.7c.1 0 .2 0 .3.1l4.9 3.7c.2.1.4.2.6.2.5 0 1-.4 1-1v-3H25c.8 0 1.5-.7 1.5-1.5v-18c0-.8-.7-1.5-1.5-1.5zm.5 19.5c0 .3-.2.5-.5.5h-2.8c-.4 0-.7.3-.7.7V26l-4.9-3.7c-.3-.2-.6-.3-.9-.3H3c-.3 0-.5-.2-.5-.5v-18c0-.3.2-.5.5-.5h22c.3 0 .5.2.5.5v18zm-9.9-9.2c.6-.6.9-1.4.9-2.3 0-1.7-1.1-3-2.5-3s-2.5 1.3-2.5 3c0 .9.4 1.8.9 2.3-1.7.6-2.9 2.3-2.9 4.2 0 .3.2.5.5.5h8c.3 0 .5-.2.5-.5 0-1.9-1.2-3.5-2.9-4.2zM14 8c.8 0 1.5.9 1.5 2s-.7 2-1.5 2-1.5-.9-1.5-2 .7-2 1.5-2zm-3.5 8c.2-1.7 1.7-3 3.5-3s3.2 1.3 3.5 3h-7z\" fill=\"currentColor\"/>",
    "attrs": {
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});