define("ember-svg-jar/inlined/confetti", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path clip-rule=\"evenodd\" d=\"M25.358 20.47a1.8 1.8 0 00-2.912.54L1.98 66.62c-.663 1.477.808 3.01 2.31 2.408l47.3-18.915a1.8 1.8 0 00.602-2.947L25.358 20.47z\" stroke=\"#0439D7\" stroke-width=\"2.7\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M43.86 29.483l16.666-16.316\" stroke=\"#0439D7\" stroke-width=\"2.25\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M39.585 5.107s7.335 7.091-2.027 17.594\" stroke=\"#CFD3DA\" stroke-width=\"2.25\" stroke-linecap=\"round\" stroke-linejoin=\"bevel\"/><path d=\"M66.895 32.681s-7.218-7.21-17.556 2.335\" stroke=\"#0439D7\" stroke-width=\"2.25\" stroke-linecap=\"round\" stroke-linejoin=\"bevel\"/><path clip-rule=\"evenodd\" d=\"M60.516 43.884c1.243 0 2.25-1.01 2.25-2.255a2.253 2.253 0 00-2.25-2.254 2.253 2.253 0 00-2.25 2.255 2.253 2.253 0 002.25 2.254zM50.612 6.009c1.243 0 2.251-1.01 2.251-2.255a2.253 2.253 0 00-2.25-2.254 2.253 2.253 0 00-2.251 2.254 2.253 2.253 0 002.25 2.255z\" stroke=\"#0439D7\" stroke-width=\"1.8\"/><path clip-rule=\"evenodd\" d=\"M73.12 23.368l-2.91 1.533.556-3.246-2.355-2.3 3.254-.473 1.455-2.953 1.456 2.953 3.254.474-2.355 2.299.556 3.246-2.91-1.533z\" stroke=\"#CFD3DA\" stroke-width=\"1.8\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path clip-rule=\"evenodd\" d=\"M27.63 9.677l-1.753 1.865-.412-2.528-2.312-1.094 2.273-1.173.324-2.542 1.816 1.803 2.513-.477-1.15 2.288 1.23 2.247-2.529-.39z\" stroke=\"#0439D7\" stroke-width=\"1.8\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M60.966 51.098s6.14 3.773 9.064 0\" stroke=\"#CFD3DA\" stroke-width=\"1.8\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M14.116 39.887l4.537 23.033\" stroke=\"#0439D7\" stroke-width=\"2.25\"/><path d=\"M25.976 41.625l3.759 16.5\" stroke=\"#0439D7\" stroke-width=\"2.25\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-dasharray=\".75\"/>",
    "attrs": {
      "width": "79",
      "height": "71",
      "viewBox": "0 0 79 71",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});